<div id="announcement-bar" class="bg-red">
  <a
    [href]="session ? './propiedad/registrar' : './auth/register'"
    class="announcement-bar-link text-light py-1 d-block"
    ><p class="text-center mb-0 small">
      <u>Te inivitamos a publicar tu inmueble, gratis <i class="fa fa-arrow-right ml-1" aria-hidden="true"></i></u>
    </p></a
  >
</div>

<header class="py-2">
  <div class="container-fluid px-0">
    <!-- Menu Navegacion -->
    <nav class="row m-0 menu align-items-center justify-content-between">
      <!-- Logo -->
      <div class="col-5 col-md-2 text-center">
        <a href="./" title="Vivienda.com">
          <img
            width="150px"
            src="./assets/img/logo-new.png"
            class="img-fluid"
            alt="Vivienda | Compra o vende un inmueble"
          />
          <!-- <img
            width="150px"
            src="//cdn.vivienda.com/images/logo.png"
            class="img-fluid"
            alt="Vivienda | Compra o vende un inmueble"
          /> -->
        </a>
        <div class="text-right pr-xl-4 small text-secondary lh-1">
          <small> Beta </small>
        </div>
      </div>
      <!-- Menu -->
      <div class="col-md-8 justify-content-center d-none d-lg-flex">
        <ul>
          <li class="bg-aqua mr-1 d-flex">
            <p>
              <a href="./busqueda">Encuentra tu inmueble</a>
            </p>
          </li>
          <li class="bg-gray mr-1 d-flex">
            <p>
              <a href="/propiedad/registrar"
              onclick="gtag('event', 'btn_publicar_menusup', {'event_label' : 'btn_publicar_menusup_label'});"
              >Publica tu inmueble</a>
            </p>
          </li>
          <li class="bg-gray mr-1 d-flex">
            <p>
              <a href="./mapa">Buscar por mapa</a>
            </p>
          </li>
          <li class="bg-gray mr-1 d-flex">
            <p>
              <a href="./servicios/buscador">Servicios para inmuebles</a>
            </p>
          </li>
          <li class="bg-gray mr-1 d-flex">
            <p>
              <a href="{{ servicePublished() }}"> Publica tu servicio </a>
            </p>
          </li>
          <li class="bg-gray mr-1 d-flex">
            <p>
              <a href="./constructoras">Constructoras</a>
            </p>
          </li>

          <li class="bg-gray mr-1 d-flex">
            <p>
              <a href="/usuario/registro-constructora" onclick="gtag('event', 'btn_publicar_constructora', {'event_label' : 'btn_publicar_constructora_label'});">Publica tu constructora</a>
            </p>
          </li>
          <li class="bg-gray mr-1 d-flex">
            <p>
              <a href="https://learning.vivienda.com/">
                Centro de aprendizaje
              </a>
            </p>
          </li>
          <!-- <li class="bg-gray mr-1 d-none d-xl-flex">
            <p>
              <a href="https://learning.vivienda.com/"> Emisora </a>
            </p>
          </li> -->
          <li class="bg-gray mr-1 d-flex">
            <p>
              <a href="./contactanos">Contáctanos</a>
            </p>
          </li>
        </ul>
      </div>
      <div class="col-7 col-lg-2 text-center justify-content-end d-flex">
        <ul>
          <li class="Social d-none d-lg-block">
            <p>
              <a
                class="mr-1"
                href="https://www.instagram.com/vivienda_com_/"
                target="_blank"
              >
                <img
                  src="//cdn.vivienda.com/images/icons/instagram.png"
                  width="25"
                />
              </a>
              <a
                href="https://www.facebook.com/Vivienda-101695609007329"
                target="_blank"
              >
                <img
                  src="//cdn.vivienda.com/images/icons/facebook.png"
                  width="25"
                />
              </a>
            </p>
          </li>
          <li class="Social d-lg-none" *ngIf="!session">
            <a href="/auth/register" class="btn btn-red d-flex align-items-center btn-sm"
            onclick="gtag('event', 'btn_registrate_mobile', {'event_label' : 'btn_registrate_mobile_label'});"
            >
              Regístrate <i class="fa fa-user-plus ml-2" aria-hidden="true"></i>
            </a>
          </li>
          <li class="profile p-0">
            <a href="{{ session ? './usuario/perfil' : './auth/login' }}">
              <img
                src="//cdn.vivienda.com/images/icons/profile.png"
                width="35"
              />
            </a>
          </li>
          <li class="pl-0 pr-1 small font-weight-bold" *ngIf="session">
            <a href="{{ session ? '/usuario/perfil' : '/auth/login' }}" class="text-dark" onclick="gtag('event', 'btn_perfil_menu', {'event_label' : 'btn_perfil_menu_label'});">
            {{ session ? user.name : "Identifícate" }}
            </a>
          </li>
          <li class="hamburger p-0 ml-1" id="menu">
            <span></span>
            <span></span>
            <span></span>
            <div class="dropdown-menu dropdown-menu-right p-md-0" id="MainMenu">
              <div class="d-block d-md-none">
                <p>
                  <a href="./busqueda">Encuentra tu inmueble</a>
                </p>
                <p>
                  <a href="./mapa">Buscar por mapa</a>
                </p>
                <p>
                  <a href="./servicios/buscador">Servicios para tu inmueble</a>
                </p>
                <p>
                  <a href="https://learning.vivienda.com/">
                    Centro de aprendizaje
                  </a>
                </p>
                <p>
                  <a href="./contactanos">Contáctanos</a>
                </p>
              </div>
              <div class="row px-2 vw-80">
                <div
                  class="col-md-2 text-left item-menu bg-gray1"
                  style="padding: 2.5rem 15px"
                >
                  <p class="text-black text-color-black mb-1 user-name">
                    Hola!, {{ session ? user.name : "identifícate" }}
                  </p>
                  <p class="text-grey text-regular mb-1">
                    {{
                      session
                        ? "Accede a tú perfil"
                        : "¿Aún no tienes una cuenta?"
                    }}
                  </p>
                  <a
                    href="{{ session ? '/usuario/perfil' : '/auth/register' }}"
                    onclick="gtag('event', 'btn_registrate_menu', {'event_label' : 'btn_registrate_menu_label'});"
                  >
                    <p class="text-danger text-bold">
                      {{ session ? "Ir a mi perfil" : "Regístrate aquí" }}
                    </p>
                  </a>
                  <a
                    (click)="homeService.openShop('./mi-cuenta/orders/')"
                    class="pointer"
                    *ngIf="session"
                  >
                    <p class="text-yellow text-bold">Pedidos</p>
                  </a>
                  <a
                    *ngIf="session"
                    class="pointer"
                    onclick="return false"
                    (click)="logOut()"
                  >
                    <p class="text-yellow text-bold">Cerrar sesión</p>
                  </a>
                </div>
                <div
                  class="col-md-2 item-menu text-left"
                  style="padding: 2.5rem 15px"
                >
                  <p
                    class="text-black text-color-black mb-1 text-red"
                    style="font-weight: 600"
                  >
                    Inmuebles
                  </p>
                  <a
                    onclick="
                    gtag('event', 'btn_publicar_menu_resalt', {
                      'event_label': 'btn_publicar_menu_resalt_label'
                    });"
                    href="./propiedad/registrar"
                    [class]="
                      this.router.url === './propiedad/registrar'
                        ? 'color-text'
                        : ''
                    "
                  >
                    <p class="text-light text-medium mb-1 bg-red px-2 pb-1">
                      Publica tu inmueble
                    </p>
                  </a>
                  <a
                    href="./busqueda"
                    [class]="
                      this.router.url === './busqueda' ? 'color-text' : ''
                    "
                  >
                    <p class="text-gray text-medium mb-1">
                      Encuentra tu inmueble
                    </p>
                  </a>
                  <p
                    class="text-black text-color-black mt-1 mb-0"
                    style="font-weight: 600"
                  >
                    Servicios
                  </p>
                  <a
                    href="./servicios/buscador"
                    [class]="
                      this.router.url === './servicios/buscador'
                        ? 'color-text'
                        : ''
                    "
                  >
                    <p class="text-gray text-medium mb-0">
                      Encuentra tu servicio
                    </p>
                  </a>
                  <a
                    href="{{ servicePublished() }}"
                    [class]="
                      this.router.url === './usuario/perfil' ? 'color-text' : ''
                    "
                  >
                    <p class="text-gray text-medium">Publica tu servicio</p>
                  </a>

                  <p
                    class="text-black text-color-black mt-1 mb-0"
                    style="font-weight: 600"
                  >
                    Constructoras
                  </p>
                  <a
                    href="./constructoras" 
                    onclick="gtag('event', 'btn_ver_constructora_submenu', {'event_label' : 'btn_ver_constructora_submenu_label'});"
                    [class]="
                      this.router.url === '/constructoras' ? 'color-text' : ''
                    "
                  >
                    <p class="text-gray text-medium">Ver constructoras</p>
                  </a>
                  <a
                    href="./usuario/registro-constructora" 
                    onclick="gtag('event', 'btn_publicar_constructora_submenu', {'event_label' : 'btn_publicar_constructora_submenu_label'});"
                    [class]="
                      this.router.url === '/usuario/registro-constructora' ? 'color-text' : ''
                    "
                  >
                    <p class="text-gray text-medium">Publica tu constructora</p>
                  </a>
                </div>
                <div
                  class="col-md-2 item-menu text-left"
                  style="padding: 2.5rem 15px"
                >
                  <p
                    class="text-black text-color-black mb-1"
                    style="font-weight: 600"
                  >
                    Sobre nosotros
                  </p>
                  <a
                    href="./about"
                    [class]="this.router.url === './about' ? 'color-text' : ''"
                  >
                    <p class="text-gray text-medium mb-1">¿Quiénes somos?</p>
                  </a>
                  <a
                    href="https://learning.vivienda.com/"
                    [class]="
                      this.router.url === 'https://learning.vivienda.com/'
                        ? 'color-text'
                        : ''
                    "
                  >
                    <p class="text-gray text-medium mb-1">
                      Centro de aprendizaje
                    </p>
                  </a>
                  <a
                    href="./contactanos"
                    [class]="
                      this.router.url === './contactanos' ? 'color-text' : ''
                    "
                  >
                    <p class="text-gray text-medium mb-">Contáctanos</p>
                  </a>
                  <p
                    class="text-black text-color-black mb-1"
                    style="font-weight: 600"
                  >
                    Siguenos
                  </p>
                  <div class="d-flex flex-row">
                    <a
                      href="https://www.facebook.com/Vivienda-101695609007329 "
                      class="mr-2"
                      target="_blank"
                    >
                      <img
                        src="//cdn.vivienda.com/images/icons/facebook.png"
                        width="14"
                      />
                    </a>
                    <a class="my-0">
                      <img
                        src="//cdn.vivienda.com/images/icons/instagram.png"
                        width="14"
                      />
                    </a>
                  </div>
                </div>
                <div
                  class="col-md-2 item-menu text-left"
                  style="padding: 2.5rem 15px"
                >
                  <p
                    class="text-black text-color-black mb-1"
                    style="font-weight: 600"
                  >
                    Directorio de aliados
                  </p>
                  <a
                    onclick="
                    gtag('event', 'Boton siguiente paso 1 en la pagina servicio desde el MENU DEL HOME ', {
                      'event_category': 'Boton Siguiente paso 1- Registro de servicio',
                      'event_label': location.pathname
                    });"
                    href="./aliados/empresas-inmobiliarias"
                    [class]="
                      this.router.url === './aliados/empresas-inmobiliarias'
                        ? 'color-text'
                        : ''
                    "
                  >
                    <p class="text-gray text-medium mb-1">
                      Empresas inmobiliarias
                    </p>
                  </a>
                  <a
                    href="./aliados/empresas-de-servicios"
                    [class]="
                      this.router.url === './aliados/empresas-de-servicios'
                        ? 'color-text'
                        : ''
                    "
                  >
                    <p class="text-gray text-medium mb-1">
                      Empresas de servicios
                    </p>
                  </a>
                  <a
                    onclick="
                    gtag('event', 'Boton agente inmobiliario desde el MENU HAMBURGUESA', {
                      'event_category': 'Boton aliados agentes inmobiliarios ',
                      'event_label': location.pathname
                    });"
                    href="./aliados/agentes-inmobiliarios"
                    [class]="
                      this.router.url === './aliados/agentes-inmobiliarios'
                        ? 'color-text'
                        : ''
                    "
                  >
                    <p class="text-gray text-medium mb-1">
                      Agentes inmobiliarios
                    </p>
                  </a>
                  <a
                    onclick="

                              gtag('event', 'Boton profesional independiente desde el MENU HAMBURGUESA', {
                                  'event_category': 'Boton aliados Profesional independiente ',
                                   'event_label': location.pathname
                              });

                              "
                    href="./aliados/profesionales-independientes"
                    [class]="
                      this.router.url ===
                      './aliados/profesionales-independientes'
                        ? 'color-text'
                        : ''
                    "
                  >
                    <p class="text-gray text-medium mb-0">
                      Profesionales de servicios
                    </p>
                  </a>
                </div>
                <div
                  class="col-md-2 item-menu text-left"
                  style="padding: 2.5rem 15px"
                >
                  <p
                    class="text-black text-color-black mb-1"
                    style="font-weight: 600"
                  >
                    ¿Quieres publicar?
                  </p>
                  <a
                    href="./propiedad/registrar"
                    [class]="
                      this.router.url === './propiedad/registrar'
                        ? 'color-text'
                        : ''
                    "
                    onclick="
                    gtag('event', 'btn_publicar_menu_op2', {
                      'event_label': 'btn_publicar_menu_op2_label'
                    });"
                  >
                    <p class="text-gray text-medium mb-1">Publica inmuebles</p>
                  </a>
                  <a
                    href="{{ servicePublished() }}"
                    [class]="
                      this.router.url === './usuario/perfil' ? 'color-text' : ''
                    "
                  >
                    <p class="text-gray text-medium mb-1">Publica servicios</p>
                  </a>
                  <a (click)="homeService.openShop('./tienda/')" class="pointer">
                    <p class="text-yellow text-black mb-1">Compra tu plan</p>
                  </a>
                </div>

                <div
                  class="col-md-2 item-menu text-center text-lg-left"
                  style="padding: 2.5rem 15px 2.5rem 0"
                >
                  <p
                    class="text-black text-color-black mb-1"
                    style="font-weight: 600"
                  >
                    Sugerencias
                  </p>
                  <p class="text-gray text-medium mb-0">
                    Ayudanos a mejorar.<br />Si tienes comentarios o
                    sugerencias, puedes dejarlos
                  </p>
                  <a
                    href="./sugerencias"
                    class="font-weight-bold"
                    style="color: #ff9c00"
                    >¡Aquí!</a
                  >
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <!-- buscador -->
    <div class="row m-0 justify-content-center">
      <div
        id="buscadornav"
        [class]="
          checkRoute('./') || checkRoute('./Servicios')
            ? 'd-none d-lg-flex row pr-0 mt-0 Buscador home justify-content-center align-items-center'
            : 'd-none d-lg-flex row pr-0 mt-0 Buscador justify-content-center align-items-center'
        "
      >
        <div class="col-6 col-md-auto ShowCollapse">
          <a
            class="p-md-0"
            href="#dropdownTypeOfferDropScroll"
            id="dropdownTypeOffer"
            data-toggle="collapse"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <p
              class="text-medium text-gray text-nav-top mb-0 py-3 py-md-0 text-center text-md-left"
              title="{{ contentText }}"
            >
              <span>
                {{ type_offer.charAt(0).toUpperCase() + type_offer.slice(1)
                }}<i class="fas fa-chevron-down ml-0 ml-lg-3 text-center"></i>
              </span>
            </p>
          </a>
          <div
            class="dropdown-menu tipo-oferta dropdown-menu-right bg-blue"
            id="dropdownTypeOfferDropScroll"
            style="width: 230px"
          >
            <div class="row">
              <div class="col-12">
                <label
                  for="arriendoc-nav"
                  class="text-color-black float-left h6 text-white pointer"
                  >Arrendar</label
                >
                <input
                  type="radio"
                  name="arriendoc-nav"
                  id="arriendoc-nav"
                  value="arrendar"
                  [(ngModel)]="type_offer"
                  class="circular"
                />
                <label
                  for="arriendoc-nav"
                  class="float-right text-white pointer"
                  style="background: none"
                >
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </label>
              </div>
              <div class="col-12">
                <label
                  for="nuevoc-nav"
                  class="text-color-black float-left h6 text-white pointer"
                  >Comprar</label
                >
                <input
                  type="radio"
                  name="nuevoc-nav"
                  id="nuevoc-nav"
                  value="comprar"
                  [(ngModel)]="type_offer"
                  class="circular"
                />
                <label
                  for="nuevoc-nav"
                  class="float-right text-white pointer"
                  style="background: none"
                >
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </label>
              </div>
              <div class="col-12">
                <label
                  for="servicioc-nav"
                  class="text-color-black float-left h6 text-white pointer"
                  >Servicios</label
                >
                <input
                  type="radio"
                  name="servicioc-nav"
                  id="servicioc-nav"
                  value="servicios"
                  [(ngModel)]="type_offer"
                  class="circular"
                  (change)="contentText = 'Selecciona...'"
                />
                <label
                  for="servicioc-nav"
                  class="float-right text-white pointer"
                  style="background: none"
                >
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </label>
              </div>
            </div>

            <!-- <div class="" style="position: absolute;bottom: 15px;">
              <div class="row mx-0">
                <h6 class="col-12 pl-0 text-white">Empresas aliadas:</h6>
                <div class="col-3 pl-0" *ngFor="aliado of [1,2,3,4]; index as i">
                  <div class="card d-flex align-items-center justify-content-center aspect-ratio-1 shadow rounded-3">
                    <img src="./assets/img/servicios/servicios-aliados-logo-{{i}}.png" alt="" class="img-fluid">
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-6 col-md-auto">
          <a
            class="p-0"
            href="#dropdownTypePropertyDropScroll"
            data-toggle="collapse"
            id="dropdownTypeProperty"
          >
            <p
              class="text-medium text-gray text-nav-top mb-0 ss py-3 py-md-0 text-center text-md-left"
              title="{{ contentText }}"
            >
              <span>
                {{
                  contentText.length > 14
                    ? contentText.substr(0, 14) + "..."
                    : contentText
                }}<i class="fas fa-chevron-down ml-0 ml-lg-3 text-center"></i>
              </span>
            </p>
          </a>
          <div
            class="dropdown-menu tipo-busqueda"
            aria-labelledby="dropdownTypeProperty"
            id="dropdownTypePropertyDropScroll"
            [class]="type_offer == 'servicios' ? 'servi' : ''"
          >
            <div class="row" *ngIf="type_offer != 'servicios'">
              <div class="col-12">
                <h6
                  id="multi-label"
                  class=" {{
                    multiSelect ? 'active' : ''
                  }} text-color-black float-left"
                >
                  Seleccionar multiples
                </h6>
                <input
                  type="checkbox"
                  name="multi-nav"
                  id="multi-nav"
                  [(ngModel)]="multiSelect"
                  class="no-close"
                />
                <label
                  for="multi-nav"
                  class="float-right"
                  (click)="changeToMultiselection()"
                ></label>
              </div>
              <ng-container *ngIf="!multiSelect">
                <div
                  class="col-12"
                  *ngFor="let tp of type_properties; index as i; let f = first"
                >
                  <h6
                    id="{{ tp.slug }}-label"
                    class=" {{
                      type_properties[i].checked ? 'active' : ''
                    }} text-color-black float-left"
                  >
                    {{ tp.type_property }}
                  </h6>
                  <input
                    type="checkbox"
                    name="{{ tp.slug }}-nav"
                    id="{{ tp.slug }}-nav"
                    [(ngModel)]="type_properties[i].checked"
                    class="no-close"
                  />
                  <label
                    for="{{ tp.slug }}-nav"
                    class="float-right"
                    (click)="changeTextContentText(tp)"
                  ></label>
                </div>
              </ng-container>
              <ng-container *ngIf="multiSelect">
                <div
                  class="col-12"
                  *ngFor="let tp of type_properties; index as i; let f = first"
                >
                  <h6
                    id="{{ tp.slug }}-label"
                    class=" {{
                      type_properties[i].checked ? 'active' : ''
                    }} text-color-black float-left"
                  >
                    {{ tp.type_property }}
                  </h6>
                  <input
                    type="checkbox"
                    name="{{ tp.slug }}-nav"
                    id="{{ tp.slug }}-nav"
                    [(ngModel)]="type_properties[i].checked"
                    class="no-close"
                  />
                  <label
                    for="{{ tp.slug }}-nav"
                    class="float-right"
                    (click)="changeTextContentMultiText(tp)"
                  ></label>
                </div>
              </ng-container>
            </div>

            <div class="d-flex" *ngIf="type_offer == 'servicios'">
              <h6 class="text-color-black float-left mr-2">
                Todos los servicios
              </h6>
              <input
                type="checkbox"
                name="all-services-nav"
                id="all-services-nav"
                [(ngModel)]="allServices"
                class="circular no-close"
              />
              <label
                for="all-services-nav"
                class="float-right"
                (click)="useAllServices()"
              ></label>
            </div>

            <div class="columns-3" *ngIf="type_offer == 'servicios'" style="">
              <div
                class="column-cat mb-4"
                *ngFor="let s of services; index as i"
              >
                <div class="row align-items-center">
                  <div class="col-10 pr-0">
                    <label
                      for="{{ s.slug }}-nav"
                      class="m-0 p-0 no-close"
                      (click)="changeTextContentTextService(s)"
                    >
                      <h6
                        class="text-color-black no-close mb-0 font-weight-bold"
                      >
                        {{ s.name }}
                      </h6>
                    </label>
                  </div>
                  <div class="col-auto px-0">
                    <input
                      type="checkbox"
                      name="{{ s.slug }}-nav"
                      id="{{ s.slug }}-nav"
                      [(ngModel)]="services[i].checked"
                      class="circular no-close"
                    />
                    <label
                      for="{{ s.slug }}-nav"
                      class=""
                      (click)="changeTextContentTextService(s)"
                    ></label>
                  </div>
                </div>

                <div *ngIf="s.subservices.length > 0">
                  <div
                    class="row mb-1 align-items-center"
                    *ngFor="let sub of s.subservices; index as j"
                  >
                    <div class="col-10 pr-0">
                      <label
                        for="{{ s.slug }}-{{ sub.slug }}-nav"
                        class="d-block p-0 m-0 no-close"
                        (click)="changeTextContentTextService(sub)"
                      >
                        <h6
                          class="text-color-black float-left no-close mb-0 w-100 pointer text-truncate"
                          title="{{ sub.name }}"
                        >
                          {{ sub.name }}
                        </h6>
                      </label>
                    </div>
                    <div class="col-auto px-0">
                      <div class="check-submenu-mini-submenu no-close">
                        <input
                          type="checkbox"
                          name="{{ s.slug }}-{{ sub.slug }}-nav"
                          id="{{ s.slug }}-{{ sub.slug }}-nav"
                          class="no-close"
                          [(ngModel)]="services[i].subservices[j].checked"
                        />
                        <label
                          for="{{ s.slug }}-{{ sub.slug }}-nav"
                          class="d-block m-0 p-0 pointer no-close"
                          (click)="changeTextContentTextService(sub)"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center my-2">
              <a
                class="btn-red text-white d-block w-50 text-bold text-center py-2 p-70 cursor-pointer"
                >Aplicar</a
              >
            </div>
          </div>
        </div>
        <div class="offset-1 col-6 offset-md-0 col-md-auto">
          <input
            type="text"
            placeholder="Ciudad, zona o sector"
            name="cityStatic"
            id="cityStatic"
            class="input-busqueda cities"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="off"
            (keyup.enter)="search()"
            *ngIf="browser"
          />
        </div>
        <div class="col-5 col-md-auto p-0">
          <a (click)="search()" class="btn" onclick="gtag('event', 'btn_buscador_nav', {'event_label' : 'btn_buscador_nav_label'});">
            <i class="fas fa-search mr-2" style="font-size: 0.9rem"></i>Buscar
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
<!--  -->
<div
  class="container-fluid top-navbar px-0 d-none d-none sticky-top"
  [class]="
    checkRouterParam('./propiedad/', './propiedad/:slug', 'property-slug') ||
    this.router.url.includes('/servicio/')
      ? ''
      : 'mb-0'
  "
>
  <div class="container" style="font-size: 10px">
    <div class="row d-inline">
      <div class="float-right mx-2 mt-1">
        <!--<a class="my-0">
                    <img src="https://cdn.vivienda.com/images/icons/linkedin-black.png" width="15px">
                </a>-->
      </div>
      <div class="float-right mx-2 mt-1">
        <!--<a class="my-0">
                    <img src="https://cdn.vivienda.com/images/icons/twitter-black.png" width="15px">
                </a>-->
        <a class="my-0" href="https://www.instagram.com/vivienda_com_/">
          <img
            src="https://cdn.vivienda.com/images/icons/instagram-foot.png"
            width="15px"
          />
        </a>
      </div>
      <div class="float-right mx-2 mt-1">
        <a
          class="my-0"
          href="https://www.facebook.com/Vivienda-101695609007329"
          target="_blank"
        >
          <img
            src="https://cdn.vivienda.com/images/icons/facebook-black.png"
            width="15px"
          />
        </a>
      </div>
      <div class="float-right mx-2 my-1">
        <a [routerLink]="['./contactanos']" class="text-color-black text-medium">
          <p class="text-nav-top link-top mb-0">Contáctanos</p>
        </a>
      </div>
      <div class="float-right mx-2 my-1">
        <a
          href="https://learning.vivienda.com/"
          class="text-color-black text-medium"
        >
          <p class="text-nav-top link-top mb-0">Centro de aprendizaje</p>
        </a>
      </div>
      <div class="float-right mx-2 my-1">
        <a
          href="./propiedad/registrar"
          class="text-color-black text-medium link-top"
        >
          <p class="text-nav-top link-top mb-0">Publica tu inmueble</p>
        </a>
      </div>
      <div class="float-right mx-2 my-1">
        <a href="./servicios/buscador" class="text-color-black text-medium">
          <p class="text-nav-top link-top mb-0">Servicios para tu inmueble</p>
        </a>
      </div>
      <div class="float-right mx-2 my-1">
        <a href="./mapa" class="text-color-black text-medium">
          <p class="text-nav-top link-top mb-0">Buscar por mapa</p>
        </a>
      </div>
      <div class="float-right mx-1 button_inmueble">
        <a href="./busqueda" class="text-color-black text-medium">
          <p class="text-nav-top link-top mb-0">Encuentra tu inmueble</p>
        </a>
      </div>
    </div>
  </div>

  <!-- Scroll Navbar -->
  <div
    class="container-fluid justify-content-center align-items-center bg-white px-0 mt-0 d-none"
    [class]="this.router.url === './usuario/perfil' ? 'bg-banner' : ''"
    id="scroll-nav"
  >
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg p-0 pb-1 navbar-light w-100 d-flex">
        <a [routerLink]="['./']" class="navbar-brand py-1">
          <img
            class="img-fluid"
            width="150px"
            src="https://cdn.vivienda.com/images/logo-beta.png"
          />
        </a>
        <div class="collapse" id="burger-scroll" style="font-size: 14px">
          <div class="row px-2">
            <div
              class="col-2-5 text-left bg-gray1"
              style="padding: 2.5rem 15px"
            >
              <p class="text-black text-color-black mb-1 user-name">
                Hola!, {{ session ? user.name : "identifícate" }}
              </p>
              <p class="text-grey text-regular mb-1">
                {{
                  session ? "Accede a tú perfil" : "¿Aún no tienes una cuenta?"
                }}
              </p>
              <a href="{{ session ? './usuario/perfil' : './auth/register' }}">
                <p class="text-danger text-bold">
                  {{ session ? "Ir a mi perfil" : "Regístrate aquí" }}
                </p>
              </a>
              <a
                (click)="homeService.openShop('./mi-cuenta/orders/')"
                class="pointer"
                *ngIf="session"
              >
                <p class="text-yellow text-bold">Pedidos</p>
              </a>
              <hr />
              <a
                *ngIf="session"
                class="pointer"
                onclick="return false"
                (click)="logOut()"
              >
                <p class="text-yellow text-bold">Cerrar sesión</p>
              </a>
            </div>
            <div class="col-2-5 text-left" style="padding: 2.5rem 15px">
              <p
                class="text-black text-color-black mb-1"
                style="font-weight: 600"
              >
                Servicios
              </p>
              <a
                href="./servicios/buscador"
                [class]="
                  this.router.url === './servicios/buscador' ? 'color-text' : ''
                "
              >
                <p class="text-gray text-medium mb-1">Encuentra tu servicio</p>
              </a>
              <a
                href="{{ servicePublished() }}"
                [class]="
                  this.router.url === './usuario/perfil' ? 'color-text' : ''
                "
              >
                <p class="text-gray text-medium">Publica tu servicio</p>
              </a>
              <p
                class="text-black text-color-black mb-1"
                style="font-weight: 600"
              >
                Inmuebles
              </p>
              <a
                href="./busqueda"
                [class]="this.router.url === './busqueda' ? 'color-text' : ''"
              >
                <p class="text-gray text-medium mb-1">Encuentra tu inmueble</p>
              </a>
              <a
                onclick="

                            gtag('event', 'Boton de Publicar Inmueble desde MENU HAMBURGUESA', {
                                'event_category': 'Boton de Publicar un inmueble ',
                                 'event_label': location.pathname
                            });

                            "
                href="./propiedad/registrar"
                [class]="
                  this.router.url === './propiedad/registrar' ? 'color-text' : ''
                "
              >
                <p class="text-gray text-medium mb-0">Publica tu inmueble</p>
              </a>
            </div>
            <div class="col-2-5 text-left" style="padding: 2.5rem 15px">
              <p
                class="text-black text-color-black mb-1"
                style="font-weight: 600"
              >
                Sobre nosotros
              </p>
              <a
                href="./about"
                [class]="this.router.url === './about' ? 'color-text' : ''"
              >
                <p class="text-gray text-medium mb-1">¿Quiénes somos?</p>
              </a>
              <a
                href="https://learning.vivienda.com/"
                [class]="
                  this.router.url === 'https://learning.vivienda.com/'
                    ? 'color-text'
                    : ''
                "
              >
                <p class="text-gray text-medium mb-1">Centro de aprendizaje</p>
              </a>
              <a
                href="./contactanos"
                [class]="this.router.url === './contactanos' ? 'color-text' : ''"
              >
                <p class="text-gray text-medium mb-">Contáctanos</p>
              </a>
              <p
                class="text-black text-color-black mb-1"
                style="font-weight: 600"
              >
                Siguenos
              </p>
              <div class="d-flex flex-row">
                <a
                  href="https://www.facebook.com/Vivienda-101695609007329 "
                  class="mr-2"
                  target="_blank"
                >
                  <img
                    src="https://cdn.vivienda.com/images/icons/facebook-black.png"
                    width="14px"
                  />
                </a>
                <a class="my-0">
                  <img
                    src="https://cdn.vivienda.com/images/icons/instagram-black.png"
                    width="15px"
                  />
                </a>
                <!--
                                  <a href="" class="mx-2">
                                    <img src="https://cdn.vivienda.com/images/icons/twitter-black.png" width="14px">
                                </a>
                                <a href="" class="ml-2">
                                    <img src="https://cdn.vivienda.com/images/icons/linkedin-black.png" width="14px">
                                </a>
                               -->
              </div>
            </div>
            <div class="col-2-5 text-left" style="padding: 2.5rem 15px">
              <p
                class="text-black text-color-black mb-1"
                style="font-weight: 600"
              >
                Directorio de aliados
              </p>
              <a
                onclick="

                            gtag('event', 'Boton siguiente paso 1 en la pagina servicio desde el MENU DEL HOME ', {
                                'event_category': 'Boton Siguiente paso 1- Registro de servicio',
                                 'event_label': location.pathname
                            });

                            "
                href="./aliados/empresas-inmobiliarias"
                [class]="
                  this.router.url === './aliados/empresas-inmobiliarias'
                    ? 'color-text'
                    : ''
                "
              >
                <p class="text-gray text-medium mb-1">Empresas inmobiliarias</p>
              </a>
              <a
                href="./aliados/empresas-de-servicios"
                [class]="
                  this.router.url === './aliados/empresas-de-servicios'
                    ? 'color-text'
                    : ''
                "
              >
                <p class="text-gray text-medium mb-1">Empresas de servicios</p>
              </a>
              <a
                onclick="

                            gtag('event', 'Boton agente inmobiliario desde el MENU HAMBURGUESA', {
                                'event_category': 'Boton aliados agentes inmobiliarios ',
                                 'event_label': location.pathname
                            });

                            "
                href="./aliados/agentes-inmobiliarios"
                [class]="
                  this.router.url === './aliados/agentes-inmobiliarios'
                    ? 'color-text'
                    : ''
                "
              >
                <p class="text-gray text-medium mb-1">Agentes inmobiliarios</p>
              </a>
              <a
                onclick="

                            gtag('event', 'Boton profesional independiente desde el MENU HAMBURGUESA', {
                                'event_category': 'Boton aliados Profesional independiente ',
                                 'event_label': location.pathname
                            });

                            "
                href="./aliados/profesionales-independientes"
                [class]="
                  this.router.url === './aliados/profesionales-independientes'
                    ? 'color-text'
                    : ''
                "
              >
                <p class="text-gray text-medium mb-0">
                  Profesionales de servicios
                </p>
              </a>
            </div>
            <div class="col-2-5 text-left" style="padding: 2.5rem 15px">
              <p
                class="text-black text-color-black mb-1"
                style="font-weight: 600"
              >
                ¿Quieres publicar?
              </p>
              <a
                href="./propiedad/registrar"
                [class]="
                  this.router.url === './propiedad/registrar' ? 'color-text' : ''
                "
              >
                <p class="text-gray text-medium mb-1">Publica inmuebles</p>
              </a>
              <a
                href="{{ servicePublished() }}"
                [class]="
                  this.router.url === './usuario/perfil' ? 'color-text' : ''
                "
              >
                <p class="text-gray text-medium mb-1">Publica servicios</p>
              </a>
              <hr />
              <a (click)="homeService.openShop('./tienda/')" class="pointer">
                <p class="text-yellow text-black mb-1">Compra tu plan</p>
              </a>
            </div>
          </div>
        </div>

        <button
          type="button"
          class="navbar-toggler"
          data-toggle="dropdown"
          data-target="#navbarCollapse"
          aria-expanded="false"
          aria-controls="navbarCollapse"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="w-100 d-none d-lg-flex pr-0 mt-0">
          <ul
            class="mx-auto py-0 filtros-activos bg-white pl-1 d-flex mb-0"
            style="list-style: none; width: 650px"
          >
            <li
              class="px-1 border-line-right d-flex align-items-center justify-content-center my-1"
              style="width: 21%"
            >
              <a
                class="p-0"
                href="#dropdownTypeOfferDropScroll"
                id="dropdownTypeOffer"
                data-toggle="collapse"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <p
                  class="text-medium text-gray text-nav-top mb-0"
                  title="{{ contentText }}"
                >
                  <span>
                    {{ type_offer.charAt(0).toUpperCase() + type_offer.slice(1)
                    }}<i
                      class="fas fa-chevron-down ml-0 ml-lg-3 text-center"
                    ></i>
                  </span>
                </p>
              </a>
              <div
                class="dropdown-menu tipo-oferta"
                aria-labelledby="dropdownTypeOffer"
                id="dropdownTypeOfferDropScroll"
              >
                <div class="row">
                  <div class="col-12">
                    <label
                      for="arriendoc-nav"
                      class="text-color-black float-left h6"
                      >Arrendar</label
                    >
                    <input
                      type="radio"
                      name="arriendoc-nav"
                      id="arriendoc-nav"
                      value="arrendar"
                      [(ngModel)]="type_offer"
                      class="circular"
                    />
                    <label for="arriendoc-nav" class="float-right"></label>
                  </div>
                  <div class="col-12">
                    <label
                      for="nuevoc-nav"
                      class="text-color-black float-left h6"
                      >Comprar</label
                    >
                    <input
                      type="radio"
                      name="nuevoc-nav"
                      id="nuevoc-nav"
                      value="comprar"
                      [(ngModel)]="type_offer"
                      class="circular"
                    />
                    <label for="nuevoc-nav" class="float-right"></label>
                  </div>
                  <div class="col-12">
                    <label
                      for="servicioc-nav"
                      class="text-color-black float-left h6"
                      >Servicios</label
                    >
                    <input
                      type="radio"
                      name="servicioc-nav"
                      id="servicioc-nav"
                      value="servicios"
                      [(ngModel)]="type_offer"
                      class="circular"
                      (change)="contentText = 'Selecciona...'"
                    />
                    <label for="servicioc-nav" class="float-right"></label>
                  </div>
                </div>
              </div>
            </li>
            <li
              class="px-1 border-line-right d-flex align-items-center justify-content-center my-1"
              style="width: 31.7%"
            >
              <a
                class="p-0"
                href="#dropdownTypePropertyDropScroll"
                data-toggle="collapse"
                id="dropdownTypeProperty"
              >
                <p
                  class="text-medium text-gray text-nav-top mb-0"
                  title="{{ contentText }}"
                >
                  <span>
                    {{
                      contentText.length > 14
                        ? contentText.substr(0, 14) + "..."
                        : contentText
                    }}<i
                      class="fas fa-chevron-down ml-0 ml-lg-3 text-center"
                    ></i>
                  </span>
                </p>
              </a>
              <div
                class="dropdown-menu tipo-busqueda"
                aria-labelledby="dropdownTypeProperty"
                id="dropdownTypePropertyDropScroll"
              >
                <div class="row" *ngIf="type_offer != 'servicios'">
                  <div
                    class="col-12"
                    *ngFor="let tp of type_properties; index as i"
                  >
                    <h6 class="text-color-black float-left">
                      {{ tp.type_property }}
                    </h6>
                    <input
                      type="checkbox"
                      name="{{ tp.slug }}-nav"
                      id="{{ tp.slug }}-nav"
                      [(ngModel)]="type_properties[i].checked"
                      class="no-close"
                    />
                    <label
                      for="{{ tp.slug }}-nav"
                      class="float-right"
                      (click)="changeTextContentText(tp)"
                    ></label>
                  </div>
                </div>
                <div class="row" *ngIf="type_offer == 'servicios'">
                  <div class="col-12">
                    <h6 class="text-color-black float-left">
                      Todas los servicios
                    </h6>
                    <input
                      type="checkbox"
                      name="all-services-nav"
                      id="all-services-nav"
                      [(ngModel)]="allServices"
                      class="circular no-close"
                    />
                    <label
                      for="all-services-nav"
                      class="float-right"
                      (click)="useAllServices()"
                    ></label>
                  </div>
                  <div class="col-12" *ngFor="let s of services; index as i">
                    <h6 class="text-color-black float-left">{{ s.name }}</h6>
                    <input
                      type="checkbox"
                      name="{{ s.slug }}-nav"
                      id="{{ s.slug }}-nav"
                      [(ngModel)]="services[i].checked"
                      class="circular no-close"
                    />
                    <label
                      for="{{ s.slug }}-nav"
                      class="float-right"
                      (click)="changeTextContentTextService(s)"
                    ></label>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <a
                    class="btn-yellow text-white d-block w-50 text-bold text-center py-2 p-70 cursor-pointer"
                    >Aplicar</a
                  >
                </div>
              </div>
            </li>
            <li
              class="px-2 my-2 border-line-right d-flex flex-row justify-content-center align-items-center ciudad"
              style="border: 0; width: 33.33334%"
            >
              <i class="fas fa-map-marker-alt mr-1" style="color: #aaa"></i>
              <input
                type="text"
                placeholder="Ciudad, zona o sector"
                name="cityStatic"
                id="cityStatic"
                class="input-busqueda cities w-75"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="off"
                (keyup.enter)="search()"
                *ngIf="browser"
              />
            </li>
            <li
              class="bg-yellow px-3 d-flex align-items-center justify-content-center"
              style="
                width: 20%;
                font-size: 0.85rem;
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
              "
            >
              <a (click)="search()" class="pointer text-white text-bold">
                <i class="fas fa-search mr-2" style="font-size: 0.9rem"></i
                >Buscar
              </a>
            </li>
          </ul>
        </div>

        <div class="d-none d-md-flex mt-0 mr-2">
          <a
            (click)="homeService.openShop('./tienda/')"
            class="text-danger pointer"
          >
            <i class="fas fa-shopping-cart fa-lg"></i>
          </a>
        </div>

        <div class="collapse navbar-collapse mt-0" id="navbarCollapse">
          <ul class="navbar-nav w-100">
            <div class="d-md-flex ml-md-auto">
              <div class="d-md-flex align-items-center" *ngIf="!session">
                <li class="nav-item py-0">
                  <a [routerLink]="['./auth/login']" class="nav-link">
                    <h6
                      class="text-medium text-center text-color-black black-sm p-80 mb-0"
                    >
                      Inicio sesión
                    </h6>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    href="./auth/register"
                    style="width: 110px"
                    class="nav-link btn-yellow"
                  >
                    <h6
                      class="text-bold m-0 black-sm text-white text-center p-90"
                    >
                      Regístrate
                    </h6>
                  </a>
                </li>
              </div>
              <div class="d-md-flex align-items-center" *ngIf="session">
                <li class="nav-item">
                  <a
                    type="button"
                    class="nav-link pb-0 pr-1 pt-0 rounded d-flex text-center align-items-center"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    id="account-float"
                    aria-controls="account1"
                    aria-expanded="false"
                  >
                    <div
                      class="profile-image mr-1"
                      [class]="user.image ? '' : 'border-0'"
                    >
                      <img
                        src="{{
                          user.image
                            ? user.image
                            : 'https://cdn.vivienda.com/images/icons/persona.png'
                        }}"
                        class="h-100"
                        onerror="this.src = 'https://cdn.vivienda.com/images/icons/persona.png'"
                      />
                    </div>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="account-float"
                  >
                    <a class="dropdown-item" [routerLink]="['./usuario/perfil']"
                      >Mi cuenta</a
                    >
                    <a
                      class="dropdown-item"
                      [routerLink]="['./propiedad/registrar']"
                      >Publica tu inmueble</a
                    >
                    <a class="dropdown-item" href="{{ servicePublished() }}"
                      >Publica tu servicio</a
                    >
                    <hr />
                    <a
                      class="dropdown-item pointer"
                      onclick="return false"
                      (click)="logOut()"
                      >Cerrar sesión</a
                    >
                  </div>
                </li>
              </div>
              <div class="d-md-flex align-items-center">
                <li class="nav-item mx-1 d-none d-md-block">
                  <a
                    href="#burger-scroll"
                    role="button"
                    aria-controls="burger-scroll"
                    class="text-danger d-block"
                    data-toggle="collapse"
                  >
                    <i class="fas fa-bars fa-lg"></i>
                  </a>
                </li>
              </div>
            </div>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>

<div class="container-fluid position-relative p-0 navmain d-none">
  <div
    class="banner-img inmob d-none d-md-flex justify-content-center position-absolute"
  >
    <img
      src="https://cdn.vivienda.com/images/icons/banner-inmo.jpg"
      alt="banner"
      class="w-100"
      *ngIf="this.router.url === '/auth/registro-inmobiliaria'"
    />
  </div>
  <div
    class="container-fluid px-0"
    [class]="
      this.router.url === './usuario/perfil' ||
      this.router.url === './usuario/inmobiliaria' ||
      this.router.url === './usuario/servicios' ||
      this.router.url === './usuario/profesional'
        ? 'banner-sol'
        : checkRouterParam('./propiedad/', './propiedad/:slug', 'property-slug')
        ? 'degradado'
        : checkRouteParam('./servicio/')
        ? 'aqui'
        : ''
    "
  >
    <!-- Mobile Navbar -->
    <nav
      class="navbar navbar-expand-lg pt-0 navbar-light navbar-mobile d-flex d-lg-none justify-content-between w-100 px-0"
    >
      <div class="row mx-2 pt-2 w-100">
        <div class="px-1" [class]="this.router.url !== './' ? 'col-1' : 'col-4'">
          <button
            type="button"
            class="navbar-toggler d-flex justify-content-center align-items-center p-0"
            data-toggle="collapse"
            data-target="#navbarCollapse2"
            aria-expanded="false"
            aria-controls="navbarCollapse2"
          >
            <span class="navbar-toggler-icon" style="width: 25px"></span>
          </button>
        </div>
        <div
          class="col-3 px-1"
          [class]="this.router.url !== './' ? '' : 'd-none'"
        >
          <a
            href="#buscador"
            onclick="$('#navbarCollapse2').removeClass('show')"
            data-toggle="collapse"
            aria-expanded="false"
            aria-controls="buscador"
            class="d-flex pointer justify-content-start align-items-center m-0 p-0"
          >
            <i class="fas fa-search text-gray mt-1 ml-1"></i>
          </a>
        </div>
        <div class="col-4 col-md-5 px-1">
          <a
            [routerLink]="['./']"
            class="navbar-brand d-flex justify-content-start align-items-center m-0 p-0"
          >
            <img
              src="https://cdn.vivienda.com/images/logo-beta.png"
              width="90px"
            />
          </a>
        </div>
        <div [class]="session ? 'd-none' : 'd-flex'" class="col-1 px-1">
          <a
            [routerLink]="['./auth/login']"
            class="d-block justify-content-start w-75 align-items-center m-0 p-0"
          >
            <img
              src="https://cdn.vivienda.com/images/icons/sign-in.png"
              width="25px"
            />
          </a>
        </div>
        <div
          [class]="session ? 'd-none' : 'd-flex'"
          class="col-3 col-md-2 justify-content-center px-1"
        >
          <a
            href="./auth/register"
            class="px-1 d-flex justify-content-center align-items-center btn-yellow text-white text-semibold registrate"
            >Registrate</a
          >
        </div>
        <div
          *ngIf="session"
          class="col-4 col-md-3 justify-content-end align-items-center px-1 d-flex"
        >
          <button
            type="button"
            class="btn dropdown-toggle d-inline-block text-color-black p-0 m-0 d-flex"
            data-toggle="collapse"
            data-target="#account3"
            aria-expanded="false"
            aria-controls="account3"
          >
            <div
              class="profile-image mr-1"
              [class]="user.image ? '' : 'border-0'"
            >
              <img
                src="{{
                  user.image
                    ? user.image
                    : 'https://cdn.vivienda.com/images/icons/persona.png'
                }}"
                class="h-100"
                onerror="this.src = 'https://cdn.vivienda.com/images/icons/persona.png'"
              />
            </div>
          </button>
          <div class="dropdown-menu dropdown-menu-right cuenta" id="account3">
            <a class="close-menu">
              <img
                src="//cdn.vivienda.com/images/icons/close.png"
                alt=""
                width="15"
              />
            </a>
            <a class="dropdown-item" [routerLink]="['./usuario/perfil']"
              >Mi cuenta</a
            >
            <a class="dropdown-item" [routerLink]="['./propiedad/registrar']"
              >Publica tu inmueble</a
            >
            <a class="dropdown-item" href="{{ servicePublished() }}"
              >Publica tu servicio</a
            >
            <div
              class="position-relative only-responsive"
              aria-labelledby="dropdownProfileSettings"
            >
              <a class="dropdown-item" href="./usuario/perfil"
                >Información principal</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=servicios"
                >Servicios publicados</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=propiedades"
                >Inmuebles</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=messages"
                >Mensajes</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=contacts"
                >Contactos</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=notifications"
                >Notificaciones</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=saved"
                >Listado de guardados</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=searches"
                >Busquedas guardadas</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=companies"
                >Empresas</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=calendar"
                >Calendario</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=documents"
                >Documentos</a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="./usuario/registro-servicio"
                >Crea empresa de servicio</a
              >
              <a class="dropdown-item" href="./usuario/registro-inmobiliario"
                >Crea empresa inmobiliaria</a
              >
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                href="https://crm.vivienda.com/"
                target="_blank"
                >CRM Vivienda</a
              >
              <div class="dropdown-divider"></div>
            </div>
            <hr />
            <a
              class="dropdown-item pointer"
              onclick="return false"
              (click)="logOut()"
              >Cerrar sesión</a
            >
          </div>
        </div>
      </div>
      <div class="collapse navbar-collapse" id="navbarCollapse2">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a
              [routerLink]="['busqueda']"
              onclick="$('#navbarCollapse2').removeClass('show')"
              [class]="
                this.router.url === './busqueda' ? 'text-yellow' : 'text-white'
              "
              class="pb-3 text-regular d-block"
              >Encuentra tu inmueble</a
            >
          </li>
          <li class="nav-item">
            <a
              href="./servicios/buscador"
              class="pb-3 text-regular d-block"
              [class]="
                this.router.url === './mapa' ? 'text-yellow' : 'text-white'
              "
              >Encuentra tu servicio</a
            >
          </li>

          <li class="nav-item">
            <a
              [routerLink]="['about']"
              onclick="$('#navbarCollapse2').removeClass('show')"
              [class]="
                this.router.url === './about' ? 'text-yellow' : 'text-white'
              "
              class="pb-3 text-regular d-block"
              >¿Quiénes somos?</a
            >
          </li>
          <li class="nav-item">
            <a
              [routerLink]="['aliados']"
              onclick="$('#navbarCollapse2').removeClass('show')"
              [class]="
                this.router.url === './aliados/empresas' ||
                this.router.url === './aliados/personas'
                  ? 'text-yellow'
                  : 'text-white'
              "
              class="pb-3 text-regular d-block"
              >Directorio de aliados</a
            >
          </li>
          <li class="nav-item">
            <a
              [routerLink]="['./propiedad/registrar']"
              onclick="$('#navbarCollapse2').removeClass('show')"
              [class]="
                this.router.url === './propiedad/registrar'
                  ? 'text-yellow'
                  : 'text-white'
              "
              class="pb-3 text-regular d-block"
              >Publica tu inmueble</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://blog.vivienda.com"
              onclick="$('#navbarCollapse2').removeClass('show')"
              [class]="
                this.router.url === './blog' ? 'text-yellow' : 'text-white'
              "
              class="pb-3 text-regular d-block"
              >Centro de aprendizaje</a
            >
          </li>
          <li class="nav-item">
            <a
              [routerLink]="['./contactanos']"
              onclick="$('#navbarCollapse2').removeClass('show')"
              [class]="
                this.router.url === './contactanos'
                  ? 'text-yellow'
                  : 'text-white'
              "
              class="pb-3 text-regular d-block"
              >Contáctanos</a
            >
          </li>
          <li class="nav-item">
            <a
              class="pb-3 text-regular d-block text-white no-close"
              href="#"
              id="dropdownProfileSettings"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Configuración del perfil <i class="fas fa-arrow-down"></i>
            </a>
            <div
              class="dropdown-menu position-relative"
              aria-labelledby="dropdownProfileSettings"
            >
              <a class="dropdown-item" href="./usuario/perfil"
                >Información principal</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=servicios"
                >Servicios publicados</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=propiedades"
                >Inmuebles</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=messages"
                >Mensajes</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=contacts"
                >Contactos</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=notifications"
                >Notificaciones</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=saved"
                >Listado de guardados</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=searches"
                >Busquedas guardadas</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=companies"
                >Empresas</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=calendar"
                >Calendario</a
              >
              <a class="dropdown-item" href="./usuario/perfil?tab=documents"
                >Documentos</a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="./usuario/registro-servicio"
                >Crea empresa de servicio</a
              >
              <a class="dropdown-item" href="./usuario/registro-inmobiliario"
                >Crea empresa inmobiliaria</a
              >
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                href="https://crm.vivienda.com/"
                target="_blank"
                >CRM Vivienda</a
              >
              <div class="dropdown-divider"></div>
            </div>
          </li>
          <li class="nav-item">
            <div class="social" class="my-4">
              <a
                href="https://www.facebook.com/Vivienda-101695609007329"
                target="_blank"
              >
                <img
                  src="https://cdn.vivienda.com/images/icons/facebook.png"
                  class="mr-3"
                  width="25px"
                />
              </a>
              <a
                href="https://www.instagram.com/vivienda_com_/"
                target="_blank"
              >
                <img
                  src="https://cdn.vivienda.com/images/icons/instagram.png"
                  class="mr-3"
                  width="25px"
                />
              </a>
              <!--
                                    <img src="https://cdn.vivienda.com/images/icons/twitter.png" class="mx-3" width="25px">
                                    <img src="https://cdn.vivienda.com/images/icons/linkedin.png" class="mx-3" width="25px">
                                -->
            </div>
          </li>
        </ul>
      </div>
      <div class="collapse navbar-collapse no-close" id="buscador">
        <div class="row text-center">
          <div
            class="col position-relative justify-content-center align-items-center no-close"
          >
            <img
              src="https://cdn.vivienda.com/images/icons/back-black.png"
              class="position-absolute back"
              onclick="$('#buscador').removeClass('show')"
            />
            <h6
              class="text-center text-regular text-color-black d-inline-block mr-auto mb-0 no-close"
            >
              Te ayudamos a encontrar el<br />{{
                type_offer == "servicios" ? "servicio" : "inmueble"
              }}
              que necesitas
            </h6>
          </div>
        </div>
        <form class="text-center no-close">
          <div class="dropdown no-close">
            <a
              class="btn btn-secondary dropdown-toggle busqueda-tipo text-limit no-close px-1"
              href="#dropdownMenuLinkListType"
              data-toggle="collapse"
              id="dropdownMenuLink"
              title="{{ contentText }}"
            >
              <span class="float-left no-close">{{
                contentTextType.length > 14
                  ? contentTextType.slice(0, 14) + "..."
                  : contentTextType
              }}</span>
              <i
                class="float-right mt-2 fas fa-chevron-down fa-custom no-close"
              ></i>
            </a>
            <div
              class="dropdown-menu busqueda-tipo no-close"
              aria-labelledby="dropdownMenuLink"
              id="dropdownMenuLinkListType"
            >
              <div class="row no-close">
                <div class="col-12 no-close">
                  <h6 class="text-color-black float-left no-close">Arrendar</h6>
                  <input
                    type="radio"
                    name="arriendo-nav-mobile"
                    id="arriendo-nav-mobile"
                    [(ngModel)]="type_offer"
                    class="no-close circular"
                    value="arrendar"
                    (change)="contentText = 'Selecciona...'"
                  />
                  <label
                    for="arriendo-nav-mobile"
                    class="float-right no-close"
                    (click)="contentTextType = 'Arrendar'"
                  ></label>
                </div>
                <div class="col-12 no-close">
                  <h6 class="text-color-black float-left no-close">Comprar</h6>
                  <input
                    type="radio"
                    name="comprar-nav-mobile"
                    id="comprar-nav-mobile"
                    [(ngModel)]="type_offer"
                    class="no-close circular"
                    value="comprar"
                    (change)="contentText = 'Selecciona...'"
                  />
                  <label
                    for="comprar-nav-mobile"
                    class="float-right no-close"
                    (click)="contentTextType = 'Compra'"
                  ></label>
                </div>
                <div class="col-12 no-close">
                  <h6 class="text-color-black float-left no-close">
                    Servicios
                  </h6>
                  <input
                    type="radio"
                    name="servicios-nav-mobile"
                    id="servicios-nav-mobile"
                    [(ngModel)]="type_offer"
                    class="no-close circular"
                    value="servicios"
                    (change)="contentText = 'Selecciona...'"
                  />
                  <label
                    for="servicios-nav-mobile"
                    class="float-right no-close"
                    (click)="contentTextType = 'Servicios'"
                  ></label>
                </div>
              </div>
              <div class="row justify-content-center no-close">
                <a
                  class="btn-yellow text-white d-block w-50 text-bold text-center py-2 p-70 cursor-pointer no-close"
                  onclick="$('#dropdownMenuLinkListType').removeClass('show')"
                  >Aplicar</a
                >
              </div>
            </div>
          </div>
          <div class="dropdown no-close">
            <a
              class="btn btn-secondary dropdown-toggle busqueda-tipo text-limit no-close px-1"
              href="#dropdownMenuLinkList"
              data-toggle="collapse"
              id="dropdownMenuLink"
              title="{{ contentText }}"
            >
              <span class="float-left no-close">{{
                contentText.length > 14
                  ? contentText.slice(0, 14) + "..."
                  : contentText
              }}</span>
              <i
                class="float-right mt-2 fas fa-chevron-down fa-custom no-close"
              ></i>
            </a>
            <div
              class="dropdown-menu busqueda-tipo no-close"
              aria-labelledby="dropdownMenuLink"
              id="dropdownMenuLinkList"
            >
              <div class="row no-close" *ngIf="type_offer != 'servicios'">
                <div
                  class="col-12 no-close"
                  *ngFor="let tp of type_properties; index as i"
                >
                  <h6 class="text-color-black float-left no-close">
                    {{ tp.type_property }}
                  </h6>
                  <input
                    type="checkbox"
                    name="{{ tp.slug }}-nav-mobile"
                    id="{{ tp.slug }}-nav-mobile"
                    [(ngModel)]="type_properties[i].checked"
                    class="no-close"
                  />
                  <label
                    for="{{ tp.slug }}-nav-mobile"
                    class="float-right no-close"
                    (click)="changeTextContentText(tp)"
                  ></label>
                </div>
              </div>
              <div class="row no-close" *ngIf="type_offer == 'servicios'">
                <div
                  class="col-12 no-close"
                  *ngFor="let s of services; index as i"
                >
                  <h6 class="text-color-black float-left no-close">
                    {{ s.name }}
                  </h6>
                  <input
                    type="checkbox"
                    name="{{ s.slug }}-nav-mobile"
                    id="{{ s.slug }}-nav-mobile"
                    [(ngModel)]="services[i].checked"
                    class="no-close"
                  />
                  <label
                    for="{{ s.slug }}-nav-mobile"
                    class="float-right no-close"
                    (click)="changeTextContentTextService(s)"
                  ></label>
                </div>
              </div>
              <div class="row justify-content-center no-close">
                <a
                  class="btn-yellow text-white d-block w-50 text-bold text-center py-2 p-70 cursor-pointer no-close"
                  onclick="$('#dropdownMenuLinkList').removeClass('show')"
                  >Aplicar</a
                >
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="Ciudad, zona o sector"
            id="cityMobile"
            name="cityMobile"
            class="input-nav mt-2 cities no-close"
            autocomplete="off"
            (keyup.enter)="search()"
          />
          <button
            class="btn-yellow text-white py-2 my-4 text-bold mx-auto text-center no-close"
            style="width: 150px"
            (click)="search()"
          >
            Buscar
          </button>
        </form>
      </div>
    </nav>

    <!-- Static Navbar -->
    <nav
      [class]="this.router.url === './usuario/perfil' ? 'nav-administrador' : ''"
      class="navbar navbar-expand-lg pb-0 pt-0 navbar-light w-100 d-none d-lg-flex position-relative"
    >
      <a [routerLink]="['./']" class="navbar-brand mt-0 mb-1">
        <div
          [class]="
            this.router.url === './usuario/servicios'
              ? 'banner-sol'
              : checkRouterParam('./servicios/', './servicios/:slug', 'aaa')
              ? 'aaa'
              : checkRouteParam('./servicio/')
              ? 'aqui'
              : ''
          "
        ></div>
        <img
          class="img-fluid"
          width="170px"
          src="https://cdn.vivienda.com/images/logo-beta.png"
        />
      </a>
      <div class="collapse" id="burger-static" style="font-size: 14px">
        <div class="row px-3">
          <div class="col-2-5 text-left bg-gray1" style="padding: 2.5rem 15px">
            <p class="text-black text-color-black mb-1 user-name">
              ¡Hola!, {{ session ? user.name : "identifícate" }}
            </p>
            <p class="text-grey text-regular mb-1">
              {{
                session ? "Accede a tú perfil" : "¿Aún no tienes una cuenta?"
              }}
            </p>
            <a href="{{ session ? './usuario/perfil' : './auth/register' }}">
              <p class="text-yellow text-bold">
                {{ session ? "Ir a mi perfil" : "Regístrate aquí" }}
              </p>
            </a>
            <a
              (click)="homeService.openShop('./mi-cuenta/orders/')"
              class="pointer"
              *ngIf="session"
            >
              <p class="text-yellow text-bold">Pedidos</p>
            </a>
            <hr />
            <a
              *ngIf="session"
              class="pointer"
              onclick="return false"
              (click)="logOut()"
            >
              <p class="text-yellow text-bold">Cerrar sesión</p>
            </a>
          </div>
          <div class="col-2-5 text-left" style="padding: 2.5rem 15px">
            <p
              class="text-black text-color-black mb-1"
              style="font-weight: 600"
            >
              Servicios
            </p>
            <a
              href="./servicios/buscador"
              [class]="
                this.router.url === './servicios/buscador' ? 'color-text' : ''
              "
            >
              <p class="text-gray text-medium mb-1">Encuentra tu servicio</p>
            </a>
            <a
              href="{{ servicePublished() }}"
              [class]="
                this.router.url === './usuario/perfil' ? 'color-text' : ''
              "
            >
              <p class="text-gray text-medium">Publica tu servicio</p>
            </a>
            <p
              class="text-black text-color-black mb-1"
              style="font-weight: 600"
            >
              Inmuebles
            </p>
            <a
              href="./busqueda"
              [class]="this.router.url === './busqueda' ? 'color-text' : ''"
            >
              <p class="text-gray text-medium mb-1">Encuentra tu inmueble</p>
            </a>
            <a
              href="./propiedad/registrar"
              [class]="
                this.router.url === './propiedad/registrar' ? 'color-text' : ''
              "
            >
              <p class="text-gray text-medium mb-0">Publica tu inmueble</p>
            </a>
          </div>
          <div class="col-2-5 text-left" style="padding: 2.5rem 15px">
            <p
              class="text-black text-color-black mb-1"
              style="font-weight: 600"
            >
              Sobre nosotros
            </p>
            <a
              href="./about"
              [class]="this.router.url === './about' ? 'color-text' : ''"
            >
              <p class="text-gray text-medium mb-1">¿Quiénes somos?</p>
            </a>
            <a
              href="https://learning.vivienda.com/"
              [class]="
                this.router.url === 'https://learning.vivienda.com/'
                  ? 'color-text'
                  : ''
              "
            >
              <p class="text-gray text-medium mb-1">Centro de aprendizaje</p>
            </a>
            <a
              href="./contactanos"
              [class]="this.router.url === './contactanos' ? 'color-text' : ''"
            >
              <p class="text-gray text-medium mb-">Contáctanos</p>
            </a>
            <p
              class="text-black text-color-black mb-1"
              style="font-weight: 600"
            >
              Siguenos
            </p>
            <div class="d-flex flex-row">
              <a
                href="https://www.facebook.com/Vivienda-101695609007329 "
                class="mr-2"
                target="_blank"
              >
                <img
                  src="https://cdn.vivienda.com/images/icons/facebook-black.png"
                  width="14px"
                />
              </a>
              <a href="" class="mx-2">
                <img
                  src="https://cdn.vivienda.com/images/icons/twitter-black.png"
                  width="14px"
                />
              </a>
              <a href="" class="ml-2">
                <img
                  src="https://cdn.vivienda.com/images/icons/linkedin-black.png"
                  width="14px"
                />
              </a>
            </div>
          </div>
          <div class="col-2-5 text-left" style="padding: 2.5rem 15px">
            <p
              class="text-black text-color-black mb-1"
              style="font-weight: 600"
            >
              Directorio de aliados
            </p>
            <a
              onclick="

                            gtag('event', 'Boton empresa inmobiliarias desde el MENU HAMBURGUESA', {
                                'event_category': 'Boton aliados empresas inmobiliarios',
                                 'event_label': location.pathname
                            });

                            "
              href="./aliados/empresas-inmobiliarias"
              [class]="
                this.router.url === './aliados/empresas-inmobiliarias'
                  ? 'color-text'
                  : ''
              "
            >
              <p class="text-gray text-medium mb-1">Empresas inmobiliarias</p>
            </a>
            <a
              href="./aliados/empresas-de-servicios"
              [class]="
                this.router.url === './aliados/empresas-de-servicios'
                  ? 'color-text'
                  : ''
              "
            >
              <p class="text-gray text-medium mb-1">Empresas de servicios</p>
            </a>
            <a
              onclick="

                            gtag('event', 'Boton agente inmobiliario desde el MENU HAMBURGUESA', {
                                'event_category': 'Boton aliados agentes inmobiliarios ',
                                 'event_label': location.pathname
                            });

                            "
              href="./aliados/agentes-inmobiliarios"
              [class]="
                this.router.url === './aliados/agentes-inmobiliarios'
                  ? 'color-text'
                  : ''
              "
            >
              <p class="text-gray text-medium mb-1">Agentes inmobiliarios</p>
            </a>
            <a
              onclick="

                            gtag('event', 'Boton profesional independiente desde el MENU HAMBURGUESA', {
                                'event_category': 'Boton aliados Profesional independiente ',
                                 'event_label': location.pathname
                            });

                            "
              href="./aliados/profesionales-independientes"
              [class]="
                this.router.url === './aliados/profesionales-independientes'
                  ? 'color-text'
                  : ''
              "
            >
              <p class="text-gray text-medium mb-0">
                Profesionales de servicios
              </p>
            </a>
          </div>
          <div class="col-2-5 text-left" style="padding: 2.5rem 15px">
            <p
              class="text-black text-color-black mb-1"
              style="font-weight: 600"
            >
              ¿Quieres publicar?
            </p>
            <a
              href="./propiedad/registrar"
              [class]="
                this.router.url === './propiedad/registrar' ? 'color-text' : ''
              "
            >
              <p class="text-gray text-medium mb-1">Publica inmuebles</p>
            </a>
            <a
              href="{{ servicePublished() }}"
              [class]="
                this.router.url === './usuario/perfil' ? 'color-text' : ''
              "
            >
              <p class="text-gray text-medium mb-1">Publica servicios</p>
            </a>
            <hr />
            <a (click)="homeService.openShop('./tienda/')" class="pointer">
              <p class="text-yellow text-black mb-1">Compra tu plan</p>
            </a>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="navbar-toggler"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-expanded="false"
        aria-controls="navbarCollapse"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="w-100 d-none d-md-flex pr-0 mt-0">
        <ul
          class="mx-auto py-0 filtros-activos bg-white pl-1 d-flex mb-0"
          style="list-style: none; width: 650px"
        >
          <li
            class="px-1 border-line-right d-flex align-items-center justify-content-center my-1"
            style="width: 21%"
          >
            <a
              class="p-0"
              href="#dropdownTypeOfferDrop"
              id="dropdownTypeOffer"
              data-toggle="collapse"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <p
                class="text-medium text-gray text-nav-top mb-0"
                title="{{ contentText }}"
              >
                <span>
                  {{ type_offer.charAt(0).toUpperCase() + type_offer.slice(1) }}
                  <i class="fas fa-chevron-down ml-0 ml-lg-3 text-center"></i>
                </span>
              </p>
            </a>
            <div
              class="dropdown-menu tipo-oferta"
              aria-labelledby="dropdownTypeOffer"
              id="dropdownTypeOfferDrop"
            >
              <div class="row">
                <div class="col-12">
                  <label
                    for="arriendoc-nav-1"
                    class="text-color-black float-left h6"
                    >Arrendar</label
                  >
                  <input
                    type="radio"
                    name="type-nav-1"
                    id="arriendoc-nav-1"
                    value="arrendar"
                    [(ngModel)]="type_offer"
                    class="circular"
                    (change)="contentText = 'Selecciona...'"
                  />
                  <label for="arriendoc-nav-1" class="float-right"></label>
                </div>
                <div class="col-12">
                  <label for="nuevoc-nav" class="text-color-black float-left h6"
                    >Comprar</label
                  >
                  <input
                    type="radio"
                    name="type-nav-1"
                    id="nuevoc-nav-1"
                    value="comprar"
                    [(ngModel)]="type_offer"
                    class="circular"
                    (change)="contentText = 'Selecciona...'"
                  />
                  <label for="nuevoc-nav-1" class="float-right"></label>
                </div>
                <div class="col-12">
                  <label
                    for="servicioc-nav"
                    class="text-color-black float-left h6"
                    >Servicios</label
                  >
                  <input
                    type="radio"
                    name="type-nav-1"
                    id="servicioc-nav-1"
                    value="servicios"
                    [(ngModel)]="type_offer"
                    class="circular"
                    (change)="contentText = 'Selecciona...'"
                  />
                  <label for="servicioc-nav-1" class="float-right"></label>
                </div>
              </div>
            </div>
          </li>
          <li
            class="px-1 border-line-right d-flex align-items-center justify-content-center my-1"
            style="width: 31.6666666667%"
          >
            <a
              class="p-0"
              href="#dropdownTypePropertyDrop"
              data-toggle="collapse"
              id="dropdownTypeProperty"
            >
              <p
                class="text-medium text-gray text-nav-top mb-0"
                title="{{ contentText }}"
              >
                <span>
                  {{
                    contentText.length > 14
                      ? contentText.substr(0, 14) + "..."
                      : contentText
                  }}<i class="fas fa-chevron-down ml-0 ml-lg-3 text-center"></i>
                </span>
              </p>
            </a>
            <div
              class="dropdown-menu tipo-busqueda"
              aria-labelledby="dropdownTypeProperty"
              id="dropdownTypePropertyDrop"
            >
              <div class="row" *ngIf="type_offer != 'servicios'">
                <div
                  class="col-12"
                  *ngFor="let tp of type_properties; index as i"
                >
                  <h6 class="text-color-black float-left">
                    {{ tp.type_property }}
                  </h6>
                  <input
                    type="checkbox"
                    name="{{ tp.slug }}-nav"
                    id="{{ tp.slug }}-nav"
                    [(ngModel)]="type_properties[i].checked"
                    class="no-close"
                  />
                  <label
                    for="{{ tp.slug }}-nav"
                    class="float-right"
                    (click)="changeTextContentText(tp)"
                  ></label>
                </div>
              </div>
              <div class="row" *ngIf="type_offer == 'servicios'">
                <div class="col-12">
                  <h6 class="text-color-black float-left">
                    Todas los servicios
                  </h6>
                  <input
                    type="checkbox"
                    name="all-services-nav"
                    id="all-services-nav"
                    [(ngModel)]="allServices"
                    class="no-close"
                  />
                  <label
                    for="all-services-nav"
                    class="float-right"
                    (click)="useAllServices()"
                  ></label>
                </div>
                <div class="col-12" *ngFor="let s of services; index as i">
                  <h6 class="text-color-black float-left">{{ s.name }}</h6>
                  <input
                    type="checkbox"
                    name="{{ s.slug }}-nav"
                    id="{{ s.slug }}-nav"
                    [(ngModel)]="services[i].checked"
                    class="no-close"
                  />
                  <label
                    for="{{ s.slug }}-nav"
                    class="float-right"
                    (click)="changeTextContentTextService(s)"
                  ></label>
                </div>
              </div>
              <div class="row justify-content-center">
                <a
                  class="btn-yellow text-white d-block w-50 text-bold text-center py-2 p-70 cursor-pointer"
                  >Aplicar</a
                >
              </div>
            </div>
          </li>
          <li
            class="px-2 my-2 border-line-right d-flex flex-row justify-content-center align-items-center ciudad"
            style="border: 0; width: 33.33334%"
          >
            <i class="fas fa-map-marker-alt mr-1" style="color: #aaa"></i>
            <input
              type="text"
              placeholder="Ciudad, zona o sector"
              name="cityStatic"
              id="cityStatic"
              class="input-busqueda cities w-100"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              (keyup.enter)="search()"
              *ngIf="browser"
            />
          </li>
          <li
            class="bg-yellow px-3 d-flex align-items-center justify-content-center"
            style="
              width: 20%;
              font-size: 0.85rem;
              border-bottom-right-radius: 5px;
              border-top-right-radius: 5px;
            "
          >
            <a (click)="search()" class="pointer text-white text-bold">
              <i class="fas fa-search mr-2" style="font-size: 0.9rem"></i>Buscar
            </a>
          </li>
        </ul>
      </div>

      <div class="d-none d-md-flex mt-0 mr-1">
        <a
          (click)="homeService.openShop('./tienda/')"
          class="text-danger pointer"
          target="_blank"
        >
          <i class="fas fa-shopping-cart fa-lg"></i>
        </a>
      </div>

      <div class="collapse navbar-collapse mt-0 mb-0" id="navbarCollapse">
        <ul class="navbar-nav w-100">
          <div class="d-md-flex ml-md-auto">
            <div class="d-md-flex align-items-center" *ngIf="!session">
              <li class="nav-item">
                <a [routerLink]="['./auth/login']" class="nav-link py-0">
                  <h6
                    class="text-regular black-sm p-80 text-center mb-0"
                    [class]="
                      this.router.url === './' ||
                      this.router.url === './about' ||
                      this.router.url === './contactanos' ||
                      this.router.url === './usuario/perfil' ||
                      this.router.url === './usuario/inmobiliaria' ||
                      this.router.url === './auth/registro-inmobiliaria' ||
                      checkRouterParam(
                        './propiedad/',
                        './propiedad/:slug',
                        'property-slug'
                      ) ||
                      this.router.url === './usuario/agentes' ||
                      this.router.url === './usuario/servicios'
                        ? 'text-white'
                        : 'text-color-black'
                    "
                  >
                    Inicio sesión
                  </h6>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="./auth/register"
                  style="width: 110px"
                  class="nav-link btn-yellow"
                >
                  <h6
                    class="text-bold m-0 black-sm text-white text-center p-90"
                  >
                    Regístrate
                  </h6>
                </a>
              </li>
            </div>
            <div class="d-md-flex" *ngIf="session">
              <li class="nav-item">
                <a
                  type="button"
                  class="nav-link pb-0 pr-1 pt-0 rounded d-flex text-center align-items-center"
                  [class]="
                    this.router.url === './' ||
                    this.router.url === './about' ||
                    this.router.url === './usuario/perfil'
                      ? 'text-white'
                      : '' ||
                        checkRouterParam(
                          './propiedad/',
                          './propiedad/:slug',
                          'property-slug'
                        ) ||
                        this.router.url.includes('/servicio/')
                      ? 'text-white'
                      : ''
                  "
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  id="account-float"
                  aria-controls="account2"
                  aria-expanded="false"
                >
                  <div
                    class="profile-image mr-1"
                    [class]="user.image ? '' : 'border-0'"
                  >
                    <img
                      src="{{
                        user.image
                          ? user.image
                          : 'https://cdn.vivienda.com/images/icons/persona.png'
                      }}"
                      class="h-100"
                      onerror="this.src = 'https://cdn.vivienda.com/images/icons/persona.png'"
                    />
                  </div>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-right cuenta"
                  id="account2"
                >
                  <a class="dropdown-item" [routerLink]="['./usuario/perfil']"
                    >Mi cuenta</a
                  >
                  <a
                    class="dropdown-item"
                    [routerLink]="['./propiedad/registrar']"
                    >Publica tu inmueble</a
                  >
                  <a class="dropdown-item" href="{{ servicePublished() }}"
                    >Publica tu servicio</a
                  >
                  <a
                    class="pb-3 text-regular d-block text-white no-close only-responsive"
                    href="#"
                    id="dropdownProfileSettings"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Configuración del perfil <i class="fas fa-arrow-down"></i>
                  </a>
                  <div
                    class="dropdown-menu position-relative"
                    aria-labelledby="dropdownProfileSettings"
                  >
                    <a class="dropdown-item" href="./usuario/perfil"
                      >Información principal</a
                    >
                    <a
                      class="dropdown-item"
                      href="./usuario/perfil?tab=servicios"
                      >Servicios publicados</a
                    >
                    <a
                      class="dropdown-item"
                      href="./usuario/perfil?tab=propiedades"
                      >Inmuebles</a
                    >
                    <a class="dropdown-item" href="./usuario/perfil?tab=messages"
                      >Mensajes</a
                    >
                    <a class="dropdown-item" href="./usuario/perfil?tab=contacts"
                      >Contactos</a
                    >
                    <a
                      class="dropdown-item"
                      href="./usuario/perfil?tab=notifications"
                      >Notificaciones</a
                    >
                    <a class="dropdown-item" href="./usuario/perfil?tab=saved"
                      >Listado de guardados</a
                    >
                    <a class="dropdown-item" href="./usuario/perfil?tab=searches"
                      >Busquedas guardadas</a
                    >
                    <a
                      class="dropdown-item"
                      href="./usuario/perfil?tab=companies"
                      >Empresas</a
                    >
                    <a class="dropdown-item" href="./usuario/perfil?tab=calendar"
                      >Calendario</a
                    >
                    <a
                      class="dropdown-item"
                      href="./usuario/perfil?tab=documents"
                      >Documentos</a
                    >
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="./usuario/registro-servicio"
                      >Crea empresa de servicio</a
                    >
                    <a
                      class="dropdown-item"
                      href="./usuario/registro-inmobiliario"
                      >Crea empresa inmobiliaria</a
                    >
                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item"
                      href="https://crm.vivienda.com/"
                      target="_blank"
                      >CRM Vivienda</a
                    >
                    <div class="dropdown-divider"></div>
                  </div>
                  <hr />
                  <a
                    class="dropdown-item pointer"
                    onclick="return false"
                    (click)="logOut()"
                    >Cerrar sesión</a
                  >
                </div>
              </li>
            </div>
            <div class="d-md-flex align-items-center">
              <li class="nav-item mx-1 d-none d-md-block">
                <a
                  href="#burger-static"
                  role="button"
                  aria-controls="burger-static"
                  class="text-danger d-block pt-1"
                  data-toggle="collapse"
                >
                  <i class="fas fa-bars fa-lg"></i>
                </a>
              </li>
            </div>
          </div>
        </ul>
      </div>
    </nav>

    <!-- Profile register Links-->
    <div
      class="text-center py-5 py-md-2 banner-profile position-relative w-100"
      *ngIf="session && this.router.url == './usuario/perfil'"
    >
      <img
        src="https://cdn.vivienda.com/images/solutions/banner.jpg"
        style="width: 120%"
        class="bg-solutions"
      />
      <h3 class="text-yellow text-center text-bold w-100">
        ¿Quieres crecer como empresa inmobiliaria?
      </h3>
      <div id="buttons" class="collapse">
        <div class="row">
          <div class="col-md-6 text-center">
            <a href="./usuario/registro-inmobiliario" class="subimg bg-yellow">
              <img
                src="https://cdn.vivienda.com/images/icons/presta-inmuebles.png"
                alt="company"
                width="60px"
              />
            </a>
            <p class="text-black text-white">Soluciones inmobiliarias</p>
            <p class="text-white w-75 mx-auto text-thin">
              Administra los diferentes inmuebles que tienes para ofrecer
            </p>
          </div>
          <div class="col-md-6 text-center">
            <a class="subimg bg-white" href="./usuario/registro-servicio">
              <img
                class="disabled"
                src="https://cdn.vivienda.com/images/icons/presta-servicios.png"
                alt="company"
                width="60px"
              />
            </a>
            <p class="text-black text-white">Presta tus servicios</p>
            <p class="text-white w-75 mx-auto text-thin">
              Desde reparar tuberías hasta tomar fotografías consigue más
              clientes y aumenta tus ingresos
            </p>
          </div>
        </div>
      </div>
      <a
        href="#buttons"
        data-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="buttons"
      >
        <i class="fas fa-chevron-down fa-2x text-yellow mx-auto"></i>
      </a>
    </div>
  </div>
</div>
