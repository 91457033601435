import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID } from '@angular/core';
import { NgModule } from '@angular/core';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-CO';
registerLocaleData(localeEs, 'es-co');
// Services - Modules
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { RecaptchaModule } from "ng-recaptcha";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxJsonLdModule } from 'ngx-json-ld';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from "ngx-ui-loader";
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ImageService } from './services/image.service';

// Components
import { AppComponent } from './app.component';
import { NavbarComponent } from './general-components/shared/navbar/navbar.component';
import { FooterComponent } from './general-components/shared/footer/footer.component';
import { HomeComponent } from './general-components/home/home.component';
import { MapComponent } from './components/map/map.component';
import { ContactComponent } from './general-components/contact/contact.component';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { AboutComponent } from './general-components/about/about.component';
import { SearchComponent } from './general-components/search/search.component';
import { FindComponent } from './general-components/find/find.component';
import { ServiceComponent } from './general-components/service/service.component';
import { ServiceIndexComponent } from './general-components/service-index/service-index.component';
import { ServicesSearchComponent } from './general-components/services-search/services-search.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipComponent } from './general-components/tooltip/tooltip.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { TermsComponent } from './general-components/terms/terms.component';
import { PolicyComponent } from './general-components/policy/policy.component';
import { ServicesDesignComponent } from './general-components/services-design/services-design.component';
import { ModalPropertyComponent } from './general-components/modal-propertie/modal-propertie.component';
import { SuggestsComponent } from './general-components/suggests/suggests.component';
import { BlogService } from './services/blog/blog.service';
import { CanonicalComponent } from './general-components/shared/canonical/canonical.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { PdfComponent } from './general-components/pdf/pdf.component';
import { QRCodeModule } from 'angular2-qrcode';

// Plugins

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    AboutComponent,
    SearchComponent,
    MapComponent,
    FindComponent,
    ServiceComponent,
    ServiceIndexComponent,
    ServicesSearchComponent,
    ServicesDesignComponent,
    TooltipComponent,
    TermsComponent,
    PolicyComponent,
    ModalPropertyComponent,
    SuggestsComponent,
    CanonicalComponent,
    PdfComponent
  ],
  imports: [
    QRCodeModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDwGE_CeEzo4IjbZD_YoQdNBqN1jKachWo',
      libraries: [
        'places'
      ],
      language: 'es',
      region: 'co'
    }),
    AgmMarkerClustererModule,
    RecaptchaModule,
    NgxIntlTelInputModule,
    NgxJsonLdModule,
    SocialLoginModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgxSliderModule,
    NgbModule,
    IvyCarouselModule,
    NgxImageGalleryModule,
    NgApexchartsModule
  ],
  providers: [
    BlogService,
    NavbarComponent,
    ContactComponent,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {provide: LOCALE_ID, useValue: 'es-co' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('4732196760211711')
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('810216607766-vsjk24p04eir5546i7dudolfvqst52f5.apps.googleusercontent.com')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
