<div class="tooltip-cont">    
    <span id="btn-tooltip" class="d-block pointer position-relative" tabindex="0" data-toggle="tooltip" placement="top">
        <button class="border-0 bg-transparent" style="pointer-events: none;" type="button" disabled><i class="far fa-lg fa-question-circle"></i></button>
    </span>
    <div class="tooltip bs-tooltip-top d-flex flex-row align-items-start" role="tooltip" style="top: -4px;
    left: 30px;">
        <div class="arrow-left mt-n1">
            <img src="https://cdn.vivienda.com/images/icons/triangle.png" class="w-100 h-100">
        </div> 
        <div class="tooltip-inner text-color-black text-regular" style="font-size: .8rem;width: 180px;
        color: rgba(58, 62, 65, 1);">
        {{mensaje}}
        </div>
    </div>
</div>
    
