<!-- <div class="banner-index-service relative">
  <h1 class="text-banner text-bold">Los mejores servicios para tu hogar</h1>
</div> -->

<div class="bg-blue text-center text-white mt-5 mb-3 py-5 px-3 hideme">
  <h4>Tenemos todo lo que necesitas para tu hogar</h4>
  <h5>
    Encuentra la ayuda experta que necesitas en casa: desde plomeros, pintores,
    jardineros, electricistas y mucho más.
  </h5>
</div>

<div class="container-service mt-2 mb-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 px-lg-0 inputs-n">
        <!-- filtros -->
        <div class="icon-service-2">
          <div class="form-group relative w-100 d-flex align-items-center flex-row-reverse flex-lg-row border mt-3 mt-lg-0" style="border-radius: 20px;">
            <span class="text-gray location mx-2">
              <i class="fas fa-map-marker-alt fa-lg"></i>
            </span>
            <input
              type="text"
              name="city-filter"
              id="location-service-filter"
              [(ngModel)]="city"
              class="w-100 m-0 border-0"
              placeholder="Escribe la ciudad, zona o sector"
              autocomplete="off"
            />
            <app-tooltip
            mensaje="Escribe el nombre de la zona que deseas buscar"
            class="rounded-circle bg-white"
          ></app-tooltip>
          </div>
        </div>

        <nav class="navbar navbar-expand-lg p-0">
          <button
            class="navbar-toggler align-items-center text-white btn-red text-bold text-color-white text-center w-100 py-2 px-3 mb-3 no-close"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="mr-auto">Servicios para tu hogar y/o oficina</span>
            <i class="ml-2 fas fa-chevron-down"></i>
          </button>

          <div
            class="collapse navbar-collapse pb-2"
            id="navbarSupportedContent"
          >
            <div id="tip-submenu-services" class="w-100">
              <button type="button" class="close pb-2 d-lg-none">
                <span aria-hidden="true">&times;</span>
              </button>
              <input
                type="text"
                class="px-3 py-1 w-100 mt-0 mb-2 no-close"
                name="filter-cat"
                id="filter-cat-services"
                onkeyup="filterServices()"
              />
              <div class="content mb-3" id="accordion">
                <div
                  class="row option-submenu- align-items-center no-close pb-2 my-2 border-bottom"
                >
                  <div class="col-2">
                    <div class="check-submenu-mini">
                      <input
                        type="checkbox"
                        name="all-services"
                        id="all-services"
                        class="py-0 my-0 no-close"
                        (change)="selecAll()"
                        [checked]="allCheckeds()"
                      />
                      <label
                        for="all-services"
                        class="pointer no-close"
                      ></label>
                    </div>
                  </div>
                  <div class="col-10 px-0">
                    <p
                      class="my-0 pointer d-flex align-items-center justify-content-between mr-1"
                    >
                      Todas las categorías
                    </p>
                  </div>
                </div>
                <div
                  class="row option-submenu- align-items-center no-close pb-2 my-2 border-bottom"
                  *ngFor="let s of services; index as i"
                >
                  <div class="col-2">
                    <div class="check-submenu-mini no-close">
                      <input
                        type="checkbox"
                        name="{{ s.slug }}"
                        id="{{ s.slug }}"
                        class="no-close"
                        (change)="selectSubcategories(i)"
                        [checked]="isAllChecked(s.subservices)"
                        *ngIf="s.subservices.length > 0"
                      />
                      <input
                        type="checkbox"
                        name="{{ s.slug }}"
                        id="{{ s.slug }}"
                        class="no-close"
                        [(ngModel)]="services[i].checked"
                        (change)="getServices()"
                        *ngIf="s.subservices.length <= 0"
                      />
                      <label
                        for="{{ s.slug }}"
                        class="pointer no-close"
                      ></label>
                    </div>
                  </div>
                  <div class="col-10 px-0">
                    <p
                      class="text-capitalize my-0 pointer d-flex align-items-center justify-content-between mr-2 lh-1 no-close"
                      data-toggle="collapse"
                      [attr.data-target]="'#collapse-categories-' + i"
                      aria-expanded="true"
                      [attr.aria-controls]="'collapse-categories-' + i"
                    >
                      {{ s.name }} ({{ s.quantity }})
                      <i
                        class="fas fa-chevron-down no-close text-red"
                        *ngIf="s.subservices.length > 0"
                      ></i>
                    </p>
                  </div>
                  <div class="col-lg-12">
                    <div
                      id="collapse-categories-{{ i }}"
                      class="collapse w-100"
                      attr.aria-labelledby="heading-collapse-categories-{{ i }}"
                      data-parent="#accordion"
                      *ngIf="s.subservices.length > 0"
                    >
                      <div
                        class="row my-1 ml-3 align-items-center"
                        *ngFor="let sub of s.subservices; index as j"
                      >
                        <div class="col-2">
                          <div class="check-submenu-mini-submenu no-close">
                            <input
                              type="checkbox"
                              name="{{ s.slug }}-{{ sub.slug }}"
                              id="{{ s.slug }}-{{ sub.slug }}"
                              class="no-close"
                              [(ngModel)]="services[i].subservices[j].checked"
                              (change)="
                                services[i].checked = true; getServices()
                              "
                            />
                            <label
                              for="{{ s.slug }}-{{ sub.slug }}"
                              class="pointer no-close"
                            ></label>
                          </div>
                        </div>
                        <div class="col-10 px-0">
                          <label
                            for="{{ s.slug }}-{{ sub.slug }}"
                            class="no-close"
                          >
                            <p
                              class="text-capitalize my-0 pointer d-flex align-items-center justify-content-between mr-1 no-close lh-1"
                            >
                              {{ sub.name }}
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <a class="py-2 mt-2 d-md-block d-none bg-yellow text-white text-bold mx-auto pointer"
                onclick="$('#tip-submenu-services-href').click()" style="width: 100px;">Aplicar</a>
              <a class="py-2 mt-2 d-md-none d-block bg-yellow text-white text-bold mx-auto pointer"
                onclick="$('#tip-submenu-services-href').click()" style="width: 100px;">Aplicar</a> -->

              <a
                (click)="getServices()"
                class="btn btn-red no-close mx-auto"
              >
                Buscar
              </a>
            </div>
          </div>
        </nav>
      </div>
      <div class="col-lg-9">
        <!-- Resultados  -->
          <div class="row">
            <div class="col-lg-4 results-n" *ngFor="let s of results.data">
              <div class="card border overflow-hidden rounded-3 mb-3">
                <a href="/servicio/{{ s.profile.slug }}/{{ s.slug }}">
                  <img
                    class="card-img-top"
                    src="{{
                      s.images.length > 0
                        ? getFormatImage(s.images[0], '16x9', s.images[0].type)
                        : s.image
                    }}"
                    onerror="this.src = 'https://cdn.vivienda.com/images/icons/reg-serv.png'"
                  />
                </a>

                <div class="card-body p-2">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-auto"></div>
                    <div class="col-auto">
                      <div class="d-flex">
                        <ng-container
                          *ngFor="let serv of s.subservices; index as i"
                        >
                          <div
                            *ngIf="i < 2"
                            class="btn btn-sm btn-blue btn-rounded ml-2 text-capitalize text-truncate"
                            title="{{ serv.name }}"
                          >
                            {{ serv.name }}
                          </div>
                        </ng-container>
                        <div
                            *ngIf="s.subservices == 0"
                            class="btn btn-sm btn-blue btn-rounded ml-2 text-capitalize text-truncate"
                            title="{{ s.name }}"
                          >
                            {{ s.name }}
                          </div>
                      </div>
                    </div>
                  </div>

                  <h5
                    class="text-red my-1 text-truncate"
                    title="{{ s.name }}"
                  >
                    <a
                      href="/servicio/{{ s.profile.slug }}/{{ s.slug }}"
                      style="color: inherit"
                      >Servicio de {{ s.name }}
                    </a>
                  </h5>
                  <p
                    class="text-description-service text-thin mb-2 small text-truncate-3 lh-1"
                  >
                    {{ s.description }}
                  </p>
                  
                  <!-- Trabaja en: -->
                  <h6
                    class="text-semibold text-thin text-truncate font-italic"
                    style="font-size: 15px"
                  >
                  <i class="fas fa-map-marker-alt mr-2"></i>

                      <span *ngFor="let l of s.locations; index as j"
                        >{{ l.city
                        }}{{ j + 1 < s.locations.length ? ", " : "" }}</span
                      >
                  </h6>
                  
                  <a href="./perfil/{{ s.profile.slug }}" class="text-dark">
                    <div  class="row align-items-center mt-1">
                      <div class="col-2 pr-0">
                        <img
                          src="{{
                            s.profile.image
                              ? s.profile.image
                              : 'https://cdn.vivienda.com/images/icons/profile.png'
                          }}"
                          onerror="this.src  = 'https://cdn.vivienda.com/images/icons/profile.png'"
                          alt=""
                          class="img-fluid rounded-circle aspect-ratio-1"
                        />
                      </div>
                      <div class="col-6 font-weight-bold small lh-1">
                        <div class="capitalize-first">
                          {{ s.profile.name }}
                        </div>
                      </div>
                      <div class="col-4 text-right small font-italic"><u>Ver perfil</u></div>
                    </div>
                  </a>

                  <a class="btn btn-red w-100 btn-rounded mt-2 font-italic" href="/servicio/{{ s.profile.slug }}/{{ s.slug }}">
                    COTIZAR
                  </a>
                  
                  
                </div>
              </div>
            </div>
          </div>
        <div class="my-2 text-center" *ngIf="results.next_page_url">
          <a
            (click)="loadMore()"
            class="btn btn-red-outline btn-rounded px-5 font-weight-bold"
          >
            <span class="px-5"> VER MÁS </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
