<div class="container">
  <div class="row">
    <div class="col-12">
      <h2 class="text-black text-color-black mb-3 mb-md-2 mt-4 mt-md-0 text-center">
        Mapa <span class="text-thin">Interactivo</span>
      </h2>
    </div>
    <hr class="col-md-11 col-11" />
  </div>

  <div class="row px-md-5 mx-md-5">
    <div class="col-lg-6 col-12">
      <div class="form-group mb-0">
        <label>
          <p class="text-thin">
            <b>Punto de referencia</b>
          </p>
        </label>
        <input type="text" class="px-3 w-md-80" id="search" name="search" placeholder="Ej. Estación trasmilenio Banderas" />
      </div>
    </div>
    <div class="col-lg-6 col-12 mt-md-3">
      <div class="form-group row mb-0">
        <div class="col-md-6">
          <a href="#offer" data-toggle="collapse" class="d-inline-block btn-yellow-outline text-white text-semibold p-2 px-1 w-100 p-80 tipo-inmueble">
            <p class="mb-0 float-left text-bold">En {{ offer["arriendo"] ? "arriendo" : "" }} {{ offer["venta"] ? "venta" : "" }}</p>
            <span class="float-right">
              <i class="fas fa-chevron-down mt-1"></i>
            </span>
          </a>
          <div id="offer" class="collapse submenu no-close">
            <div class="row no-close">
              <div class="col no-close text-left pt-3">
                <p class="col-12" (click)="offer['arriendo'] = !offer['arriendo']; resetItems()">
                  <i class="fas fa-circle" style="color: #E20140"></i>
                  En arriendo
                  <i *ngIf="!offer['arriendo']" class="far fa-square float-right mt-1 no-close"></i>
                  <i *ngIf="offer['arriendo']" class="fas fa-check-square float-right mt-1 no-close" style="color: #f7bf50"></i>
                </p>
                <p class="col-12" (click)="offer['venta'] = !offer['venta']; resetItems()">
                  <i class="fas fa-circle" style="color: #4694A1"></i>
                  En venta
                  <i *ngIf="!offer['venta']" class="far fa-square float-right mt-1 no-close"></i>
                  <i *ngIf="offer['venta']" class="fas fa-check-square float-right mt-1 no-close" style="color: #f7bf50"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <a href="#precio" data-toggle="collapse" class="d-inline-block btn-yellow-outline text-white text-semibold p-2 px-1 w-100 p-80 tipo-inmueble">
            <p class="mb-0 float-left text-bold">Precio</p>
            <span class="float-right">
              <i class="fas fa-chevron-down mt-1"></i>
            </span>
          </a>
          <div id="precio" class="collapse submenu p-3 no-close" style="width: 350px">
            <div class="row no-close">
              <div class="col no-close" [formGroup]="sliderFormPriceRange" *ngIf="(offer['arriendo'] && !offer['venta']) || (!offer['arriendo'] && !offer['venta'])">
                <p class="text-medium text-color-black p-90 mb-4 pb-3 no-close">
                  <span class="float-left no-close">Desde</span>
                  <span class="float-right no-close">Hasta</span>
                </p>
                <ngx-slider
                  [(value)]="price.min"
                  [(highValue)]="price.max"
                  [options]="optionsPriceRange"
                  formControlName="sliderControlPriceRange"
                  (userChangeEnd)="onUserChangeEnd($event)"
                >
                </ngx-slider>
              </div>

              <div class="col no-close" [formGroup]="sliderFormPriceRangeVenta" *ngIf="offer['venta'] && !offer['arriendo']">
                <p class="text-medium text-color-black p-90 mb-4 pb-3 no-close">
                  <span class="float-left no-close">Desde</span>
                  <span class="float-right no-close">Hasta</span>
                </p>
                <ngx-slider
                  [(value)]="priceVenta.min"
                  [(highValue)]="priceVenta.max"
                  [options]="optionsPriceRangeVenta"
                  formControlName="sliderControlPriceRangeVenta"
                  (userChangeEnd)="onUserChangeEnd($event, 'priceVenta')"
                >
                </ngx-slider>
              </div>

              <div class="col no-close" [formGroup]="sliderFormPriceRangeMix" *ngIf="offer['arriendo'] && offer['venta']">
                <p class="text-medium text-color-black p-90 mb-4 pb-3 no-close">
                  <span class="float-left no-close">Desde</span>
                  <span class="float-right no-close">Hasta</span>
                </p>
                <ngx-slider
                  [(value)]="priceMix.min"
                  [(highValue)]="priceMix.max"
                  [options]="optionsPriceRangeMix"
                  formControlName="sliderControlPriceRangeMix"
                  (userChangeEnd)="onUserChangeEnd($event, 'priceMix')"
                >
                </ngx-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row px-md-5 mx-md-5 justify-content-center">
    <div class="col-6 col-md-2 text-center">
      <p class="mb-0">
        <small>
        <i class="fas fa-circle" style="color: #E20140"></i>
        Arriendo
        </small>
      </p>
    </div>

    <div class="col-6 col-md-2 text-center">
      <p class="mb-0">
        <small>
        <i class="fas fa-circle" style="color: #4694A1"></i>
        Venta
        </small>
      </p>
    </div>
  </div>

  <div class="col-12 px-1 pt-1 pb-3">
    <app-map
      [lat]="lat"
      [lng]="lng"
      [location]="location"
      [offer]="offer"
      [price]="price"
      [withProperty]="withProperty"
    ></app-map>
  </div>
</div>
