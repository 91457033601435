import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { TokenService } from '../services/auth/token.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import * as Cookies from 'js-cookie';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  browser: boolean;
  constructor(
    private tokenService: TokenService,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.browser = isPlatformBrowser(platformId);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.browser) {
      const accessToken = this.tokenService.getToken();
      const customer = Cookies.get('customer');
      if (!req.headers.has('Authorization')) {
        req = req.clone({
          setHeaders: {
            Authorization: "Bearer " + (typeof accessToken != 'undefined' ? accessToken : 'null'),
            Customer: typeof customer != 'undefined' ? customer : 'null'
          }
        });
      }
    } else {
      const header_value = environment.api_key;
      req = req.clone({
        headers: req.headers.set(header_value, environment.api_value)
      });
    }
    return next.handle(req);
  }
}