import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../services/../../environments/environment'
import { TokenService } from './token.service';
import { Router, GuardsCheckEnd } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private http: HttpClient, private tokenService: TokenService, private router: Router) { }

  // User registration
  register(user): Observable<any> {
    return this.http.post(`${environment.api_uri}auth/register`, user);
  }

  // Login
  signin(user): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Headers': '*',
      })
    }
    return this.http.post<any>(`${environment.api_uri}auth/login`, user);
  }

  // Login with social networks
  signinSocial(user: any): Observable<any> {
    return this.http.post<any>(`${environment.api_uri}auth/login/networks`, user);
  }

  logOut(forceAlert = true, from = 'others') {
    if (forceAlert) {
      Swal.fire({
        title: 'Cerrando la sesión, espera un momento...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onBeforeOpen: () => Swal.showLoading()
      });
    }
    const tokenDestroy = this.tokenService.getToken();
    let tokenSession = this.tokenService.isLoggedIn();

    if (tokenSession) {
      this.http.post(`${environment.serverUrl}/api/auth/logout`, tokenDestroy)
        .subscribe(
          response => {
            this.closeAll(from == 'others')
          },
          error => {
            this.closeAll(from == 'others')
          }
        );
    } else {
      this.closeAll(from == 'others')
    }
  }

  closeAll(redirect = true) {
    this.tokenService.removeToken();
    this.tokenService.isLoginSubject.next(false);
    localStorage.removeItem('user');

    localStorage.removeItem('facebook');
    localStorage.removeItem('google');

    let urlParams = new URLSearchParams(window.location.search);
    let content = '';
    let finalUri = '';
    if (finalUri != './auth/login' && finalUri != './auth/register' && finalUri != '/')  {
      if (Object.keys(urlParams).length <= 0) {
        this.router.events.subscribe(
          (event: any) => {
            if (event instanceof GuardsCheckEnd && redirect) {
              window.location.href = './auth/login?redirectTo=' + event.url;
            }
          }
        );
      } else {
        urlParams.forEach((value, index) => {
          content += index + '=' + value + '&';
        });
        let uri = './auth/login';
        if (content != '') {
          uri += '?' + content;
        }
        if (redirect) { window.location.href = uri };
      }
    }
    if (redirect) { window.location.href = '/'; }
  }

  restorePassword(body): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/api/auth/password`, body);
  }

  passwordRecovery(body): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/api/auth/password/recovery`, body);
  }

  verifiedAccount(body, format = 'email'): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/api/auth/verification/${format}/code/validation`, body);
  }

  generateCode(format = 'email'): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/api/auth/verification/${format}`, {});
  }

  handleAction(action: string, response: any): void {
    switch (action) {
      case 'servicios':
        if (response.hasServicesProfessional || response.hasServicesCompany) {
          location.href = './usuario/perfil?tab=servicios&action=register';
        } else {
          location.href = './usuario/registro-servicio?action=registrar-servicio';
        }
        break;
    }
  }
}
