import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {

  constructor(
    protected meta: Meta,
    protected title: Title
  ) {
    this.title.setTitle(`Política de tratamiento de datos personales | Vivienda.com`);
    this.meta.addTags([
      { name: 'description', content: 'Conoce como Vivienda.com recolectará, conservará, utilizará, compartirá, tratará y transmitirá los Datos Personales.' },
      { property: 'og:title', content: this.title.getTitle() },
      { property: 'og:description', content: 'Conoce como Vivienda.com recolectará, conservará, utilizará, compartirá, tratará y transmitirá los Datos Personales.' },
      { property: 'og:url', content: 'https://vivienda.com/politica-de-tratamiento-de-datos-personales' },
      { property: 'og:image', content: 'https://cdn.vivienda.com/images/logo.png' }
    ])
  }

  ngOnInit(): void {
  }

}
