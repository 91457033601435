import { AgmInfoWindow, AgmMap, MapsAPILoader } from '@agm/core';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { isNumber } from 'ngx-bootstrap/chronos/utils/type-checks';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
declare const $: any;

@Component({
  selector: 'app-find',
  templateUrl: './find.component.html',
  styleUrls: ['./find.component.css']
})
export class FindComponent implements OnInit {
  browser = false;
  lat = 4.6799359;
  lng = -74.1603453;
  zoom: number = 13;
  distance = 10;
  properties = [];
  currentIW: AgmInfoWindow = null;
  previousIW: AgmInfoWindow = null;
  map;
  location = [
    { lat: 0, lng: 0 },
    { lat: 0, lng: 0 }
  ];
  timeOut: any;

  offer = {
    arriendo: true,
    venta: true
  };

  price = {
    min: 100000,
    max: 5000000,
    active: false
  };
  sliderFormPriceRange: FormGroup = new FormGroup({
    sliderControlPriceRange: new FormControl([100000, 5000001])
  });
  optionsPriceRange: Options = {
    floor: 100000,
    ceil: 5000001,
    step: 100000,
    draggableRange: true,
    translate: (value: any): string => {
      return (value <= 5000000) ? (parseInt(value)).toLocaleString('es-CO', {style: 'currency', currency: 'COP', maximumFractionDigits: 0, minimumFractionDigits: 0}) : '+ $ 5.000.000';
    }
  };

  priceVenta = {
    min: 10000000,
    max: 500000000,
    active: false
  };
  sliderFormPriceRangeVenta: FormGroup = new FormGroup({
    sliderControlPriceRangeVenta: new FormControl([10000000, 500000001])
  });
  optionsPriceRangeVenta: Options = {
    floor: 10000000,
    ceil: 500000001,
    step: 5000000,
    draggableRange: true,
    translate: (value: any): string => {
      return (value <= 500000000) ? (parseInt(value)).toLocaleString('es-CO', {style: 'currency', currency: 'COP', maximumFractionDigits: 0, minimumFractionDigits: 0}) : '+ $ 500.000.000';
    }
  };

  priceMix = {
    min: 100000,
    max: 500000000,
    active: false
  };
  sliderFormPriceRangeMix: FormGroup = new FormGroup({
    sliderControlPriceRangeMix: new FormControl([100000, 50000000001])
  });
  optionsPriceRangeMix: Options = {
    floor: 100000,
    ceil: 500000001,
    step: 500000,
    draggableRange: true,
    translate: (value: any): string => {
      return (value <= 500000000) ? (parseInt(value)).toLocaleString('es-CO', {style: 'currency', currency: 'COP', maximumFractionDigits: 0, minimumFractionDigits: 0}) : '+ $ 500.000.000';
    }
  };
  rangeTimeout = null;

  activeMarker = false;

  withProperty = '';

  constructor(
    protected mapsAPILoader: MapsAPILoader,
    protected ngZone: NgZone,
    protected apiService: ApiConnectionService,
    protected meta: Meta,
    protected title: Title,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.browser = isPlatformBrowser(platformId);
    this.title.setTitle(`Buscar por mapa | Vivienda.com`);
    this.meta.addTags([
      { name: 'title', content: this.title.getTitle() },
      { name: 'description', content: 'Encuentra apartamentos, casas, oficinas, bodegas, fincas, lotes y más inmuebles en venta o arriendo. Vivienda.com el sitio web para comprar, vender y arrendar finca raíz totalmente gratis.' },
      { name: 'og:title', content: this.title.getTitle() },
      { name: 'og:description', content: 'Encuentra apartamentos, casas, oficinas, bodegas, fincas, lotes y más inmuebles en venta o arriendo. Vivienda.com el sitio web para comprar, vender y arrendar finca raíz totalmente gratis.' },
      { name: 'og:url', content: 'https://vivienda.com/mapa' }
    ]);
  }

  ngOnInit(): void {
    if(this.browser) {
      this.setCurrentPosition();
      this.mapsAPILoader.load().then(() => {
        const autocomplete = new google.maps.places.Autocomplete($('#search')[0]);
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();

            if (place.geometry === undefined || place.geometry === null) {return;}
            this.lat = place.geometry.location.lat();
            this.lng = place.geometry.location.lng();
          });
        });
      });
    }
  }

  setCurrentPosition(): void {
    let loc:any = location.search;
    if(loc){
        this.lat = Number( new URLSearchParams(window.location.search).get('lat'));
        this.lng = Number( new URLSearchParams(window.location.search).get('lng'));
        let code = new URLSearchParams(window.location.search).get('code')
        this.withProperty = sessionStorage.getItem(code);
        sessionStorage.removeItem(code);
    }
    else if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.zoom = 13;
          loc = { lat: this.lat, lng: this.lng, zoom: this.zoom };
          localStorage.setItem('recent_locations', JSON.stringify(loc));
          var date = new Date();
          date.setDate(date.getDate() + 365);
          document.cookie =
            'recent_locations = ' + JSON.stringify(loc) + '; expire = ' + date;
        },
        error => {
          this.lat = 4.6799359;
          this.lng = -74.1603453;
          this.zoom = 13;
        }
      );
    } else {
      this.lat = 4.6799359;
      this.lng = -74.1603453;
      this.zoom = 13;
    }
  }

  geocodePosition() {
    if (this.browser) {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: {
            lat: this.lat,
            lng: this.lng,
          },
        },
        (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            $('#reference, #reference-mobile').val(
              results[0].formatted_address
            );
          }
        }
      );
    }
  }

  onUserChangeEnd(changeContext: ChangeContext, type = 'price'): void {
    this[type].active = true;
    if (this.rangeTimeout) {
      clearTimeout(this.rangeTimeout);
      this.rangeTimeout = null;
    }
    this.rangeTimeout = setTimeout(() => {
      this.rangeTimeout = null;
    }, 1500);
  }

  resetItems() {
    this.price = {
      min: 100000,
      max: 5000000,
      active: false
    };

    this.priceVenta = {
      min: 10000000,
      max: 500000000,
      active: false
    };

    this.priceMix = {
      min: 100000,
      max: 500000000,
      active: false
    };
  }
}
