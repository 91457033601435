<agm-map
  #AgmMap
  [latitude]="lat"
  [longitude]="lng"
  [zoom]="zoom"
  [mapTypeId]="'hybrid'"
  
  (mapReady)="onMapReady($event)"
  (boundsChange)="boundsChange($event)"
  [disableDoubleClickZoom]="true"
  *ngIf="browser"
  
>
<agm-marker-cluster maxZoom="11" minimumClusterSize="10" imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
  <agm-marker
    *ngIf="!withProperty"
    [latitude]="lat"
    [longitude]="lng"
    [iconUrl]="
      $any({
        url: './assets/img/person-location.png',
        scaledSize: { height: 40, width: 40 }
      })
    "
    [markerDraggable]="true"
    (dragEnd)="markerDragEnd($event)"
  ></agm-marker>
  <ng-container *ngFor="let p of properties; index as i">
    <agm-marker
      *ngIf="compareLat(p.slug); else bigMarker"
      [latitude]="p.latitude"
      [longitude]="p.longitude"
      (markerClick)="markerClick(infoWindow,p.slug)"
      [iconUrl]="
        $any({
          url:
            p.type_offer == 'arriendo'
              ? './assets/img/arriendo-icon-marker.png'
              : './assets/img/venta-icon-marker.png',
          scaledSize: { height: 25, width: 25 }
        })
      "
    >
      <agm-info-window #infoWindow>
        <div *ngIf="!propertySelected;else infoSelectedP" class="row">
          <div class="col-md-5 p-0 text-center">
            <a href="./propiedad/{{ p.slug }}">
              <img
              src="{{
                p.images && p.images.length > 0
                  ? image.getFormatImage(
                      p.images[0],
                      '16x9-180x100',
                      p.images[0].type
                    )
                  : './assets/img/loading-img.gif'
              }}"
                onerror="this.src = './assets/img/loading-img.gif'"
                class="w-85 img-fluid"
              />
            </a>
          </div>
          <div class="col-md-7 p-0 text-center text-md-left">
            <p class="text-color-black text-bold mb-0">
              {{ p.type_property.type_property }} en {{ p.type_offer }}
            </p>
            <p
              class="text-color-black text-medium mb-0"
              *ngIf="p.areas.length > 0"
            >
              {{ getArea(p) }} m<sup>2</sup>
            </p>
            <a
              href="./propiedad/{{ p.slug }}"
              class="d-block text-yellow text-bold btn-pink"
              >Ver detalle</a
            >
          </div>
        </div>
        <ng-template #infoSelectedP>
          <ng-container >
            <div  class="row">
              <div class="col-md-5 p-0 text-center">
                <a href="./propiedad/{{ propertySelected.slug }}">
                  <img
                    [src]="propertySelected.images || 'https://cdn.vivienda.com/images/properties/no-disponible.jpg'"
                    onerror="this.src = './assets/img/loading-img.gif'"
                    class="w-85 img-fluid"
                  />
                </a>
              </div>
              <div class="col-md-7 p-0 text-center text-md-left">
                <p class="text-color-black text-bold mb-0">
                  {{ propertySelected.type_property.type_property }} en {{ propertySelected.type_offer }}
                </p>
                <p
                  class="text-color-black text-medium mb-0"
                  *ngIf="propertySelected.areas.length > 0"
                >
                  {{ getArea(propertySelected) }} m<sup>2</sup>
                </p>
                <a
                  href="./propiedad/{{ propertySelected.slug }}"
                  class="d-block text-yellow text-bold btn-pink"
                  >Ver detalle</a
                >
              </div>
            </div>
          </ng-container>
        </ng-template>

      </agm-info-window>
    </agm-marker>
    <ng-template #bigMarker>
      <ng-container>
        <agm-marker
          [latitude]="p.latitude"
          [longitude]="p.longitude"
          (markerClick)="markerClick(infoWindowBig,p.slug)"
          [iconUrl]="
            $any({
              url:
                p.type_offer == 'arriendo'
                  ? './assets/img/arriendo-icon-marker-big.png'
                  : './assets/img/venta-icon-marker-big.png',
              scaledSize: { height: 50, width: 50 }
            })
          "
        >
          <agm-info-window #infoWindowBig>
            <div *ngIf="!propertySelected;else infoSelectedPB" class="row">
              <div class="col-md-5 p-0 text-center">
                <a href="./propiedad/{{ p.slug }}">
                  <img
                  src="{{
                    p.images && p.images.length > 0
                      ? image.getFormatImage(
                          p.images[0],
                          '16x9-180x100',
                          p.images[0].type
                        )
                      : './assets/img/loading-img.gif'
                  }}"
                    onerror="this.src = '/assets/img/loading-img.gif'"
                    class="w-85 img-fluid"
                  />
                </a>
              </div>
              <div class="col-md-7 p-0 text-center text-md-left">
                <p class="text-color-black text-bold mb-0">
                  {{ p.type_property.type_property }} en {{ p.type_offer }}
                </p>
                <p
                  class="text-color-black text-medium mb-0"
                  *ngIf="p.areas.length > 0"
                >
                  {{ getArea(p) }} m<sup>2</sup>
                </p>
                <a
                  href="./propiedad/{{ p.slug }}"
                  class="d-block text-yellow text-bold btn-pink"
                  >Ver detalle</a
                >
              </div>
            </div>
            <ng-template #infoSelectedPB>
              <ng-container>
                <div class="row">
                  <div *ngIf="false" class="col-md-5 p-0 text-center">
                    <a href="./propiedad/{{ propertySelected.slug }}">
                      <img
                        [src]="p.images && p.images.length > 0 ? image.getFormatImage(p.images[0],'16x9-180x100',p.images[0].type) : './assets/img/loading-img.gif'"
                        onerror="this.src = './assets/img/loading-img.gif'"
                        class="w-85 img-fluid"
                      />
                    </a>
                  </div>
                  <div class="col-md-7 p-0 text-center text-md-left">
                    <p class="text-color-black text-bold mb-0">
                      {{ propertySelected.type_property.type_property }} en BG {{ propertySelected.type_offer }}
                    </p>
                    <p
                      class="text-color-black text-medium mb-0"
                      *ngIf="propertySelected.areas.length > 0"
                    >
                      {{ getArea(propertySelected) }} m<sup>2</sup>
                    </p>
                    <a
                      href="./propiedad/{{ propertySelected.slug }}"
                      class="d-block text-yellow text-bold btn-pink"
                      >Ver detalle</a
                    >
                  </div>
                </div>
              </ng-container>

            </ng-template>
          </agm-info-window>
        </agm-marker>
      </ng-container>
    </ng-template>
  </ng-container>
  </agm-marker-cluster>
</agm-map>
