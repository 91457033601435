import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  NgZone,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/auth/token.service';
import { Router, ActivationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { HomeService } from 'src/app/services/home/home.service';
import { MapsAPILoader } from '@agm/core';
import * as Cookies from 'js-cookie';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
declare const $: any;
declare const gtag: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  session = false;
  browser = false;
  user: any = [];
  home: any;
  multiSelect = true
  contentText = 'Todos';
  contentTextType = 'Arrendar';
  type_offer = 'arrendar';
  type_properties = [
    { checked: false, type_property: 'Finca', slug: 'finca' },
    { checked: false, type_property: 'Casa', slug: 'casa' },
    { checked: false, type_property: 'Edificio', slug: 'edificio' },
    { checked: false, type_property: 'Bodega', slug: 'bodega' },
    { checked: false, type_property: 'Lote', slug: 'lote' },
    { checked: false, type_property: 'Apartamento', slug: 'apartamento' },
    { checked: false, type_property: 'Local', slug: 'local' },
    { checked: false, type_property: 'Consultorio', slug: 'consultorio' },
    { checked: false, type_property: 'Oficina', slug: 'oficina' },
    { checked: false, type_property: 'Parqueaderos', slug: 'parqueadero' },
  ];
  services = [];
  allServices = false;
  cities = [];
  citiesSlug = [];
  city = null;
  active = {
    tp: false,
  };

  lat = null;
  lng = null;

  routeChecker = {};
  isHome: boolean;
  constructor(
    protected mapsAPILoader: MapsAPILoader,
    protected ngZone: NgZone,
    public tokenService: TokenService,
    private authService: AuthService,
    public router: Router,
    protected http: HttpClient,
    public homeService: HomeService,
    protected apiService: ApiConnectionService,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.browser = isPlatformBrowser(platformId);
    if (this.browser) {
      this.parseUriItems();
      $(document).ready(function () {
        $('body').on('click', function (event) {
          if (
            !$(event.target).hasClass('no-close') &&
            !$(event.target).is('label')
          )
            setTimeout(() => {
              $('header .collapse').removeClass('show');
            }, 100);
        });
      });
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 100 && window.location.pathname == '/') {
          // console.log(window.location.pathname);

          $('#buscadornav').removeClass('home');
        } else if (window.location.pathname == '/') {
          $('#buscadornav').addClass('home');
        }
      });
      this.getServicesList();
    }
  }

  ngOnInit(): void {
    this.session = this.tokenService.isLoggedIn();
    if (this.session)
      this.user = JSON.parse(localStorage.getItem('user')) || {};

    if (this.browser) {
      $(document).ready(function () {
        $('.close-menu').click(function () {
          $('#account3').removeClass('show');
        });
        $('.directorio-aliado').mouseenter(function () {
          $('.directorio-collapse').show();
        });
        $('.directorio-collapse').mouseleave(function () {
          $('.directorio-collapse').hide();
        });
        $('.directorio-collapse').mouseenter(function () {
          $('.directorio-collapse').show();
        });
        $('.directorio-aliado').mouseleave(function () {
          $('.directorio-collapse').hide();
        });
      });
      this.mapsAPILoader.load().then(() => {
        // tslint:disable-next-line:new-parens
        let counter = 0;
        $('.cities').each(() => {
          const autocomplete = new google.maps.places.Autocomplete(
            $('.cities')[counter]
          );
          autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
              const place: google.maps.places.PlaceResult =
                autocomplete.getPlace();

              if (place.geometry === undefined || place.geometry === null) {
                return;
              }
              place.address_components.forEach((v) => {
                v.types.forEach((value) => {
                  if (value == 'locality' && this.city != v.long_name)
                    this.city = v.long_name;
                });
              });
              this.lat = place.geometry.location.lat();
              this.lng = place.geometry.location.lng();
            });
          });
          counter++;
        });
      });
      const urlParams = window.location.pathname
        .split('/')
        .filter((v) => v && v != '' && v.trim() != '');
      if (urlParams[0] == 'servicios') {
        this.type_offer = 'servicios';
        this.contentTextType = 'Servicios';
        if (urlParams[1] && urlParams[1] != 'buscador') {
          var services = decodeURIComponent(urlParams[1]).split('_');
          var data = [];
          services.forEach((v) => {
            var index = this.services.findIndex((value) => value.slug == v);
            if (index > -1) {
              this.services[index].checked = true;
              data.push(this.services[index].name);
            }

            this.contentText =
              data.length > 0 ? data.join(', ') : 'Selecciona...';
          });
        } else {
          this.allServices = true;
          this.contentText = 'Todos los servicios';
          this.services.forEach((v, i) => {
            this.services[i].checked = true;
          });
        }
      }

      $('#menu').click(function () {
        $('#MainMenu').toggleClass('show');
      });
    }
  }

  getServicesList() {
    var services = JSON.parse(sessionStorage.getItem('services'));
    if (!services) {
      this.apiService
        .getData('services?onlyParamsStatic=true', { auth: false })
        .subscribe(
          (response: any) => {
            this.services = response;
            sessionStorage.setItem('services', JSON.stringify(this.services));
            this.services.forEach((v, i) => {
              this.services[i].checked = false;
            });
          },
          (error) => console.log(error)
        );
    } else {
      this.services = services;
      //console.log(this.services);

      this.services.forEach((v, i) => {
        this.services[i].checked = false;
      });
    }
  }

  logOut() {
    this.authService.logOut();
  }
  changeToMultiselection(){
    this.contentText = this.multiSelect ? 'Todos' : 'Seleccione...';
    this.type_properties = [
      { checked: false, type_property: 'Finca', slug: 'finca' },
      { checked: false, type_property: 'Casa', slug: 'casa' },
      { checked: false, type_property: 'Edificio', slug: 'edificio' },
      { checked: false, type_property: 'Bodega', slug: 'bodega' },
      { checked: false, type_property: 'Lote', slug: 'lote' },
      { checked: false, type_property: 'Apartamento', slug: 'apartamento' },
      { checked: false, type_property: 'Local', slug: 'local' },
      { checked: false, type_property: 'Consultorio', slug: 'consultorio' },
      { checked: false, type_property: 'Oficina', slug: 'oficina' },
    ];
  }
  changeTextContentText(item: any = []) {
    let name = ''
    this.type_properties.forEach((v:any) => {

      if(v.slug != item.slug) {
        v.checked = false
      }else{
        name = item.type_property
      }
    });


      this.contentText = name ? item.type_property : 'Selecciona...';

  }
  changeTextContentMultiText(item: any = []) {


    if (item.slug == 'all' && !item.checked) {
      this.type_properties = [
        { checked: true, type_property: 'Todos', slug: 'all' },
        { checked: false, type_property: 'Finca', slug: 'finca' },
        { checked: false, type_property: 'Casa', slug: 'casa' },
        { checked: false, type_property: 'Edificio', slug: 'edificio' },
        { checked: false, type_property: 'Bodega', slug: 'bodega' },
        { checked: false, type_property: 'Lote', slug: 'lote' },
        { checked: false, type_property: 'Apartamento', slug: 'apartamento' },
        { checked: false, type_property: 'Local', slug: 'local' },
        { checked: false, type_property: 'Consultorio', slug: 'consultorio' },
        { checked: false, type_property: 'Oficina', slug: 'oficina' },
      ];
      this.contentText = 'Todos';
    } else {


      // this.type_properties[0].checked = false;


      let label = document.getElementById(item.slug + '-label');
      label.classList.contains('active')
        ? label.classList.remove('active')
        : label.classList.add('active');
      var array =
        this.contentText != 'Selecciona...' ? this.contentText.split(', ') : [];
      if (array.includes('Todos')) {
        var index = array.findIndex((tp) => tp == 'Todos');
        array.splice(index, 1);
      }
      if (array.includes(item.type_property)) {
        var index = array.findIndex((tp) => tp == item.type_property);
        array.splice(index, 1);
      } else {
        array.push(item.type_property);
      }
      this.contentText = array.length > 0 ? array.join(', ') : 'Selecciona...';
    }
  }

  changeTextContentTextService(item: any = [], force = false) {
    var array =
      this.contentText != 'Selecciona...' &&
      this.contentText != 'Todos los servicios'
        ? this.contentText.split(', ')
        : [];
    if (array.includes(item.name)) {
      var index = array.findIndex((s) => s == item.name);
      array.splice(index, 1);
    } else {
      if (force) {
        array.push(item.name);
      } else {
        if (!item.checked) array.push(item.name);
        else
          this.services.forEach((v) => {
            if (v.checked && v.slug != item.slug) {
              array.push(v.name);
            }
          });
      }
    }
    var quantity = 0;
    setTimeout(() => {
      this.services.forEach((v) => {
        if (v.checked) {
          quantity++;
        }
      });
      if (quantity == this.services.length) {
        this.allServices = true;
        this.contentText = 'Todos los servicios';
      } else {
        this.allServices = false;
        this.contentText =
          array.length > 0 ? array.join(', ') : 'Selecciona...';
      }
    }, 100);
  }

  search() {
    if (this.type_offer != 'servicios') {
      var uri = '';
      var typeProperties = [];
        this.type_properties.forEach((v) => {
          if (v.checked) typeProperties.push(v.slug);
        });
      var typeProperty = typeProperties.join('-');
      var typeOffer = this.type_offer;
      const city = this.city;
      if (typeProperty.length > 0) uri += '&typeProperty=' + typeProperty;
      if (typeOffer) {
        typeOffer =
          typeOffer == 'servicios'
            ? typeOffer
            : typeOffer == 'arrendar' || typeOffer == 'arriendo'
            ? 'arriendo'
            : 'venta';
        uri += '&typeOffer=' + typeOffer;
      }
      if (this.lat && this.lng)
        uri +=
          '&lat=' +
          this.lat +
          '&lng=' +
          this.lng +
          (city ? '&city=' + city : '');
      const slug = this.buildSlug(typeProperty, typeOffer, city);
      window.location.href =
        './busqueda?source=nav&search=true' +
        uri +
        (slug ? '&slug=' + slug : '');
    } else {
      var uri = '';
      var services = [];
      this.services.forEach((v) => {
        if (v.checked) {
          services.push(v.slug);
        }
      });
      var service = services.join('_');
      const city = this.city;
      if (service.length <= 0 || this.allServices) service = 'buscador';
      if (this.lat && this.lng)
        uri +=
          '&lat=' +
          this.lat +
          '&lng=' +
          this.lng +
          (city ? '&city=' + city : '');
      window.location.href =
        './servicios/' + service + '?source=nav&search=true' + uri;
    }
  }

  buildSlug(typeProperty, typeOffer, city) {
    let slug = '';
    city = this.city
      ? this.homeService.clearString(city.split(' ').join('-')).toLowerCase()
      : null;
    if (typeProperty.length > 0) slug += typeProperty;
    if (typeOffer) slug += slug.length > 0 ? '-en-' + typeOffer : typeOffer;
    if (city) slug += slug.length > 0 ? '-en-' + city : city;

    return slug;
  }

  clickEvent(event = '', category = '', label = '', value = 'true'): void {
    gtag('event', event, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }

  checkRouteParam(prefix) {
    return this.router.url.includes(prefix);
  }
  checkRoute(ruta) {
    if (this.router.url == ruta) return true;
    else false;
  }
  checkRouterParam(prefix = '/', compare = '/', checker = ''): any {
    this.router.events.subscribe((event: any) => {
      if (event instanceof ActivationEnd) {
        var path = event.snapshot.routeConfig.path;
        var final = prefix + path;
        if (final == compare) {
          this.routeChecker[checker] = true;
          return true;
        }
      }
    });
    return this.routeChecker[checker];
  }

  parseUriItems() {
    const params = new URLSearchParams(window.location.href);
    const typeProperty = params.get('typeProperty');
    if (typeProperty) {
      this.type_properties[5].checked = false;
      this.contentText = '';
      const tps = typeProperty.split('-');
      this.type_properties.forEach((v, i) => {
        const index = tps.findIndex((value) => value == v.slug);
        if (index > -1) {
          this.contentText += this.type_properties[i].type_property + ', ';
          this.type_properties[i].checked = true;
        }
      });
    }
    if (this.contentText.slice(-2, this.contentText.length) == ', ')
      this.contentText = this.contentText.slice(0, -2);
    this.type_offer = params.get('typeOffer') || 'arrendar';
    this.city = params.get('city');
    this.lat = params.get('lat');
    this.lng = params.get('lng');
    setTimeout(() => $('.input-busqueda, .input-nav').val(this.city), 500);
  }

  findIndex(object, param, val) {
    return object.findIndex((value) => value[param] == val);
  }

  openWordpress() {
    const url =
      'https://blog.vivienda.com/wp-login-personalize.php?auth=' +
      Cookies.get('vivienda_com_auth_token');
    window.open(url, '_blank');
  }

  servicePublished() {
    if (this.session) return './usuario/perfil?tab=servicios&action=register';
    return './Servicios';
  }

  useAllServices() {
    if (this.contentText == 'Todos los servicios') {
      this.contentText = 'Selecciona...';
      this.services.forEach((v, i) => {
        this.services[i].checked = false;
      });
    } else {
      this.contentText = 'Todos los servicios';
      this.services.forEach((v, i) => {
        this.services[i].checked = true;
      });
    }
  }
}
