import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { RecaptchaComponent } from 'ng-recaptcha';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
import { TokenService } from 'src/app/services/auth/token.service';
import { HomeService } from 'src/app/services/home/home.service';
import Swal from 'sweetalert2/dist/sweetalert2';

@Component({
  selector: 'app-suggests',
  templateUrl: './suggests.component.html',
  styleUrls: ['./suggests.component.css']
})
export class SuggestsComponent implements OnInit {
  user: any = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    message: ''
  };
  captcha = null;
  browser = false;
  session = false;

  @ViewChild('captchaRef')
  public captchaRef: RecaptchaComponent;

  constructor(
    protected fb: FormBuilder,
    protected tokenService: TokenService,
    protected homeService: HomeService,
    protected http: ApiConnectionService,
    private metaTagService: Meta,
    protected title: Title,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.browser = isPlatformBrowser(platformId);
    this.session = this.tokenService.isLoggedIn();
  }

  ngOnInit(): void {
    if (this.session) {
      try {
        const data = JSON.parse(localStorage.getItem('user'));
        this.user.name = data?.name;
        this.user.surname = data?.surname;
        this.user.email = data?.email;
        this.user.phone = data?.cellphone;
      } catch (e) {}
    }
    this.metaTagService.addTags([
      { name: 'keywords', content: 'contáctanos, vivienda, apartamento, arriendo, inmueble, bienes raices, residencia, domicilio, casa, habitación' },
      { name: 'title', content: 'Contáctanos' },
      { name: 'description', content: 'Nos tomamos muy en serio nuestro compromiso con nuestros usuarios. Si necesitas nuestra ayuda con tu cuenta de usuario, tienes preguntas sobre cómo utilizar la plataforma o tienes dificultades técnicas, no dudes en ponerte en contacto con nosotros.' },
      { name: 'og:title', content: 'Contáctanos' },
      { name: 'og:description', content: 'Nos tomamos muy en serio nuestro compromiso con nuestros usuarios. Si necesitas nuestra ayuda con tu cuenta de usuario, tienes preguntas sobre cómo utilizar la plataforma o tienes dificultades técnicas, no dudes en ponerte en contacto con nosotros.' },
      { name: 'og:url', content: 'https://vivienda.com/contactanos' },
      { name: 'og:image', content: 'https://cdn.vivienda.com/images/contacts/banner.jpg' },
    ]);
    this.title.setTitle(`Sugerencias - Vivienda.com`);
  }

  contact(f: any): void {
    if (f.form?.status == 'VALID') {
      let data = f.form.value;
      data['g-recaptcha-response'] = this.captcha;
      data['phone'] = data['cellphone'];
      data['metadata'] = 'suggests';
      Swal.fire({
        title: 'Espera un momento...',
        text: 'Estamos guardando tu solicitud',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onBeforeOpen: () => Swal.showLoading()
      });
      this.http.postData('contact', data, {auth: false})
      .subscribe(
        (response) => {
          Swal.fire({
            title: '¡Mensajes enviado!',
            icon: 'success'
          }).then(() => {
            history.pushState({}, 'Gracias por contactarnos', './sugerencias/gracias');
          });
        },
        (error) => {
          if (error.status == 401) {
            Swal.fire({
              title: 'Confirma que no eres un robot',
              icon: 'warning',
              confirmButtonText: 'Validar'
            }).then(() => {
              this.captchaRef.reset();
              this.session = false;
            });
          } else if (error.status == 400) {
            let errors = this.homeService.parseErrorResponse(error);
            Swal.fire({
              title: 'Formulario incorrecto',
              html: errors.message,
              icon: errors.icon
            }).then(() => {
              if (!this.session)
                this.captchaRef.reset();
            });
          } else if (error.status == 406) {
            Swal.fire(error.error.message, '', 'warning')
            .then(() => window.location.reload());
          } else {
            Swal.fire({
              title: 'En este momento no podemos atender tu solicitud',
              text: 'Por favor intenta más tarde',
              icon: 'danger'
            }).then(() => window.location.reload());
          }
        }
      )
    }
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }
}
