<div class="container-fluid position-relative banner-img" id="about-page">
    <div class="container pt-4 pb-2" style="z-index: 5;">
        <h1 class="text-white pt-3 text-semibold ml-2 mb-3 mb-md-5">¿Qué hemos logrado?</h1>
        <div class="row px-md-0 mt-3 text-center">
            <div class="col-6 col-md-3">
                <h2 class="text-white text-semibold">
                    <img src="https://cdn.vivienda.com/images/icons/inmuebles-venta.png" width="40px"> {{ stats.sale }}
                </h2>
                <p class="text-medium text-white">Inmuebles en venta</p>
            </div>
            <div class="col-6 col-md-3">
                <h2 class="text-white text-semibold">
                    <img src="https://cdn.vivienda.com/images/icons/inmuebles-arriendo.png" width="40px"> {{ stats.rent }}
                </h2>
                <p class="text-medium text-white">Inmuebles en arriendo</p>
            </div>
        </div>
        <div class="row px-md-0 mt-4 text-center">
            <div class="col-6 col-md-3">
                <h2 class="text-white text-semibold">
                    <img src="https://cdn.vivienda.com/images/icons/profesionales-registrados.png" width="40px"> {{ stats.professionals }}
                </h2>
                <p class="text-medium text-white">Profesionales registrados</p>
            </div>
            <div class="col-6 col-md-3">
                <h2 class="text-white text-semibold">
                    <img src="https://cdn.vivienda.com/images/icons/clientes-satisfechos.png" width="40px"> {{ stats.users }}
                </h2>
                <p class="text-medium text-white">Clientes satisfechos</p>
            </div>
        </div>
        <div class="row mb-5 px-md-0 mt-4 text-center">
            <div class="col-6 col-md-3">
                <h2 class="text-white text-semibold">
                    <img src="https://cdn.vivienda.com/images/icons/empresas-registradas.png" width="40px"> {{ stats.companies }}
                </h2>
                <p class="text-medium text-white">Empresas registradas</p>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid mb-5 mt-2">
    <div class="row text-center text-md-left">
        <div class="col-md-6 px-2 px-md-5 my-auto">
            <h1 class="text-color-black text-semibold subtitle mb-2 mt-3 mt-md-0 mb-md-4">Sobre Nosotros</h1>
            <h5 class="text-gray text-regular pr-0 pr-md-5 mb-4 mb-md-0 mx-3 mx-md-0">Somos un portal que integra todo lo que necesitas para tu vivienda. Podrás además vender, comprar inmuebles nuevos y usados y si quieres publicar el tuyo tendrás todas las herramientas disponible para hacerlo. Complementando con todos los servicios que tu hogar pueda llegar a necesitar</h5>
        </div>
        <div class="col-md-6 px-0">
            <img src="https://cdn.vivienda.com/images/about/about.jpg" class="w-100">
        </div>
    </div>
</div>
<div class="container">
    <h2 class="text-black text-color-black text-center mt-5 mb-4 my-md-5">¿Qué te <span class="text-thin">Ofrecemos?</span></h2>
    <div class="row my-4">
        <div class="col-12 col-md-6 col-lg-3 my-2 px-md-3">
            <div class="card p-0">
                <img src="https://cdn.vivienda.com/images/icons/catalogo-inmuebles.png" class="mx-auto mt-4">
                <h6 class="text-semibold text-color-black text-center mt-3 card-titulo">Catálogo de inmuebles</h6>
                <p class="text-thin text-color-black text-center p-90 card-desc px-2 mb-2">Podrás encontrar tu
                    inmueble ideal. según tus necesidades podrás filtrar y guardar tus búsquedas para que te encuentres
                    fácilmente tu nuevo hogar.</p>
                <a href="./busqueda" class="d-block m-2 text-medium text-white text-center py-2 btn-yellow">
                    <h5 class="mb-0 text-bold">Conocer más</h5>
                </a>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 my-2 px-md-3">
            <div class="card p-0">
                <img src="https://cdn.vivienda.com/images/icons/servicios-inmueble.png" class="mx-auto mt-4">
                <h6 class="text-semibold text-color-black text-center mt-3 card-titulo">Servicios para tu inmueble</h6>
                <p class="text-thin text-color-black text-center p-90 card-desc px-2 mb-2">Encontrarás todo lo
                    que necesitas. Unimos a los mejores profesionales independientes con personas y empresas que buscan
                    un servicio de calidad aumentando el valor de tu inmueble.</p>
                <a href="./servicios/buscador" class="d-block m-2 text-medium text-white text-center py-2 btn-yellow">
                    <h5 class="mb-0 text-bold">Conocer más</h5>
                </a>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 my-2 px-md-3">
            <div class="card p-0">
                <img src="https://cdn.vivienda.com/images/icons/publicar-inmueble.png" class="mx-auto mt-4">
                <h6 class="text-semibold text-color-black text-center mt-3 card-titulo">Publica tu inmueble</h6>
                <p class="text-thin text-color-black text-center p-90 card-desc px-2 mb-2">Rápido, fácil y sin
                    trámites. Sólo con tu registro lograrás dar a conocer tu inmueble gratis y de esta manera comenzar a
                    recibir muchas visitas. En poco tiempo encontrarás tu próximo inquilino.</p>
                <a href="./propiedad/registrar" class="d-block m-2 text-medium text-white text-center py-2 btn-yellow">
                    <h5 class="mb-0 text-bold">Conocer más</h5>
                </a>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 my-2 px-md-3">
            <div class="card p-0">
                <img src="https://cdn.vivienda.com/images/icons/soluciones-negocio.png" class="mx-auto mt-4">
                <h6 class="text-semibold text-color-black text-center mt-3 card-titulo">Soluciones de negocio</h6>
                <p class="text-thin text-color-black text-center p-90 card-desc px-2 mb-2">Con el fin de
                    ayudarte a crecer. Ofrece tus servicios a nivel nacional, tanto como empresa como profesional
                    indpendiente sin necesidad de pauta y totalmente gratis.</p>
                <a href="./usuario/registro-inmobiliario"
                    class="d-block m-2 text-medium text-white text-center py-2 btn-yellow">
                    <h5 class="mb-0 text-bold">Conocer más</h5>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="container-fluid mt-5 mb-2">
        <div class="row">
            <div class="col-md-6 px-2 px-md-5 my-auto text-center text-md-left">
                <h2 class="text-color-black text-semibold subtitle mb-4">¿A dónde vamos?</h2>
                <h5 class="text-gray text-regular pr-0 pr-md-5 mb-4 mb-md-0">Nuestra misión es transformar el sector
                    inmobiliario para conseguir que nuestros clientes vendan su casa lo más raápido posible, de forma
                    fácil y transparente. Tendrá a su disposición servicios de marketing, inmobiliarios y legales.</h5>
            </div>
            <div class="col-md-6 px-0">
                <div class="row">
                    <div class="col-6 px-1">
                        <img src="https://cdn.vivienda.com/images/about/donde-1.jpg" class="w-100">
                    </div>
                    <div class="col-6 px-1">
                        <img src="https://cdn.vivienda.com/images/about/donde-2.jpg" class="w-100">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-6 px-1">
                        <img src="https://cdn.vivienda.com/images/about/donde-3.jpg" class="w-100">
                    </div>
                    <div class="col-6 px-1">
                        <img src="https://cdn.vivienda.com/images/about/donde-4.jpg" class="w-100">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
