// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_uri: 'https://api.vivienda.com/api/',
  serverUrl: 'https://api.vivienda.com',
  wordpress_uri:'https://learning.vivienda.com/wp-json/wp/v2',
  encryption_key: '123',
  api_value: '123',
  api_key: '123',
  type_documents: [
    { name: 'Tarjeta de Identidad', value: 'TI' },
    { name: 'Cédula de Ciudadanía', value: 'CC' },
    { name: 'Cédula de Extranjería', value: 'CE' },
    { name: 'Pasaporte', value: 'PA' },
    { name: 'NIT', value: 'NIT' }
  ],
  social: [
    { name: 'LinkedIn', image: 'https://cdn.vivienda.com/images/icons/linkedin-foot.png', value: '' },
    { name: 'Facebook', image: 'https://cdn.vivienda.com/images/icons/facebook-foot.png', value: '' },
    { name: 'Twitter', image: 'https://cdn.vivienda.com/images/icons/twitter-foot.png', value: '' },
    { name: 'Blogspot', image: 'https://cdn.vivienda.com/images/icons/blogspot.png', value: '' },
    { name: 'Instagram', image: 'https://cdn.vivienda.com/images/icons/instagram.png', value: '' },
    { name: 'YouTube', image: 'https://cdn.vivienda.com/images/icons/youtube.png', value: '' },
    { name: 'RSS', image: 'https://cdn.vivienda.com/images/icons/rss.png', value: '' }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
