import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
import { BlogService } from 'src/app/services/blog/blog.service';

import { Router } from "@angular/router";
import { TokenService } from 'src/app/services/auth/token.service';
declare const $: any;


@Component({
  selector: 'app-services-search',
  templateUrl: './services-search.component.html',
  styleUrls: ['./services-search.component.css']
})
export class ServicesSearchComponent implements OnInit {
  services = [];
  servicesRanked = {
    data: [],
    next_page_url: null
  };
  isLoggedIn: boolean = false;
  browser = false;

  aliados = [1,2,3,4,5,6]

  blogPosts: any;
  id!: string;
  errorMessage: any;

  constructor(
    protected tokenService: TokenService,
    protected apiService: ApiConnectionService,
    protected http: HttpClient,
    protected activateRoute: ActivatedRoute,
    protected blogService: BlogService,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.browser = isPlatformBrowser(platformId);
    this.isLoggedIn = tokenService.isLoggedIn();
  }

  ngOnInit(): void {
    $('.carousel').owlCarousel({
      autoHeight: true,
      nav: true,
      dots: false,
      loop: true,
      margin: 10,
      items: 1,
    });

    this.getAll();
    this.getRanked();   
    this.getPosts();
   }

   getRanked(){
  	this.apiService.getData('services?ranked=true', {auth: false})
      .subscribe(
        response => {
          this.servicesRanked = response;  
        },
        error => {
          console.log(error);
        }
      );

      setTimeout(() => {
        $('.carouselranked').owlCarousel({
          autoHeight: true,
          nav: true,
          dots: false,
          loop: true,
          margin: 15,
          items: 3,
          responsive : {
            // breakpoint from 0 up
            0 : {
                items: 1,
            },
            768 : {
              items: 3,
            }
        }
        });
      }, 2000);
  }

  getAll(){
  	this.apiService.getData('services', {auth: false})
      .subscribe(
        response => {
          this.services = response;
          
        },
        error => {
          console.log(error);
        }
      );
    
      setTimeout(() => {
        this.carouselServices();
      }, 3000);
  }

  carouselServices (){
    $('.carouselservices').owlCarousel({
      autoHeight: true,
      nav: true,
      dots: false,
      loop: true,
      margin: 10,
      items: 6,
      responsive : {
        // breakpoint from 0 up
        0 : {
            items: 2,
        },
        768 : {
          items: 4,
        },
        1200 : {
          items: 6,
        }
    }
    });
  }

  getPosts() {
    this.blogService.getPosts().subscribe(
      (data) => {
        this.blogPosts = data;
        console.log(this.blogPosts);
      },
      (error) => {
        // if any error, Code throws the error
        this.errorMessage = error.message;
        console.log(this.errorMessage, 'error');
      }
    );
  }
  
  // loadMore() {
  //   this.http.get(this.services.next_page_url)
  //     .subscribe(
  //       (response: any) => {
  //         response.data.forEach(v => {
  //           this.services.data.push(v);
  //         });
  //         this.services.next_page_url = response.next_page_url;
  //       }
  //     )
  // }
}