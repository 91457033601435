import { Component, Inject, OnInit, PLATFORM_ID, ViewChild, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiConnectionService } from 'src/app/services/api-connection.service';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import Swal from 'sweetalert2/dist/sweetalert2';
import { RecaptchaComponent } from 'ng-recaptcha';
import { TokenService } from 'src/app/services/auth/token.service';
import { HomeService } from 'src/app/services/home/home.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { HostListener } from '@angular/core';
import { GALLERY_CONF, GALLERY_IMAGE, NgxImageGalleryComponent } from 'ngx-image-gallery';
declare const $: any;
declare const gtag: any;

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {
  services: any = {
    subservices: [],
    images: []
  };
  service: any = '';
  slug: any = '';
  profile: any = {};
  typeSaved = null;
  moresub = false;
  moreloc = false;
  imageGroup = [];
  lists = [];
  listSelected = null;
  list = {
    name: '',
    description: ''
  };

  user: any = {};

  schema: any = {};
  showMap = false;

  browser = false;
  head = '';
  whatsapp = [];

  uri;
  captcha;

  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showImageTitle: false,
    reactToMouseWheel: true,
    showExtUrlControl: false,
    reactToRightClick: true,
    imagePointer: true
  };
  
  images: GALLERY_IMAGE[] = [];

  imagePosition = 0;

  finalResponse: boolean = false;

  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;

  @HostListener("window:scroll", ['$event'])
  doSomethingOnWindowScroll($event: Event) {
    if (document.body.scrollTop > 110 || document.documentElement.scrollTop > 110) {
      document.getElementById('details').classList.add('down');
      document.getElementById('details').classList.remove('d-fix');
      document.getElementById('details').classList.remove('d-none');
    } else {
      document.getElementById('details').classList.remove('down');
      document.getElementById('details').classList.add('d-none');
    }
  }

  @ViewChild('captchaRef') public captchaRef: RecaptchaComponent;
  @ViewChild('captchaRefReview') public captchaRefReview: RecaptchaComponent;
  showCaptcha = false;
  session = false;

  index = 0;

  relations = {
    favorites: false,
    saved: false
  };
  related = [];

  rate = null;

  constructor(
    protected mapsAPILoader: MapsAPILoader,
    protected ngZone: NgZone,
    protected http: ApiConnectionService,
    protected tokenService: TokenService,
    protected route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private meta: Meta,
    protected homeService: HomeService,
    protected authService: AuthService,
    protected httpClient: HttpClient,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.browser = isPlatformBrowser(platformId);
    route.params.subscribe(params => {
      this.service = params['service'];
      this.slug = params['profile'];
    });
    this.uri = window.location.href;
    this.showCaptcha = !this.tokenService.isLoggedIn();
    this.session = this.tokenService.isLoggedIn();
    if (this.session && this.browser) {
      this.user = JSON.parse(localStorage.getItem('user'));
    }
  }

  ngOnInit(): void {
    this.getService();
  }

  getService(): void {
    this.http.postData('services/details/' + this.slug + '/' + this.service, {}, {auth: false})
    .subscribe(
      (response: any) => {
        this.services = response;
        this.profile = response.profile;
        console.log(this.services);
        
        this.relations = response.relations;
        this.related = response.related;
        this.buildImageGroup(response);
        this.setTotalAttributes(response);
        this.head = this.services.name + ' en ';
        this.head += this.services.locations[0].address;
        this.title.setTitle(this.head + ' | ' + this.title.getTitle());
        this.meta.addTag({name: 'language', content: 'es_CO'});
        this.meta.addTag({name: 'geo.position', content: this.services.locations[0].latitude + ', ' + this.services.locations[0].longitude});

        this.meta.addTag({name: 'title', content: this.title.getTitle()});
        this.meta.addTag({name: 'url', content: this.router.url});
        this.meta.addTag({name: 'description', content: this.buildDescription() + ' | ' + this.profile.name});
        this.meta.addTag({name: 'image', content: this.services.images[0] ? this.services.images[0].cropped : 'https://cdn.vivienda.com/images/properties/no-disponible.jpg'});
        this.meta.addTag({name: 'keywords', content: this.buildKeywordList()});

        this.meta.addTag({property: 'og:title', content: this.title.getTitle()});
        this.meta.addTag({property: 'og:url', content: this.router.url});
        this.meta.addTag({property: 'og:description', content: this.buildDescription()});
        this.meta.addTag({property: 'og:image', content: this.services.images[0] ? this.services.images[0].cropped : 'https://cdn.vivienda.com/images/properties/no-disponible.jpg'});

        this.meta.addTag({name: 'author', content: "woobsing"});
        this.meta.addTag({name: 'copyright', content: "woobsing"});

        this.schema = this.buildSchema();
        if (this.browser) {
          this.services.images.forEach((v) => {
            this.images.push({
              url: v.path,
              thumbnailUrl: v.path
            });
          });
          this.loadDescriptionLength();
        }

        this.finalResponse = true;
      },
      (error: any) => {
        if (this.browser) {
          if (error.status == 404) {
            Swal.fire({
              title: 'Este servicio no se encuentra disponible o no existe',
              text: 'Por favor inténtalo de nuevo'
            }).then(() => {
              window.location.href = './servicios';
            });
          } else {
            this.http.parseDefaultHttpResponse(error);
          }
        } else {
          console.log(error);
        }
      }
    )
  }

  loadDescriptionLength() {
    setTimeout(() => {
      if ($(".desc-p").text().length >= 180) {
        $(".desc-p").text($(".desc-p").text().substr(0, 182).replace(/\s+$/, "") + '...');
        $("#desc-b").removeClass('d-none');
      }
    }, 200);
  }

  removeDups(array) {
    let unique = {};
    array.forEach(function(i) {
      if(!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }

  replaceImage(image, index, forceOpen = true) {
    this.index = index;
    if (image.type == 'image') {
      if (forceOpen) { this.openGallery(index); }
      $('#imageContainer > img').removeClass('d-none');
      $('#imageContainer > img').attr('src', this.getFormatImage(image, '16x9'));
      $('#videoContainer > iframe').addClass('d-none');
    } else {
      $('#videoContainer > iframe').removeClass('d-none');
      $('#videoContainer > iframe').attr('src', image.path);
      $('#imageContainer > img').addClass('d-none');
    }
  }

  descriptionContent() {
    if ($("#service-description").hasClass('show-content')) {
      $("#service-description").text(this.services.description.slice(0, 200).replace(/\s+$/, "") + '...');
      $("#service-description > a").html('Ver más <i class="fas fa-chevron-down ml-2"></i>');
      $("#service-description").removeClass('show-content');
    } else {
      let content = this.services.description;
      $("#service-description").text(content);
      $("#service-description > a").html('Ver menos <i class="fas fa-chevron-up ml-2"></i>');
      $("#service-description").addClass('show-content');
    }
  }

  setTotalAttributes(response) {
    response.contacts.forEach((value, index) => {
      value.phones.forEach((v, i) => {
        this.whatsapp.push(v.phone);
      });
    });
  }

  buildKeywordList(): string {
    let description = this.services.name + ', ';
    this.services.subservices.forEach((v, i) => {
    	  description += v.name + ', ';
    });

    this.services.locations.forEach((v, i) => {
        description += v.city + ', ';
    });

    description +=  this.profile.name + ', ';

    if (description.slice(description.length - 2) == ', ')
      description = description.slice(0, -2) + ' ';
    return description;
  }

  buildDescription(): string {
    let description = this.head + ' ';
    this.services.subservices.forEach((v, i) => {
    	  description += v.name + ', ';
    });
    if (description.slice(description.length - 2) == ', ')
      description = description.slice(0, -2) + ' ';
    return description;
  }

  buildImageGroup(response) {
    let groups = [];
    response.images.forEach((value, index) => {
      value.thumb = this.getFormatImage(value, '16x9-180x100');
      groups.push(value);
    });
    this.imageGroup = groups;
  }

  contact(f): void {
    if (f.form.status == 'VALID') {
      Swal.fire({
        title: 'Estamos notificando tu solicitud...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => Swal.showLoading()
      });
      f.value['profileslug'] = this.profile.slug;
      f.value['slug'] = this.services.slug;
      f.value['g-recaptcha-response'] = this.captcha;
      this.http.postData('contact/services', f.value, {auth: false})
      .subscribe(
        response => {
          Swal.fire(response.message, response.data, 'success')
            .then(() => {
              if (!this.session) {
                Swal.fire({
                  title: 'Ahora te invitamos a registrarte',
                  showCancelButton: true,
                  confirmButtonText: 'Registrarme',
                  cancelButtonText: 'Cancelar',
                  reverseButtons: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    localStorage.setItem('userParams', JSON.stringify({
                      name: f.value['name'],
                      surname: f.value['surname'],
                      cellphone: f.value['cellphone'],
                      email: f.value['email']
                    }));
                    window.location.href = './auth/register?from=' + window.location.href + '&action=contactForm&section=service';
                  } else
                    window.location.reload();
                });
              } else
                window.location.reload();
            });
        },
        error => {
          if (error.status == 401) {
            Swal.fire({
              title: 'Para poder continuar por favor confirma que no eres un robot'
            }).then(() => {
              this.showCaptcha = true;
            })
          } else if (error.status == 400) {
            Swal.fire({
              title: 'Asegúrate que pusiste un correo válido',
            }).then(() => {
              this.captchaRef.reset();
            });
          } else {
            Swal.fire({
              title: 'En este momento no podemos atender tu solictud, por favor inténtalo más tarde',
            });
          }
        }
      )
    }
  }

  buildSchema(): object {
    return {
      "@context": "http://schema.org/",
      "@type": [
        "LocalBusiness"
      ],
      "name": this.head + ' ' + this.profile.name,
      "image": this.services.images[0] ? this.services.images[0].cropped : 'https://cdn.vivienda.com/images/properties/no-disponible.jpg',
      "url": 'https://vivienda.com/' + this.router.url,
      "telephone": this.services.contacts[0].phones[0].phone,
      "address": {
        "@type": "PostalAddress",
        "addressLocality": this.services.locations[0].address,
        "addressRegion": this.services.locations[0].city
      },
      "description": this.buildDescription(),
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": this.services.locations[0].latitude,
        "longitude": this.services.locations[0].longitude
      }
    }
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }

  copyLink() {
    var $temp = $("<input>");
    $("body").append($temp);
    let uri = this.uri;
    $temp.val(uri).select();
    document.execCommand("copy");
    $temp.remove();
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: '¡Copiado!',
      showConfirmButton: false,
      timer: 500
    })
  }

  collapseItems(slug) {
    $(".sub-cat.collapse").each(function () {
      if ($(this).attr('id') != slug && $(this).hasClass('show')) {
        $(this).removeClass('show');
      }
    });
  }

  removeClass(id, value) {
    $("#" + id).removeClass(value);
  }

  getFormatImage(image, size, type = 'image', forceFormat = null) {
    if (type == 'image') {
      var file = 'https://cdn.vivienda.com/images/services/no-disponible.jpg';
      if (typeof image.thumbs !== 'undefined' && image.thumbs.length > 0) {
        const index = image.thumbs.findIndex(v => v.size == size);
        if (index > -1)
          file = image.thumbs[index].image;
      } else {
        var extension = image.path.substr(-3);
        if (!this.isAvailableImageFormat(extension)) {
          extension = image.path.substr(-4);
          if (!this.isAvailableImageFormat(extension)) {
            return file;
          }
        }
        if (!forceFormat)
          file = image.path.replace('.' + extension, '') + '-' + size + '.' + extension;
        else
          file = image.path.replace('.' + extension, '') + '-' + size + '.' + forceFormat;
      }

      return file;
    } else if (type == 'video')
      return this.getMimeVideo(image);

    return image.path;
  }

   isAvailableImageFormat(format): boolean {
    const formats = ['jpeg', 'jpg', 'png', 'jpe', 'jfif'];
    return formats.includes(format);
  }

  checkVideo() {
    let has = false;
    let uri = '';
    this.services.images.forEach(v => {
      if (v.type == 'video') {
        uri = v.path;
        has = true;
      }
    });
    if (has && this.browser)
      $("#video, #videoMobile").attr('src', uri);
    return has;
  }

  getMimeVideo(video) {
    try {
      var host = new URL(video.path);
      switch (host.hostname) {
        case 'www.youtube.com':
          let base = host.pathname.split('/');
          return 'https://i.ytimg.com/vi/' + base[2] + '/mqdefault.jpg';
          break;
      }
    } catch (e) {
      return 'https://i.ytimg.com/';
    }
  }

  getFirstImageFormat() {
    var image: any = {};
    this.services.images.forEach(v => {
      if (v.type == 'image' && !image.path)
        image = v;
    });
    return image.path && image.path.trim() !== '' ? image : null;
  }

  getFirstImageFormatT(images) {
    var image: any = {};
    images.forEach(v => {
      if (v.type == 'image' && !image.path)
        image = v;
    });
    return image.path && image.path.trim() !== '' ? image : null;
  }

  changeImage(method) {
    if (method == 'next') {
      if (this.imageGroup[this.index + 1]) {
        this.replaceImage(this.imageGroup[this.index + 1], this.index + 1, false);
      } else {
        this.index = 0;
        this.replaceImage(this.imageGroup[this.index], this.index, false);
      }
    } else {
      if (this.imageGroup[this.index - 1]) {
        this.replaceImage(this.imageGroup[this.index - 1], this.index - 1, false);
      } else {
        this.index = this.imageGroup.length - 1;
        this.replaceImage(this.imageGroup[this.index], this.index, false);
      }
    }
    if (this.imageGroup.length > 1) {
      let index = this.index;
      let counter = 0;
      $(".carousel-item.carousel-gallery").each(function () {
        $(this).removeClass('active');
        if (counter == index)
          $(this).addClass('active');
        counter++;
      });
    }
  }

  savedService(type = '') {
    Swal.fire({
      title: 'Espera un momento...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });
    if (type !== '') {
      this.typeSaved = type;
      this.http.postData('services/saved', {
        profile : this.profile.slug,
        services: this.services.id,
        type: type,
        attach: this.relations[type]
      }, {auth: true})
      .subscribe(
        response => {
          Swal.fire({
            title: response.message,
            icon: 'success'
          }).then(() => {
            this.relations[type] = !this.relations[type];
          });
        },
        error => {
          Swal.close();
          if (error.status == 401) {
            Swal.fire({
              title: 'Para poder realizar esta acción por favor inicia sesión'
            }).then(() => {
              this.authService.logOut(false);
              window.location.href = './auth/login?redirectTo=/servicio/' + this.services.slug;
            });
          } else if (error.status == 400) {
            $("#lists").modal('show');
            this.lists = error.error.data;
            if (this.lists.length <= 0)
              this.list.name = 'Lista N° ' + (this.lists.length + 1);
          }
        }
      )
    } else {
      let list = null;
      if (this.lists.length > 0) {
        if (!this.listSelected && this.list.name.trim() == '') {
          Swal.fire({
            title: 'Por favor selecciona una lista o llena el formulario para crear una nueva',
            icon: 'warning'
          });
          return;
        }
        list = this.listSelected ? this.listSelected : this.list;
      } else {
        if (this.list.name.trim() == '') {
          Swal.fire({
            title: 'Por favor asigna un nombre a tu lista',
            icon: 'warning'
          });
          return;
        }
        list = this.list;
      }

      this.http.postData('services/saved', {
        list: list,
        profile : this.profile.slug,
        service: this.services.slug,
        type: this.typeSaved,
        format: 'fromlist'
      }, {auth: true})
        .subscribe(
          response => {
            Swal.fire({
              title: response.message,
              icon: 'success',
            }).then(() => {
              this.relations[this.typeSaved] = !this.relations[this.typeSaved];
              this.list = {
                name: '',
                description: ''
              };
              this.typeSaved = null;
              this.lists = [];
              this.listSelected = null;
              $("#lists").modal('hide');
            });
          },
          error => {
            Swal.fire({
              title: 'En estos momentos no podemos atender tu solicitud',
              icon: 'error'
            });
          }
        )
    }
  }

  selectRate(number, from = '') {
    this.rate = 0;
    this.removeRateOver(from);
    this.rate = number;
    for (var i = 0; i <= number; i++) {
      $('.rate-modal.rate-' + i + from + ' > i').removeClass('far');
      $('.rate-modal.rate-' + i + from + ' > i').addClass('fas');
    }
  }

  rateOver(position, from = '') {
    if (!this.rate) {
      for (var i = 0; i <= position; i++) {
        $('.rate-modal.rate-' + (i + 1) + from + ' > i').removeClass('far');
        $('.rate-modal.rate-' + (i + 1) + from + ' > i').addClass('fas');
      }
    }
  }

  removeRateOver(from = '') {
    if (!this.rate) {
      for (var i = 0; i <= 5; i++) {
        $('.rate-modal.rate-' + (i + 1) + from + ' > i').removeClass('fas');
        $('.rate-modal.rate-' + (i + 1) + from + ' > i').addClass('far');
      }
    }
  }

  resetModal() {
    this.rate = 0;
    this.captcha = null;
    this.captchaRefReview.reset();
    $("#rate-comment").val('');
    this.removeRateOver();
  }

  saveRate(f) {
    if (this.captcha && this.captcha !== '' && this.rate) {
      Swal.fire({
        title: 'Estamos guardando la reseña, espera un momento...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => Swal.showLoading()
      });
      const body = {
        score: this.rate,
        message: f.value['rate-comment'],
        'g-recaptcha-response': this.captcha
      };
      this.http.postData('services/' + this.service + '/' + this.slug + '/rate', body, {auth: false})
        .subscribe(
          response => {
            Swal.fire({
              title: response.message,
              icon: 'success'
            }).then(() => {
              window.location.reload();
              $("#rate-modal").modal('hide');
              this.resetModal();
            })
          },
          error => {
            if (error.status == 401) {
              Swal.fire({
                title: 'Para continuar por favor inicia sesión',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Iniciar sesión',
                reverseButtons: true
              }).then(result => {
                if (result.value)
                  window.location.href = './auth/login?redirectTo=' + window.location.pathname;
              });
            } else if (error.status == 409) {
              Swal.fire({
                title: error.error.message,
                icon: 'warning'
              }).then(() => {
                $("#rate-modal").modal('hide');
                this.resetModal();
              });
            } else {
              Swal.fire({
                title: error.error.message,
                icon: 'warning'
              }).then(() => {
                this.captchaRefReview.reset();
              });
            }
          }
        )
    }
  }

  errorImage(image, size, id) {
    if (this.browser)
      $("#" + id).attr('src', this.getFormatImage(image, size, 'image', 'png'));
  }

  writeMe(mobile) {
    if (mobile) {
      $("html, body").animate({
        scrollTop: $("div#contact-form").offset().top - 140
      }, 1000);
    }
    $("div#contact-form").animate({
      scrollTop: $('form.form-contact').offset().top - 60
    }, 2000);
  }

  openGallery(index: number = 0) {
    this.ngxImageGallery.open(index);
  }
  
  closeGallery() {
    this.ngxImageGallery.close();
  }
  
  newImage(index: number = 0) {
    this.ngxImageGallery.setActiveImage(index);
  }
  
  nextImage(index: number = 0) {
    this.ngxImageGallery.next();
  }
  
  prevImage(index: number = 0) {
    this.ngxImageGallery.prev();
  }
}
