import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor() { }

  getFormatImage(image, size, type = 'image', forceFormat = null): string {
    if (type == 'image') {
      var file = 'https://cdn.vivienda.com/images/properties/no-disponible.jpg';
      if (typeof image.thumbs !== 'undefined' && image.thumbs.length > 0) {
        const index = image.thumbs.findIndex((v) => v.size == size);
        if (index > -1) file = image.thumbs[index].image;
      } else {
        var extension = image.path.substr(-3);
        if (!this.isAvailableImageFormat(extension)) {
          extension = image.path.substr(-4);
          if (!this.isAvailableImageFormat(extension)) {
            return file;
          }
        }
        file = `${image.path.replace('.' + extension, '')}-${size}.${(!forceFormat ? extension : forceFormat)}`;
      }

      return file;
    }

    return image.path;
  }

  isAvailableImageFormat(format): boolean {
    const formats = ['jpeg', 'jpg', 'png', 'jpe', 'jfif'];
    return formats.includes(format);
  }
}
