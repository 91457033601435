<ng-template #loading>
  <div class="emptyBlock p-5 text-center">
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-template>
<div id="pdfContainer" class="" *ngIf="property.type_property?.type_property; else loading">
  <div class="">
    <div class="border-top border-danger mb-1" style="border-width: 10px !important"></div>
    <div>
      <div class="row mx-0">
        <div class="col-md-8 col-lg-9 col-xl-10 px-0">
          <div class="text-white">
            <div class="text-right">
              <img src="./assets/img/qrshadow.png" alt="" width="40px" />
            </div>
            <div class="bg-red pt-2 pb-4 pl-3 pl-md-5">
              <h2 class="mb-0 font-weight-bold text-uppercase">
                {{ property.type_property?.type_property }} en
                {{ property.type_offer }}
              </h2>
              <h4 class="font-weight-normal mb-0">
                {{ property.zone + ", " }} {{ property.city }}
              </h4>
            </div>
            <div class="bg-blue pl-3 pl-md-5 pr-3 py-2" style="
                width: fit-content;
                border-radius: 0 0.5em 0.5em 0;
                position: relative;
                margin-top: -1em;
              ">
              <h5 class="mb-0">
                ${{ property.amount }} {{ property.currency }}
              </h5>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-lg-3 col-xl-2 px-0">
          <div class="row mx-0 mt-2 mt-md-0">
            <div class="col-auto px-md-0 ml-auto mx-md-auto">
              <qr-code [value]="qrslug" [size]="187"></qr-code>
              <div class="bg-blue p-1 text-center text-white mx-auto" style="
                width: fit-content;
                border-radius: 0 0 1em 1em;
                position: relative;
                width: 84%;
              ">
                <h6 class="mb-0 small">
                  <span class="font-weight-bold"> Escanea Aquí </span>
                  <br />
                  para más información
                </h6>
              </div>
            </div>
            <div class="col-md px-0 d-none d-md-block">
              <div class="text-white">
                <div style="height: 30px;"></div>
                <div class="bg-red pt-2 pb-4">
                  <h2 class="mb-0 font-weight-bold text-uppercase">
                    &nbsp;&nbsp;
                  </h2>
                  <h4 class="font-weight-normal mb-0">
                    &nbsp;&nbsp;
                  </h4>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div id="img-container">
        <ng-template #noImg>
          <div style="aspect-ratio: 10/4;" class="w-100 py-5 text-center">
            Cargando imágenes para este inmueble...
          </div>
        </ng-template>
        <ng-container *ngIf="imagesof?.length > 0; else noImg">
          <div class="row mx-0" *ngIf="imagesof?.length == 1">
            <div class="col-12 p-0">
              <img [src]="imagesof[0]?.path" alt="" class="img-fluid w-100 aspect-ratio-01 aspect-ratio-md-104"
                style="object-fit: cover" />
            </div>
          </div>

          <div class="row mx-0" *ngIf="imagesof?.length == 2">
            <div class="col-md-6 p-0" *ngFor="let img of imagesof; let key = index">
              <img [src]="img.path" alt="" class="object-fit-cover img-fluid w-100"
                [class]="key == 1 ? 'border-left border-white' : ''"
                style="aspect-ratio: 6/5; object-fit: cover" />
            </div>
          </div>

          <div class="row mx-0" *ngIf="imagesof?.length == 3">
            <div class="col-md-4 p-0" *ngFor="let img of imagesof; let key = index">
              <img [src]="img.path" alt="" class="object-fit-cover img-fluid w-100"
              [class]="key == 1 ? 'border-left border-right border-white' : ''"
                style="aspect-ratio: 4/5; object-fit: cover" />
            </div>
          </div>

          <div class="row mx-0" *ngIf="imagesof?.length == 4">
            <div class="col-6 col-md-3 p-0" *ngFor="let img of imagesof; let key = index">
              <img [src]="img.path" alt="" class="object-fit-cover img-fluid w-100 aspect-ratio-01 aspect-ratio-35;"
              [class]="key != 0 ? 'border-left border-white' : ''"
                style="object-fit: cover" />
            </div>
          </div>

          <div class="row mx-0" *ngIf="imagesof?.length >= 5">
            <div class="col-md-6 p-0">
              <img [src]="imagesof[0]?.path" alt="" class="object-fit-cover img-fluid w-100"
                style="aspect-ratio: 7/6; object-fit: cover" />
            </div>
            <div class="col-md-6 p-0">
              <div class="row mx-0">
                <ng-container *ngFor="let img of imagesof; let key = index">
                  <div class="col-6 p-0" *ngIf="key != 0 && key < 5">
                    <img [src]="img.path" alt="" class="object-fit-cover img-fluid w-100"
                      style="aspect-ratio: 7/6; object-fit: cover" />
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-12 px-0 d-none d-xl-block" *ngIf="imagesof?.length >= 9">
              <div class="row mx-0">
                <ng-container *ngFor="let img of imagesof; let key = index">
                  <div class="col p-0" *ngIf="key > 4 && key < 10">
                    <img [src]="img.path" alt="" class="object-fit-cover img-fluid w-100"
                      style="aspect-ratio: 7/6; object-fit: cover" />
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div style="margin-top: -3em">
        <div class="bg-gray1 col-md-11 col-lg-8 mx-auto text-center py-3 rounded-3 mb-3">
          <div class="row">
            <div class="col-6 col-md-3 mb-3 mb-md-0">
              <p class="mb-0">Oferta</p>
              <h4 class="mb-0 font-weight-bold text-capitalize">
                En {{ property.type_offer }}
              </h4>
            </div>
            <div class="col-6 col-md-3 mb-3 mb-md-0">
              <p class="mb-0">Antigüedad</p>
              <h4 class="mb-0 font-weight-bold text-capitalize">
                {{ property.antiquity || "No registra" }}
              </h4>
            </div>
            <div class="col-6 col-md-3">
              <p class="mb-0">Área</p>
              <h4 class="mb-0 font-weight-bold">
                {{ totalArea ? (totalArea | number) : "No registra" }}
                <span *ngIf="totalArea">m<sup>2</sup></span>
              </h4>
            </div>
            <div class="col-6 col-md-3">
              <p class="mb-0">Estrato</p>
              <h4 class="mb-0 font-weight-bold">
                {{ property.estrato || "No registra" }}
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div class="px-3 px-md-5">
        <div class="row" *ngIf="property.description">
          <div class="col-1 text-right">
            <img src="./assets/img/pdf/descripcion.svg" alt="" width="30px" />
          </div>
          <div class="col-11">
            <h5 class="font-weight-bold">Descripción del Inmueble</h5>
            <p class="">
              {{ property.description }}
            </p>
          </div>
        </div>

        <div class="row" *ngIf="countOp('interiors')">
          <div class="col-1 text-right">
            <img src="./assets/img/pdf/interiores.svg" alt="" width="30px" />
          </div>
          <div class="col-11">
            <h5 class="font-weight-bold">Características Interiores</h5>
            <div class="row">
              <ng-container *ngFor="let op of property.options">
                <ng-container *ngIf="op.type_option == 'interiors'">
                  <ng-container *ngFor="let characteristic of op.options">
                    <ng-container *ngIf="
                          characteristic.config.value == true ||
                          characteristic.config.items ||
                          (characteristic.config.value != false &&
                            characteristic.config.value != undefined &&
                            characteristic.config.value != null &&
                            characteristic.config.value.trim() != '')
                        ">
                      <div class="col-md-6" *ngIf="
                            characteristic.config.type == 'counter' ||
                            characteristic.config.type == 'simple'
                          ">
                        <ul>
                          <li>
                            <p class="lh-1">
                              {{ characteristic.name }}:
                              <b *ngIf="characteristic.config.type == 'simple'">1</b>
                              <b *ngIf="characteristic.config.type != 'simple'">{{
                                characteristic.config?.value
                                }}</b>
                            </p>
                          </li>
                        </ul>
                      </div>

                      <div class="col-md-6" *ngIf="characteristic.config.type == 'multiple-list'">
                        <ul>
                          <li>
                            <p class="lh-1">
                              {{ characteristic.name }}:
                              <span *ngFor="
                                let item of characteristic.config.items;
                                index as i
                              " class="font-weight-bold">
                                {{ item.name
                                }}{{
                                i < characteristic.config.items.length - 1 ? ", " : "" }} </span>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>

          </div>
        </div>

        <div class="row" *ngIf="countOp('exterior')">
          <div class="col-1 text-right">
            <img src="./assets/img/pdf/exteriores.svg" alt="" width="30px" />
          </div>
          <div class="col-11">
            <h5 class="font-weight-bold">Características Exteriores</h5>
            <div class="row">
              <ng-container *ngFor="let op of property.options">
                <ng-container *ngIf="op.type_option == 'exterior'">
                  <ng-container *ngFor="let characteristic of op.options">
                    <ng-container *ngIf="
                          characteristic.config.value == true ||
                          characteristic.config.items ||
                          (characteristic.config.value != false &&
                            characteristic.config.value != undefined &&
                            characteristic.config.value != null &&
                            characteristic.config.value.trim() != '')
                        ">
                      <div class="col-md-6" *ngIf="
                            characteristic.config.type == 'counter' ||
                            characteristic.config.type == 'simple'
                          ">
                        <ul>
                          <li>
                            <p class="lh-1">
                              {{ characteristic.name }}:
                              <b *ngIf="characteristic.config.type == 'simple'">1</b>
                              <b *ngIf="characteristic.config.type != 'simple'">{{
                                characteristic.config?.value
                                }}</b>
                            </p>
                          </li>
                        </ul>
                      </div>

                      <div class="col-md-6" *ngIf="characteristic.config.type == 'multiple-list'">
                        <ul>
                          <li>
                            <p class="lh-1">
                              {{ characteristic.name }}:
                              <span *ngFor="
                                let item of characteristic.config.items;
                                index as i
                              " class="font-weight-bold">
                                {{ item.name
                                }}{{
                                i < characteristic.config.items.length - 1 ? ", " : "" }} </span>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="countOp('common_zones')">
          <div class="col-1 text-right">
            <img src="./assets/img/pdf/zonas.svg" alt="" width="30px" />
          </div>
          <div class="col-11">
            <h5 class="font-weight-bold">Zonas Comunes</h5>
            <div class="row">
              <ng-container *ngFor="let op of property.options">
                <ng-container *ngIf="op.type_option == 'common_zones'">
                  <ng-container *ngFor="let characteristic of op.options">
                    <ng-container *ngIf="
                          characteristic.config.value == true ||
                          characteristic.config.items ||
                          (characteristic.config.value != false &&
                            characteristic.config.value != undefined &&
                            characteristic.config.value != null &&
                            characteristic.config.value.trim() != '')
                        ">
                      <div class="col-md-4" *ngIf="
                            characteristic.config.type == 'counter' ||
                            characteristic.config.type == 'simple'
                          ">
                        <ul>
                          <li>
                            <p class="lh-1">
                              {{ characteristic.name }}:
                              <b *ngIf="characteristic.config.type == 'simple'">1</b>
                              <b *ngIf="characteristic.config.type != 'simple'">{{
                                characteristic.config?.value
                                }}</b>
                            </p>
                          </li>
                        </ul>
                      </div>

                      <div class="col-md-4" *ngIf="characteristic.config.type == 'multiple-list'">
                        <ul>
                          <li>
                            <p class="lh-1">
                              {{ characteristic.name }}:
                              <span *ngFor="
                                let item of characteristic.config.items;
                                index as i
                              " class="font-weight-bold">
                                {{ item.name
                                }}{{
                                i < characteristic.config.items.length - 1 ? ", " : "" }} </span>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="property.nearby_sites?.length > 0">
          <div class="col-1 text-right">
            <img src="./assets/img/pdf/detalles.svg" alt="" width="30px" />
          </div>
          <div class="col-11">
            <h5 class="font-weight-bold">Sitios Cercanos</h5>
            <div class="row">
              <ng-container *ngFor="let site of property.nearby_sites">

                <div class="col-md-4">
                  <ul>
                    <li>
                      <p class="lh-1">
                        {{ site.name }}
                      </p>
                    </li>
                  </ul>
                </div>

              </ng-container>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-1 text-right">
            <img src="./assets/img/pdf/ubicacion.svg" alt="" width="30px" />
          </div>
          <div class="col-11">
            <h5 class="font-weight-bold">Ubicación</h5>
            <ul class="mb-1">
              <li *ngIf="property.city"> {{ property.city }}</li>
              <li *ngIf="property.zone">{{ property.zone }}</li>
              <li *ngIf="property.address">{{ property.address }}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="px-3 px-md-5 py-2">
        <img [src]="mapImg" style="object-fit: cover; height: auto;" class="img-fluid pointer w-100 rounded-3" />
      </div>

      <div class="px-5 py-3 text-center">
        <a [href]="'./propiedad/'+property.slug">
          <h5 class="text-blue mb-0">
            <span class="border-bottom">
              Ver más acerca de la publicación
            </span>
          </h5>
        </a>
      </div>

      <div class="mt-2 px-3 px-md-5 py-3 bg-red text-light">
        <div class="row mb-3 mb-md-0">
          <div class="col-1 text-right">
            <img src="./assets/img/pdf/contacto.svg" alt="" width="25px" />
          </div>
          <div class="col-11 col-md-7 col-lg-5">
            <h5 class="font-weight-bold mb-2 mb-md-0">Datos de Contacto</h5>
            <div class="row mb-2 mb-md-0">
              <div class="col-md">
                <b> Nombre: </b>
              </div>
              <div class="col-md">{{ property.contacts[0]?.content?.name || 'No registra' }}</div>
            </div>

            <div class="row mb-2 mb-md-0">
              <div class="col-md-6">
                <b> Correo:</b>
              </div>
              <div class="col-md-6">{{ property.contacts[0]?.content?.email || 'No registra' }}</div>
            </div>

            <ng-container *ngFor="let phone of property.contacts[0]?.content?.phones; let i = index">
              <div class="row mb-2 mb-md-0" *ngIf="phone.phone">
                <div class="col-md-6">
                  <b> Número de Celular{{ i > 0 ? ' '+i+1 : ''}}: </b>
                </div>
                <div class="col-md-6">{{ phone.phone }} <i *ngIf="phone.accept.whatsapp"
                    class="fab fa-whatsapp ml-1"></i>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="text-center text-md-right">
          <p class="m-0">
            Powered by
            <img src="./assets/img/pdf/logo-vivienda.svg" alt="" class="align-middle ml-1" />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>