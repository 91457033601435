import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../services/../../environments/environment'
import { AuthService } from '../auth/auth.service';
import * as CryptoJS from 'crypto-js';
import Swal from 'sweetalert2/dist/sweetalert2';
import { TokenService } from '../auth/token.service';
import { Router } from '@angular/router';
import { ApiConnectionService } from '../api-connection.service';
import * as Cookies from 'js-cookie';
declare const gtag: any;

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private tokenService: TokenService,
    private apiConection: ApiConnectionService,
    protected router: Router
  ) { }

  parseErrorResponse(error: any, browser = false): any {
    let errors = error.error;
    let messages = '';
    let icon = 'warning';
    switch (error.status) {
      case 400:
        messages = '<ul style="list-style: none">';
        for (let i in errors.data) {
          for (let j in errors.data[i]) {
            messages += '<li>' + errors.data[i][j] + "</li>";
          }
        }
        messages += '</ul>';
        break;
      case 401:
        this.authService.logOut();
        break;
      case 500:
        if (browser) {
          Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            title: 'Algo salió mal...',
            text: 'Por favor inténtalo de nuevo',
            icon: 'error'
          }).then(() => {
            window.location.reload();
          });
        }
      default:
        try {
          if (Array.isArray(errors.data)) {
            messages = errors.data[0];
          } else {
            messages = errors.data;
          }
        } catch (e) {
          messages = 'En este momento no se puede atender la solicitud, inténtalo más tarde';
        }
        break;
    }
    return {
      'message': messages,
      'icon': icon
    }
  }

  parseEncryptData(data: any): any {
    let key = environment.encryption_key;
    const Base64 = atob(data);
    const encrypted = JSON.parse(Base64);
    const iv = CryptoJS.enc.Base64.parse(encrypted.iv);
    const value = encrypted.value;
    key = CryptoJS.enc.Base64.parse(key);

    let decrypted = CryptoJS.AES.decrypt(value, key, {
      iv: iv,
      fornat: CryptoJS.format.JSON
    });

    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    decrypted = decrypted.substr(decrypted.indexOf('"') + 1);
    decrypted = decrypted.substr(0, decrypted.length - 2);
    return JSON.parse(decrypted);
  }

  getUserLocation(): Observable<any> {
    return this.http.get('https://ip-api.com/json/');
  }

  getAbout(): Observable<any> {
    return this.apiConection.getData('about', {});
  }

  clickEvent(event = '', category = '', label = '', value = 'true'): void {
    gtag('event', event, {
      'event_category': category,
      'event_label': label,
      'value': value
    });
  }

  launchWaitAlert(title = 'Espera un momento...') {
    Swal.fire({
      title: title,
      allowOutsideClick: false,
      allowEscapeKey: false,
      onBeforeOpen: () => Swal.showLoading()
    });
  }

  clearString(str) {
    var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç,.;:/", 
      to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
      mapping = {};
   
    for (var i = 0, j = from.length; i < j; i++ )
      mapping[ from.charAt( i ) ] = to.charAt( i );
        
    var ret = [];
    for( var i = 0; i < str.length; i++ ) {
      var c = str.charAt( i );
      if( mapping.hasOwnProperty( str.charAt( i ) ) )
        ret.push( mapping[ c ] );
      else
        ret.push( c );
    }
    return ret.join('');
  }

  openShop(redirectTo = '/') {
    const url = 'https://blog.vivienda.com/wp-login-personalize.php?auth=' + Cookies.get('vivienda_com_auth_token') + '&redirectTo=' + redirectTo;
    window.open(url, '_blank');
  }
}
