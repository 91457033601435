<!-- Web banner -->
<div class="form-contact-general d-none d-md-block" id="collapseContacto">
  <div class="d-flex general">
    <div class="button">
      <div class="float-info transition-left" id="cont-btn-float" onclick="$('#collapseContacto').toggleClass('transition');">
        <span>
          <img src="//cdn.vivienda.com/images/icons/arrow-down.png" alt="arrow" class="hidden" />
          <img src="//cdn.vivienda.com/images/icons/close.png" alt="arrow" class="activo" />
        </span>
        <a class="text-bold pointer">Novedades y noticias</a>
      </div>
    </div>
    <div class="content p-0">
      <div class="formulario-contact">
        <form class="form-horizontal contactForm" autocomplete="off">
          <div class="col-sm-12 mt-4 text-center">
            <h2 class="mb-1">Ayudanos a<br>mejorar.
              <br>
              Déjanos tus comentarios</h2>
          </div>
          <div class="col-sm-12 mt-4 text-center">
            <!-- <a href="./auth/register" class="py-1 px-4 btn-yellow text-bold mb-0 mt-1">¡Aquí!</a> -->
            <a href="./sugerencias" class="pt-1 pb-2 px-4 btn-yellow font-weight-bold mb-0 mt-0" style="background-color: #ff9C00;">¡Aquí!</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Mobile banner -->
<div class="container-fluid position-relative carousel-web">
  <div class="carousel-inner owl-carousel home-carousel">
    <div class="item active">
      <img src="https://cdn.vivienda.com/images/home/banner-1.jpg" class="w-100 d-none d-lg-block" />
      <img src="https://cdn.vivienda.com/images/home/banner-1.jpg" class="d-block d-lg-none" />
    </div>
    <div class="item">
      <img src="https://cdn.vivienda.com/images/home/banner-2.jpg" class="w-100 d-none d-lg-block" />
      <img src="https://cdn.vivienda.com/images/home/banner-2.jpg" class="d-block d-lg-none" />
    </div>
    <div class="item">
      <img src="https://cdn.vivienda.com/images/home/banner-3.jpg" class="w-100 d-none d-lg-block" />
      <img src="https://cdn.vivienda.com/images/home/banner-3.jpg" class="d-block d-lg-none" />
    </div>
  </div>
  <div id="searchOption" class="position-absolute d-flex flex-column justify-content-center d-md-none text-banner-home text-center mt-0 pt-0 pb-5 px-5">
    <h4 class="text-white text-semibold mt-5">
      Te ayudamos a encontrar el {{ navbarComponent.type_offer == "servicios" ? "servicio" : "inmueble" }} que necesitas
    </h4>
    <div class="dropdown show">
      <button class="btn dropdown-toggle no-close btn_home" type="button" id="dropdownArriendo" data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false" >
        {{ navbarComponent.type_offer.charAt(0).toUpperCase() + navbarComponent.type_offer.slice(1) }}
        <i class="fas fa-chevron-down float-right mt-1"></i>
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownArriendo">
        <a class="dropdown-item no-close" (click)="navbarComponent.type_offer = 'arrendar';navbarComponent.contentText = 'Selecciona...'" >
          Arrendar
          <i *ngIf="navbarComponent.type_offer != 'arrendar'" class="far fa-square float-right mt-1 no-close"></i>
          <i *ngIf="navbarComponent.type_offer == 'arrendar'" class="fas fa-check-square float-right mt-1 no-close" style="color: #f7bf50"></i>
        </a>
        <a class="dropdown-item no-close" (click)="navbarComponent.type_offer = 'venta'; navbarComponent.contentText = 'Selecciona...'">
          Venta
          <i *ngIf="navbarComponent.type_offer != 'venta'" class="far fa-square float-right mt-1 no-close"></i>
          <i *ngIf="navbarComponent.type_offer == 'venta'" class="fas fa-check-square float-right mt-1 no-close" style="color: #f7bf50"></i>
        </a>
        <a class="dropdown-item no-close" (click)="navbarComponent.type_offer = 'servicios';navbarComponent.contentText = 'Selecciona...'">
          Servicios
          <i *ngIf="navbarComponent.type_offer != 'servicios'" class="far fa-square float-right mt-1 no-close"></i>
          <i *ngIf="navbarComponent.type_offer == 'servicios'" class="fas fa-check-square float-right mt-1 no-close" style="color: #f7bf50"></i>
        </a>
      </div>
    </div>
    <div class="dropdown no-close">
      <button class="btn dropdown-toggle no-close btn_home" type="button" id="dropdownTipo" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{ navbarComponent.contentText.length > 20 ? navbarComponent.contentText.substr(0, 20) + "..." : navbarComponent.contentText }} <i class="fas fa-chevron-down float-right mt-1"></i>
      </button>
      <div class="dropdown-menu no-close btn_home" aria-labelledby="dropdownTipo" *ngIf="navbarComponent.type_offer != 'servicios'">
        <a class="dropdown-item no-close" (click)="navbarComponent.type_properties[i].checked = !navbarComponent.type_properties[i].checked;navbarComponent.changeTextContentText(tp)" *ngFor="let tp of navbarComponent.type_properties; index as i">
          {{ tp.type_property }}
          <i *ngIf="!tp.checked" class="far fa-square float-right mt-1 no-close"></i>
          <i *ngIf="tp.checked" class="fas fa-check-square float-right mt-1 no-close" style="color: #f7bf50"></i>
        </a>
      </div>
      <div class="dropdown-menu no-close" aria-labelledby="dropdownTipo" *ngIf="navbarComponent.type_offer == 'servicios'">
        <a class="dropdown-item no-close" (click)="navbarComponent.services[i].checked = !navbarComponent.services[i].checked;navbarComponent.changeTextContentTextService(s, true)" *ngFor="let s of navbarComponent.services; index as i">
          {{ s.name }}
          <i *ngIf="!s.checked" class="far fa-square float-right mt-1 no-close"></i>
          <i *ngIf="s.checked" class="fas fa-check-square float-right mt-1 no-close" style="color: #f7bf50"></i>
        </a>
      </div>
    </div>
    <input type="text" id="cityHome" name="cityHome" placeholder="Ciudad, zona o sector" class="input-banner" autocomplete="off" />
    <button class="btn-banner bg-yellow text-white px-3 text-bold py-2 mt-4 mx-auto" style="width: 120px" (click)="navbarComponent.search()">Buscar</button>
  </div>
  <div class="position-absolute text-banner txt d-none d-md-block">
    <h1 class="text-white m-0 text-bold">Te ayudamos a encontrar tu espacio ideal</h1>
    <h4 class="texto-description mb-3">Conoce el catálogo de propiedades que tenemos para tí</h4>
    <p class="my-0 more-link text-center">
      <a href="./busqueda" class="d-block text-bold btn-pink text-white text-center">Ver más</a>
    </p>
  </div>
</div>

<!-- Proyectos destacados -->
<div class="container-fluid ProyectosDestacados bg-grey-scale-2">
  <h3 class="Title mt-1 mb-0">Últimos inmuebles</h3>
</div>
<div class="container-fluid ProyectosDestacados bg-grey-scale-2 pt-0 pb-2">
  <div class="row mx-4 destacados">
    <div class="col-12 col-sm-6 col-md-4 propertie-card px-1 mt-2" *ngFor="let property of properties; index as i">
      <div class="card margin-top: 5px; card-inmu m-auto">
        <div class="figcaption-proyectos">
          <div class="card-img-top img-inmueble" style="background-image: url('{{ property.first_image ? property.first_image.original : 'https://cdn.vivienda.com/images/properties/no-disponible.jpg' }}')" (mouseover)="focused(i)">
            <a href="./propiedad/{{ property.slug }}" class="all-content"></a>
            <div class="buttons d-none m-btn-top"></div>
          </div>
        </div>
        <div class="card-body" style="padding: 1rem; padding-top: 10px">
          <div class="row">
            <div class="col-12">
              <h1 class="card-title title-inmueble mt-0 mb-0">
                <a class="text-color-black" href="./propiedad/{{ property.slug }}">{{ property.zone }}{{ property.city ? ", " + property.city : "" }}</a>
              </h1>
              <h2 class="precio text-color-black d-flex align-items-center">
                <img src="//cdn.vivienda.com/images/icons/gps.png" class="mr-1" />
                <span>
                  <span class="text-bold">
                    ${{ property.amount }} {{ property.currency.iso }}
                  </span>
                  <span class="text-regular">
                    {{ property.type_property.type_property }}
                    {{ property.type_offer.charAt(0).toUpperCase() + property.type_offer.slice(1) }}
                  </span>
                </span>
              </h2>
              <a href="./propiedad/{{ property.slug }}" class="d-block btn-yellow py-2 showMore">
                Detalle <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 d-flex justify-content-center">
      <h4 class="w-100 text-underline text-center">
        <a href="./busqueda" style="color: #0096a3"> Ver todos </a>
      </h4>
    </div>
  </div>
</div>


<!-- Buscar inmuebles-->
<div class="container-fluid bg-grey buscador py-2 px-0">

  <div class="row mx-0 pb-0 justify-content-center pt-0 mb-3">
    <div class="col-12">
      <h3 class="Title">Busca tus Inmuebles</h3>
      <p class="text-center">
        Escribe el lugar cercano en donde quieres los resultados de tu inmueble
      </p>
    </div>
    <div class="col-12 col-md-10 p-0 flex-row align-items-center justify-content-start buscas">
      <form autocomplete="off" class="d-flex row align-items-center justify-content-center px-0 mx-0 col-12" id="buscador">
        <div class="col-md-12 p-0">
          <div class="form-group mb-2 d-flex">
            <input type="text" name="reference" id="reference" placeholder="Punto de referencia / Dirección" class="px-4 mt-0" autocorrect="off" autocapitalize="off" spellcheck="off" #search *ngIf="browser" />
            <button type="button" class="btn-pink m-0 pl-4 pr-4">Buscar</button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-12">
      <div class="row justify-content-center align-items-center">
        <div class="col-6 col-md-2 text-center">
          <p class="mb-0">
            <small>
            <i class="fas fa-circle" style="color: #E20140"></i>
            Arriendo
            </small>
          </p>
        </div>
    
        <div class="col-6 col-md-2 text-center">
          <p class="mb-0">
            <small>
            <i class="fas fa-circle" style="color: #4694A1"></i>
            Venta
            </small>
          </p>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-11 px-5 px-sm-1 pt-2">
      <div class="container">
        <div class="spinner-border d-none" style="top: 45%;left: 45%;width: 120px;height: 120px;position: absolute;z-index: 50;" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <app-map [lat]="lat" [lng]="lng" [location]="location" [zoom]="zoom" (propertiesChange)="setProperties($event)"></app-map>
    </div>
  </div>

</div>

<!-- Servicios Adicionales -->
<div class="container-fluid pb-3 pt-0 px-0">
  <div class="container-fluid px-0 bg-grey-scale-1 py-1 my-0" style="background-color: #bfbfbf00">
    <h3 class="Title my-0">Servicios Adicionales</h3>
  </div>
  <div class="container mt-1 px-0">
    <div class="row m-0 justify-content-center">
      <div class="col-md-9">
        <carousel style="height: 200px" class="serviciosRotador d-none d-md-block" *ngIf="browser">
          <div class="carousel-cell" *ngFor="let serv of services">
            <a [href]="'./servicios/' + serv.name.toLowerCase()">
              <figcaption class="yellow">
                <img src="{{ serv.image }}" />
                <h4>{{ serv.name }}</h4>
              </figcaption>
            </a>
          </div>
        </carousel>
        <div class="serviciosRotador d-block d-md-none">
          <div class="carousel-cell" *ngFor="let serv of services">
            <figcaption class="yellow col-12">
              <img src="{{ serv.image }}" class="img-fluid" />
              <h4>{{ serv.name }}</h4>
            </figcaption>
          </div>
        </div>
      </div>
      <div class="permanente col-md-auto p-0 d-none d-md-block">
        <div class="item">
          <a href="./servicios">
            <figcaption class="yellow">
              <img src="//cdn.vivienda.com/images/services/mas-servicios.png" class="img-fluid" style="max-width: 198px" />
              <h4>Ver más</h4>
            </figcaption>
          </a>
        </div>
      </div>
      <div class="permanente col-9 p-0 d-block d-md-none">
        <div class="item">
          <a href="./servicios">
            <figcaption class="yellow">
              <img src="//cdn.vivienda.com/images/services/mas-servicios.png" alt="" class="img-fluid" style="max-width: 198px" />
              <h4>Ver más</h4>
            </figcaption>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Planes -->
<div class="container-fluid bg-yellow Planes">
  <div class="container">
    <div class="row text-center justify-content-center">
      <div class="col-md-6 bg-white p-0">
        <h3>
          ¿Quieres que tu inmueble
          <span> sea el más destacado? </span>
        </h3>
        <h6>
          CONOCE NUESTROS PLANES
          <br />
          <span> Y CONSIGUE MÁS CLIENTES </span>
        </h6>
        <a href="https://learning.vivienda.com/tienda/" target="_blank" class="btn-yellow pl-5 pr-5 pt-2 pb-2 mt-2 d-inline-block">
          Ver planes
        </a>
      </div>
      <div class="col-md-4 p-0 d-none d-lg-flex">
        <img src="//cdn.vivienda.com/pauta/home-pauta-destacado.jpg"/>
      </div>
    </div>
  </div>
</div>



<!-- Formualrio contacto -->
<div class="container-fluid contacto bg-white py-3">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h3 class="Title mb-0">Contacto</h3>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <form #contactForm="ngForm" class="row m-0" (ngSubmit)="contactComponent.contact(contactForm)" autocomplete="off">
            <div class="form-group mb-0 col-md-6">
              <input type="text" name="name" id="name" class="px-4 w-100 input-border" placeholder="Nombre(s)" ngModel required />
              <br />
              <span *ngIf="contactForm.submitted && contactForm.controls['name'].errors" class="text-danger animated fadeIn">El nombre(s) es obligatorio</span>
            </div>
            <div class="form-group mb-0 col-md-6">
              <input type="text" name="surname" id="surname" class="px-4 w-100 input-border" placeholder="Apellido(s)" ngModel required />
              <span *ngIf="contactForm.submitted && contactForm.controls['surname'].errors" class="text-danger animated fadeIn">El apellido(s) es obligatorio</span>
            </div>
            <div class="form-group mb-0 col-md-6">
              <input type="email" name="email" id="email" class="px-4 w-100 input-border" placeholder="Correo electrónico" ngModel required />
              <span *ngIf="contactForm.submitted && contactForm.controls['email'].errors" class="text-danger animated fadeIn" >El correo electrónico es obligatorio</span>
            </div>
            <div class="form-group mb-0 col-md-6 mt-3">
              <input type="text" name="cellphone" id="cellphone" class="px-4 w-100 input-border" placeholder="Teléfono de contacto" inputmode="numeric" />
              <input type="hidden" name="phone" id="phone" ngModel required />
              <span *ngIf="contactForm.submitted && contactForm.controls['phone'].errors" class="text-danger animated fadeIn">El teléfono es obligatorio</span>
            </div>
            <div class="form-group mb-0 mt-3 col-12">
              <textarea name="message" id="message" class="px-4 input-border" rows="4" min="20" placeholder="Mensaje" ngModel required></textarea>
              <span *ngIf="contactForm.submitted && contactForm.controls['message'].errors" class="text-danger animated fadeIn">El mensaje es obligatorio y mínimo de 20 caracteres</span>
            </div>
            <div class="form-group text-center mt-1 mb-0">
              <input type="checkbox" name="terms" id="terms" required />
              <label for="terms" style="position: absolute; left: 40px"></label>
              <h6 class="text-gray text-thin text-left textos terminos mx-lg-auto ml-md-auto mr-md-0 ml-auto w-75 mb-0" >
                Acepto los términos y condiciones, política de privacidad y el
                tratamiento de mis datos personales
              </h6>
            </div>
            <div class="form-group mb-0 d-flex justify-content-center col-12">
              <div class="g-recaptcha recaptcha-1 my-0">
                <re-captcha #captchaRef="reCaptcha" (resolved)="contactComponent.resolved($event)" siteKey="6LdkKNwUAAAAABGyZqGidUfLfPWk73AuG0u_ylMa" class="g-recaptcha" required></re-captcha>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <button type="submit" class="btn-magenta d-block mr-0 mr-md-auto text-white text-bold px-3 py-2 mb-2 mb-md-2 mt-1 w-100 pt-3 pb-3" style="background-color: #e20140">
                <h5 class="mb-0">Enviar</h5>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-gray py-3">
  <div class="container">
    <div class="col-12">
      <h3 class="Title text-blue mb-0">Aliados</h3>
    </div>
    <div class="row mt-2 pb-2 text-center justify-content-center">
      <carousel
        style="height: 195px; width: 75%"
        class="RotadorAliados d-none d-lg-block"
        *ngIf="browser"
      >
        <div class="carousel-cell" *ngFor="let profile of profiles">
          <div class="client1">
            <div class="img-empresa d-flex align-items-center">
              <a href="./perfil/{{ profile.slug }}">
                <img [src]="profile.image ? profile.image : '//cdn.vivienda.com/images/icons/aliado.png'" class="img-fluid" onerror="this.src = '//cdn.vivienda.com/images/icons/aliado.png'" />
              </a>
            </div>
            <h4 class="text-color-black text-bold p-sm-90 mb-0 mt-3">
              {{ profile.name }}
            </h4>
            <h6 class="text-color-black p-sm-90 mb-0 mt-1">
              {{ profile.type }}
            </h6>
          </div>
        </div>
      </carousel>
      <carousel
        style="height: 275px; width: 90%"
        class="RotadorAliados d-none d-md-block d-lg-none"
        *ngIf="browser"
      >
        <div class="carousel-cell" *ngFor="let profile of profiles">
          <div class="client1">
            <div class="img-empresa d-flex align-items-center">
              <a href="./perfil/{{ profile.slug }}">
                <img [src]="profile.image ? profile.image : '//cdn.vivienda.com/images/icons/aliado.png'" class="img-fluid" onerror="this.src = '//cdn.vivienda.com/images/icons/aliado.png'" />
              </a>
            </div>
            <h4 class="text-color-black text-bold p-sm-90 mb-0 mt-3">
              {{ profile.name }}
            </h4>
            <h6 class="text-color-black p-sm-90 mb-0 mt-1">
              {{ profile.type }}
            </h6>
          </div>
        </div>
      </carousel>
      <div class="d-flex d-md-none container row">
        <div class="client1 col-6" style="background-color: transparent" *ngFor="let profile of profiles">
          <div class="img-empresa d-flex align-items-center">
            <a style="height: 100%" href="./perfil/{{ profile.slug }}">
              <img [src]="profile.image ? profile.image : '//cdn.vivienda.com/images/icons/aliado.png'" class="img-fluid" onerror="this.src = '//cdn.vivienda.com/images/icons/aliado.png'" />
            </a>
          </div>
          <h4 class="text-color-black text-bold p-sm-90 mb-0 mt-3">
            {{ profile.name }}
          </h4>
          <h6 class="text-color-black p-sm-90 mb-0 mt-1">{{ profile.type }}</h6>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid pt-3 pb-2">
  <div class="container">
    <h5 class="Title text-center">
      INMUEBLES EN VENTA / ARRIENDO
    </h5>

      <ul class="list-unstyled tres-cols small">
        <li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/apartamento/'>Apartamentos</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/casa/'>Casas</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/bodega/'>Bodegas</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/lote/'>Lotes</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/finca/'>Fincas</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/consultorio/'>Consultorios</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/oficina/'>Oficinas</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/bodega/'>Bodegas</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/apartamento/venta/'>Apartamentos en venta</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/casa/venta/'>Casas en venta</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/bodega/venta/'>Bodegas en venta</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/lote/venta/'>Lotes en venta</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/finca/venta/'>Fincas en venta</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/consultorio/venta/'>Consultorios en venta</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/oficina/venta/'>Oficinas en venta</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/bodega/venta/'>Bodegas en venta</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/apartamento/arriendo/'>Apartamentos en arriendo</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/casa/arriendo/'>Casas en arriendo</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/bodega/arriendo/'>Bodegas en arriendo</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/lote/arriendo/'>Lotes en arriendo</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/finca/arriendo/'>Fincas en arriendo</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/consultorio/arriendo/'>Consultorios en arriendo</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/oficina/arriendo/'>Oficinas en arriendo</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/bodega/arriendo/'>Bodegas en arriendo</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/apartamento/venta/bogota/'>Apartamentos en venta en Bogotá</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/casa/venta/bogota/'>Casas en venta en Bogotá</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/consultorio/venta/bogota/'>Consultorios en venta en Bogotá</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/apartamento/arriendo/bogota/'>Apartamentos en arriendo en Bogotá</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/casa/arriendo/bogota/'>Casas en arriendo en Bogotá</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/oficina/arriendo/bogota/'>Oficinas en arriendo en Bogotá</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/apartamento/venta/medellin/'>Apartamentos en venta en Medellin</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/casa/venta/medellin/'>Casas en venta en Medellin</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/apartamento/arriendo/medellin/'>Apartamentos en arriendo en Medellin</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/casa/arriendo/medellin/'>Casas en arriendo en Medellin</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/oficina/arriendo/medellin/'>Oficinas en arriendo en Medellin</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/apartamento/venta/cali/'>Apartamentos en venta en Cali</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/casa/venta/cali/'>Casas en venta en Cali</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/oficina/venta/cali/'>Oficinas en venta en Cali</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/apartamento/arriendo/cali/'>Apartamentos en arriendo en Cali</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/casa/arriendo/cali/'>Casas en arriendo en Cali</a></li>
<li class="nav-item"><a class="nav-link py-0 mt-1 text-secondary" href='/category/oficina/arriendo/cali/'>Oficinas en arriendo en Cali</a></li>
        
      </ul>


  </div>
</div>

<!-- Modal Sugerencias -->
<div class="modal fade" id="suggestModalLong" tabindex="-1" role="dialog" aria-labelledby="suggestModalLongTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header py-2 px-3">
        <h6 class="modal-title" id="suggestModalLongTitle"><i class="fas fa-envelope-open-text"></i> Buzón de sugerencias</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
          <h5 class="mb-3">Ayudanos a mejorar.</h5>
          <h5 class="mb-3">Si tienes comentarios o sugerencias, puedes dejarlos</h5>
        <div class="pt-1">
          <a href="./sugerencias" class="btn btn-yellow btn-rounded font-weight-bold px-3" style="background-color: #ff9C00;">¡Aquí!</a>
        </div>
      </div>
    </div>
  </div>
</div>