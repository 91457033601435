$(document).scroll(function () {
    var y = $(this).scrollTop();
    if (y > 65) {
        $("#scroll-nav").removeClass('d-none');
    } else {
        $("#scroll-nav").addClass('d-none');
    }
});
$(document).ready(function () {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });

    setTimeout(() => {
        var element = document.querySelector("#cellphone");
        if (element) {
            var iti = window.intlTelInput(element, {
                initialCountry: 'auto',
                geoIpLookup: function (success, failure) {
                    $.get("https://ipinfo.io", function () { }, "jsonp")
                        .always(function (resp) {
                            var countryCode = (resp && resp.country) ? resp.country : "co";
                            success(countryCode);
                        });
                },
                separateDialCode: false,
                nationalMode: false,
                utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.min.js'
            });

            const handleChange = () => {
                $("#cellphone").val(iti.getNumber());
            }

            element.addEventListener('change', handleChange);
            element.addEventListener('keyup', handleChange);

            if ('./auth/register' == window.location.pathname) {
                $("div.iti__flag-container").addClass('register');
            }
        }
    }, 2000);
});

function filterServices() {
    const val = $("#filter-cat-services").val().toUpperCase();
    if (val && val.trim() !== '') {
        $("#tip-submenu-services .content > div.row > p").each(function () {
            var value = $(this).text();
            const item = this;
            if (value.toUpperCase().indexOf(val) > -1) {
                showItemsServiceFilter(item, 'flex');
            } else {
                if ($($(this).attr('data-target') + " > div > div.row")) {
                    const target = $($(this).attr('data-target') + " > div > div.row > p");
                    var found = false;
                    $(target).each(function () {
                        if ($(this).text().toUpperCase().indexOf(val) > -1) {
                            found = true;
                            showItemsServiceFilter(item, 'flex');
                        }
                    });
                    if (!found)
                        showItemsServiceFilter(item, 'none');
                } else
                    showItemsServiceFilter(item, 'none');
            }
        });
    } else {
        $("#tip-submenu-services .content > div.row").addClass('d-flex');
        $("#tip-submenu-services .content > div.row").removeClass('d-none');
    }
}

function showItemsServiceFilter(item, method = 'flex') {
    if (method == 'flex') {
        $(
            $(item).parent()[0]
        ).addClass('d-flex');

        $(
            $(item).parent()[0]
        ).removeClass('d-none');
    } else {
        $(
            $(item).parent()[0]
        ).addClass('d-none');

        $(
            $(item).parent()[0]
        ).removeClass('d-flex');
    }
}

//setInterval('mapdetail()', 2500);
function mapdetail() {
    a = document.getElementsByClassName("gm-style-iw-a");
    b = document.getElementById("inmuebles-btn");
    c = document.getElementById("inmuebles");
    if (a.length >= 1) {
        b.classList.add("collapse");
    } else if (b && c && c.classList.contains("collapse")) {
        b.classList.remove("collapse");
    }
}
function menuSm(n, element, img) {
    a = document.getElementsByClassName("vista");
    b = document.getElementsByClassName("select-sm");
    for (let i = 0; i < a.length; i++) {
        if (i == n) {
            if (a[i].classList.contains("active-sm")) {
                a[i].classList.remove("active-sm");
            }
            else {
                a[i].classList.add("active-sm");
            }
        }
        else {
            a[i].classList.remove("active-sm");
        }
    }
    for (let j = 0; j < b.length; j++) {
        if (j == 0) {
            b[j].children[0].src = "https://cdn.vivienda.com/images/icons/inm-fav.png";
        }
        if (j == 1) {
            b[j].children[0].src = "https://cdn.vivienda.com/images/icons/busquedas.png";
        }
        if (j == 2) {
            b[j].children[0].src = "https://cdn.vivienda.com/images/icons/notificaciones.png";
        }
        if (j == 3) {
            b[j].children[0].src = "https://cdn.vivienda.com/images/icons/messages.png";
        }
        if (element == b[j]) {
            if (b[j].classList.contains("active")) {
                b[j].classList.remove("active");
                b[j].children[0].src = img + ".png";
            } else {
                b[j].classList.add("active");
                b[j].children[0].src = img + "-enabled.png";
            }
        } else {
            b[j].classList.remove("active");
        }
    }
}
function menu(n, element, img) {
    a = document.getElementsByClassName("vista");
    b = document.getElementsByClassName("selectores");
    for (let i = 0; i < a.length; i++) {
        if (i == n) {
            a[i].classList.add("active");
        }
        else {
            a[i].classList.remove("active");
        }
    }
    for (let j = 0; j < b.length; j++) {
        if (j == 0) {
            b[j].children[0].src = "https://cdn.vivienda.com/images/icons/inm-fav.png";
        }
        if (j == 1) {
            b[j].children[0].src = "https://cdn.vivienda.com/images/icons/busquedas.png";
        }
        if (j == 2) {
            b[j].children[0].src = "https://cdn.vivienda.com/images/icons/notificaciones.png";
        }
        if (j == 3) {
            b[j].children[0].src = "https://cdn.vivienda.com/images/icons/messages.png";
        }
        if (element == b[j]) {
            b[j].classList.add("active");
            b[j].children[0].src = img + "-enabled.png";
        } else {
            d.src = "https://cdn.vivienda.com/images/icons/empresa-perfil.png"
        }
        if (a[i] == element) {
            a[i].classList.add("active");
            b[i].classList.add("active");
            c[i].classList.remove("disabled");
        } else {
            a[i].classList.remove("active");
            b[i].classList.remove("active");
            c[i].classList.add("disabled");
        }
    }
}
function tabsMobile(element) {
    a = document.getElementsByClassName("tab-select1");
    b = document.getElementsByClassName("tab");
    c = document.getElementsByClassName("select-img");
    d = document.getElementById("profile-identificator");
    for (let i = 0; i < a.length; i++) {
        if (a[3] == element || a[6] == element || a[10] == element || a[12] == element) {
            d.src = "https://cdn.vivienda.com/images/icons/persona-perfil.png"
        } else {
            d.src = "https://cdn.vivienda.com/images/icons/empresa-perfil.png"
        }
        if (a[i] == element) {
            a[i].classList.add("active");
            b[i].classList.add("active");
            c[i].classList.remove("disabled");
        } else {
            a[i].classList.remove("active");
            b[i].classList.remove("active");
            c[i].classList.add("disabled");
        }
    }
}
function tabs(element) {
    a = document.getElementsByClassName("tab-select");
    b = document.getElementsByClassName("tab");
    c = document.getElementsByClassName("select-img");
    d = document.getElementById("profile-identificator");
    for (let i = 0; i < a.length; i++) {
        if (a[3] == element || a[6] == element || a[10] == element || a[12] == element) {
            d.src = "https://cdn.vivienda.com/images/icons/persona-perfil.png"
        } else {
            d.src = "https://cdn.vivienda.com/images/icons/empresa-perfil.png"
        }
        if (a[i] == element) {
            a[i].classList.add("active");
            b[i].classList.add("active");
            c[i].classList.remove("disabled");
        } else {
            a[i].classList.remove("active");
            b[i].classList.remove("active");
            c[i].classList.add("disabled");
        }
    }
}
function tabsMobile(element) {
    a = document.getElementsByClassName("tab-select1");
    b = document.getElementsByClassName("tab");
    c = document.getElementsByClassName("select-img");
    d = document.getElementById("profile-identificator");
    for (let i = 0; i < a.length; i++) {
        if (a[3] == element || a[6] == element || a[10] == element || a[12] == element) {
            d.src = "https://cdn.vivienda.com/images/icons/persona-perfil.png"
        } else {
            d.src = "https://cdn.vivienda.com/images/icons/empresa-perfil.png"
        }
        if (a[i] == element) {
            a[i].classList.add("active");
            b[i].classList.add("active");
            c[i].classList.remove("disabled");
        } else {
            a[i].classList.remove("active");
            b[i].classList.remove("active");
            c[i].classList.add("disabled");
        }
    }
}
function countChar300(val) {
    var len = val.value.length;
    if (len >= 1024) {
        val.value = val.value.substring(0, 1024);
    } else {
        $('#charNum').text(len);
    }
};
function hideCaract(element) {
    a = document.getElementsByClassName("selector");
    b = document.getElementsByClassName("caracteristicas-principales");
    for (let i = 0; i < a.length; i++) {
        if (a[i] === element) {
            if (a[i].classList.contains("active")) {
                a[i].classList.remove("active");
            } else {
                a[i].classList.add("active");
            }
        } else {
            a[i].classList.remove("active");
            b[i].classList.remove("show");
        }
    }
}
function changeView(id) {
    a = document.getElementsByClassName("circle");
    b = document.getElementsByClassName("tab");
    for (let i = 0; i < a.length; i++) {
        if (i == id) {
            a[i].classList.add("active");
            b[i].classList.add("active");
        } else {
            a[i].classList.remove("active");
            b[i].classList.remove("active");
        }
    }
}
function changeViewmodal(id) {
    a = document.getElementsByClassName("circle");
    b = document.getElementsByClassName("tabmodal");
    for (let i = 0; i < a.length; i++) {
        if (i == id) {
            a[i].classList.add("active");
            b[i].classList.add("active");
        } else {
            a[i].classList.remove("active");
            b[i].classList.remove("active");
        }
    }
}
function nextView(id) {
    a = document.getElementsByClassName("circle");
    b = document.getElementsByClassName("tab");
    for (let i = 0; i < a.length; i++) {
        if (i == id) {
            a[i - 1].classList.add("complete");
            a[i].classList.add("active");
            b[i].classList.add("active");
        } else {
            a[i].classList.remove("active");
            b[i].classList.remove("active");
        }
    }
}
function nextViewmodal(id) {
    a = document.getElementsByClassName("circle");
    b = document.getElementsByClassName("tabmodal");
    for (let i = 0; i < a.length; i++) {
        if (i == id) {
            a[i - 1].classList.add("complete");
            a[i].classList.add("active");
            b[i].classList.add("active");
        } else {
            a[i].classList.remove("active");
            b[i].classList.remove("active");
        }
    }
}
function select(element) {
    a = document.getElementsByClassName("dropdown-item");
    b = document.getElementById("dropdownMenuButton");
    element.classList.add("active");
    c = element.innerText;
    for (let i = 0; i < a.length; i++) {
        if (a[i] == element) {
            b.innerHTML = c + "<i class='fas fa-chevron-down float-right mt-1'></i>";
        } else {
            a[i].classList.remove("active");
        }
    }
}
function empresaPersona() {
    a = document.getElementById("info-person");
    b = document.getElementById("info-empresa");
    c = document.getElementById("dropdownMenuButton");
    if (c.innerText == " Empresa Inmobiliaria" || c.innerText == "Empresa de servicios") {
        a.classList.add("d-none");
        a.classList.remove("d-block");
        b.classList.add("d-block");
        b.classList.remove("d-none");
    } else {
        b.classList.add("d-none");
        b.classList.remove("d-block");
        a.classList.add("d-block");
        a.classList.remove("d-none");
    }
}
